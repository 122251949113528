import { useEffect } from 'react';
import { Col, Input } from 'reactstrap';
/* Commented out for the theme */
// import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { BsQuestionLg as AiOutlineQuestionCircle } from 'react-icons/bs';

const showInfo = (evnt, msg) => {
  const p = document.querySelector('.filter p');
  if(!p && evnt.type === 'click'){
    const target = evnt.currentTarget;

    const pop = document.createElement('p');
    /* Commented out for the theme */
    // const left = (target.getBoundingClientRect().left - 228);
    const left = (target.getBoundingClientRect().left - 243);
    pop.style.left = left+'px';
    pop.innerHTML = msg;

    target.parentElement.append(pop);
  } else {
    if(p) p.remove();
  }
};

function Filter({data, name, label, classes = '', msg = ''}){
  useEffect(() => {
    const svgs = document.querySelectorAll('.filter svg');
    for(const svg of svgs) svg.addEventListener('focusout', (evnt) => showInfo(evnt, ''));
  }, []);

  if(classes) classes = ' '+classes;

  return (
    <Col className={`filter${classes}`} md={3}>
      <Input name={name} type="select">
        <option value="-">{label}</option>
        {
          data?.map((v, i) => (v)? <option key={i} value={v}>{v}</option> : '')
        }
      </Input>
      {msg? <AiOutlineQuestionCircle size="18" color="#2e6ca4" onClick={(evnt) => showInfo(evnt, msg)} /> : ''}
    </Col>
  );
}

export default Filter;
