import Context, { useContext } from '../../context';
import { MdCloudSync } from 'react-icons/md';
import './index.css';

import { NewToken } from '../Token';
import { toggleConfirm } from '../Confirm';

import {
  loaderShowWithMsg, loaderMsg,
  loaderErrMsg
} from '../Loader';

const loadNewLoans = () => {
  loaderShowWithMsg('Updating loans data will only take a minute');

  NewToken().then(newtoken => {
    fetch(`${process.env.REACT_APP_API}/auth/loans`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    })
      .then(res => res.json())
      .then(res => {
        loaderMsg(res.message);
        setTimeout(() => {
          toggleConfirm();

          // Trigger dispatch in All loan types filter to update the displays
          document
          .querySelector('.filters .filter select')
          .dispatchEvent(new Event('change'));
        }, 3000);
      })
      .catch(err => {
        loaderErrMsg();
        console.log(err);
      });
  });
}

function NewLoans(){
  const Contexts = useContext(Context);
  const signin = Contexts.signin;
  const viewmode = Contexts.viewmode;

  const toggleNewLoans = () => {
    toggleConfirm({
      action: 'load new loans data',
      label: 'Proceed',
      confirm: loadNewLoans
    });
  };

  return !signin || viewmode !== 'admin'? '' : (
    <font onClick={toggleNewLoans}>
      <MdCloudSync size="24" className="newloans" title="Load new loans" />
    </font>
  );
}

export default NewLoans;