import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './index.css';

/* import {
  Container, Button, Form, Label,
  Input
} from 'reactstrap'; */

import { Container } from 'reactstrap';

import {
  loaderSet, /* loaderShow, */
  loaderHide, loaderErrMsg
} from '../../components/Loader';

/* const submit = (evnt) => {
  evnt.preventDefault();
  const form = evnt.target;
  const zipcode = form.querySelector('[name="zipcode"]');
  const name = zipcode.value;
  if(name){
    loaderShow();
    getData(name);
  }
}; */

const getData = (name) => {
  // To display the text format
  /* fetch(`${process.env.REACT_APP_API}/zips/${name}?format=text`)
  .then(res => res.text())
  .then(res => console.log(res)) */

  fetch(`${process.env.REACT_APP_API}/zips/${name}`)
  .then(res => res.json())
  .then(res => {
    const ziplist = document.querySelector('.ziplist');
    if(res.status === 'OK'){
      const data = res.data;
      data.forEach((a) => {
        // Table
        let table = '<table>';
        // Body
        let tbody = '<tbody>';
        for(const key in a){
          tbody += '<tr>';
          tbody += `<td>${key.toUpperCase()}:</td>`;
          tbody += `<td>${a[key]}</td>`;
          tbody += '</tr>';
        }
        tbody += '</tbody>';
        // /Body
        table += tbody;
        table += '</table>';
        // /Table
        ziplist.innerHTML = table;
      });
    } else {
      ziplist.innerHTML = `<center>${res.message}</center>`;
    }
    loaderHide();
  })
  .catch(err => {
    loaderErrMsg();
    console.log(err);
  });
};

function Zipcode(){
  const { zip } = useParams();

  useEffect(() => {
    document.body.classList.add('nomap');
    loaderSet();
    // loaderHide();
    getData(zip);
  }, []); // eslint-disable-line

  return (
    <Container fluid className="zipcode">
      {/* <Form onSubmit={submit}>
        <Label>Zipcode:</Label>
        <Input name="zipcode" bsSize="sm" autoFocus={true} />
        <Button size="sm">Submit</Button>
      </Form> */}
      <div className="table ziplist">
        <center>Data will appear here</center>
      </div>
    </Container>
  );
}

export default Zipcode;