import { memo } from 'react';
import Context, { useContext } from '../../context';

let sessions = () => void 0;

function Unload(){
  const Contexts = useContext(Context);

  sessions = async (data) => {
    // Do not add any condition to this block
    // because any condition can be use to manually
    // set all sessionStorage data and can bypass
    // the login or modify access token or any
    // data listed below
    sessionStorage.signin = data.signin;
    sessionStorage.token = data.token;
    sessionStorage.level = data.level;
    sessionStorage.name = data.name;
    sessionStorage.email = data.email;
    sessionStorage.conts = data.conts;
    sessionStorage.relate = data.relate;
    sessionStorage.comms = data.comms;
    sessionStorage.summary = data.summary;
    sessionStorage.viewmode = data.viewmode;
    sessionStorage.exptime = data.exptime;

    // Replaced with USD or ILS not 0 then it is active
    // sessionStorage.stats = data.stats;

    const trans = data.trans;
    const navs = data.navs;
    const accts = data.accts;
    try {
      localStorage.trans = trans;
      localStorage.navs = navs;
      try {
        localStorage.accts = accts;
      } catch(err){
        sessionStorage.accts = accts;
      }
    } catch(err){
      delete localStorage.trans;
      delete localStorage.navs;

      const appCache = await caches.open('appCache');
      appCache.put('/trans.json', new Response(trans));
      appCache.put('/navs.json', new Response(navs));
      appCache.put('/accts.json', new Response(accts));
    }
  };

  onbeforeunload = (evnt) => {
    sessions(Contexts);

    // Uncomment to display "Leave site?"
    // if(window.history.state.init){
    //   evnt.preventDefault(); // FireFox
    //   evnt.returnValue = ''; // Chrome
    //   return ''; // Safari
    // }
  };
}

export default memo(Unload);
export { sessions };