import { useState, memo } from 'react';
import Context, { useContext } from '../../context';
import {
  Button, Modal, ModalHeader,
  ModalBody, ModalFooter
} from 'reactstrap';

let time = () => void 0;
let toggle = () => void 0;
let signout = () => void 0;
let toggleAlert = () => void 0;
let autosignout = () => void 0;

let inactive = 0;
let out = 0;
let sin = 0;

// 1 min of countdown
const start = 60;
// 15 mins of inactivity
// const inactivity = 900000;
// 1 hr of inactivity
const inactivity = 3600000;

let timer = () => {
  if(!inactive){
    clearTimeout(time);
    time = setTimeout(toggleAlert, inactivity);
  } else if(out && sin){
    inactive = 0;
    out = 0;
  }
};

const counter = () => {
  let left = start;
  autosignout = setInterval(function(){
    left -= 1;
    if(left <= 0) signout(stop);
    document.querySelector('.count').innerText = left;
  }, 1000);
};

const stop = () => {
  clearInterval(autosignout);
  out = 1; sin = 0; toggle();
};

function Idle(){
  const Contexts = useContext(Context);
  signout = Contexts.signout;
  sin = Contexts.signin;

  const [modalAlert, setModalAlert] = useState(false);
  toggleAlert = () => {
    if(!inactive){
      inactive = 1;
      counter();
    } else {
      inactive = 0;
      clearInterval(autosignout);
    }
    toggle();
  };

  toggle = () => setModalAlert(!modalAlert);

  timer();
  onmousemove = timer;
  onmousedown = timer;
  onkeydown = timer;
  onclick = timer;
  document.addEventListener('scroll', timer, true);
  document.addEventListener('touchstart', timer, true);

  return (
    <Modal isOpen={modalAlert} toggle={toggleAlert} centered={true} backdrop="static">
      <ModalHeader toggle={toggleAlert}>Confirm</ModalHeader>
      <ModalBody>
        You are about to be logged out in <span className="count">{start}</span>.
      </ModalBody>
      <ModalFooter>
        <Button size="sm" color="primary" onClick={toggleAlert}>
          Keep me logged in
        </Button>
        <Button size="sm" color="primary" onClick={() => signout(stop)}>
          Log me out
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default memo(Idle);
