// Frontend implementation of geodata
// import names from '../../data/geo/markets/_.json';
import InfoWindow, { infoContent } from '../InfoWindow';
import { label as Label } from '../Label';
import { rate as loanrate } from '../../utils/loans';
import { NewToken } from '../Token';
import { loaderMsg, loaderErrMsg } from '../Loader';

let loaded = 0;
const rating = {};

let names = [];
fetch(`${process.env.REACT_APP_API}/markets?type=names`)
  .then(res => res.json())
  .then(res => names = res.data[0])
  .catch(err => console.log(err));

const init = () => {
  return new Promise((resolve) => {
    if(!loaded){
      fetch(`${process.env.REACT_APP_API}/markets`)
        .then(res => res.json())
        .then(res => {
          res.data.forEach(a => {
            const market = a.market.toLowerCase().trim();
            const rate = a.rating;
            rating[market] = (rate)? a.rating.toLowerCase().trim() : ' ';
          });

          loaded = 1;
          resolve(1);
        })
        .catch(err => console.log(err));
    } else resolve(0);
  });
};

const getData = (infowindow, name) => {
  NewToken().then(newtoken => {
    fetch(`${process.env.REACT_APP_API}/markets/${encodeURIComponent(name)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    })
      .then(res => res.json())
      .then(res => {
        if(res.status === 'OK'){
          const data = res.data[0];
          data.rating = loanrate(data.rating).num;

            const details = [
              {
                title: 'Location Information',
                fields: [
                  'market', 'location_rating',

                  'vacant_units_ratio_3m', 'vacant_units_ratio_1m', 'total_population_3m',
                  'total_population', 'total_housing_3m', 'total_housing',
                  'population_density_3m', 'population_density'
                ]
              },
              {
                title: 'Pricing Information',
                fields: [
                  'last_actual_rent_psf_3_year_ratio', 'last_actual_rent_psf_1_year_ratio', 'last_actual_rent_3_year_ratio',
                  'last_actual_rent_3_year', 'last_actual_rent_1_year_ratio', 'last_actual_rent_1_year',
                  'last_actual_rent', 'last_actual_psf_rent'
                ]
              },
              {
                title: 'Market Momentum',
                fields: [
                  'number_of_units_under_construction', 'number_of_units_prospective', 'number_of_units_planned',
                  'number_of_units_completed', 'number_prospective_properties', 'number_planned_properties',
                  'number_in_construction_properties', 'number_completed_properties',

                  'median_hh_income_3m', 'median_hh_income',

                  'last_occupancy_date', 'last_occupancy_3_year_ratio', 'last_occupancy_3_year',
                  'last_occupancy_1_year_ratio', 'last_occupancy_1_year', 'last_occupancy',

                  'compound_growth_rate_3m', 'compound_growth_rate', '5y_population_projection_ratio_3m',
                  '5y_population_projection_ratio_1m'
                ]
              },
              {
                title: 'Rating & Ranking',
                fields: ['location_rating_average', 'location_rating']
              }
            ];

          infoContent(infowindow[name], details, data, () => closeInfo(infowindow, name));
        } else {
          loaderMsg(res.message);
          closeInfo(infowindow, name);
        }
      })
      .catch(err => {
        loaderErrMsg();
        closeInfo(infowindow, name);
        console.log(err);
      });
  });
};

const closeInfo = (infowindow, name) => {
  infowindow[name].close();
  delete infowindow[name];
};

const infowindow = {};
function Market(obj, map, maps, polygon, hover, creds){
  init(obj).then(() => {
    const label = new Label();

    try {
      names.forEach(async _name => {
        let market = _name.toLowerCase().trim();
        if(market === 'atlanta - suburban') market = 'suburban atlanta';
        if(market === 'atlanta - urban') market = 'urban atlanta';
        if(market === 'chicago - suburban') market = 'suburban chicago';
        if(market === 'chicago - urban') market = 'urban chicago';
        if(market === 'dallas - north') market = 'north dallas';
        if(market === 'dallas - suburban') market = 'suburban dallas';
        if(market === 'fort lauderdale') market = 'ft lauderdale';
        if(market === 'houston - east') market = 'east houston';
        if(market === 'houston - west') market = 'west houston';
        if(market === 'lafayette-lake charles') market = 'lafayette - lake charles';
        if(market === 'los angeles - eastern county') market = 'eastern los angeles county';
        if(market === 'los angeles - metro') market = 'metro los angeles';
        if(market === 'mississippi state university') market = 'mississippi state';
        if(market === 'new jersey - central') market = 'central new jersey';
        if(market === 'new jersey - northern') market = 'northern new jersey';
        if(market === 'philadelphia - suburban') market = 'suburban philadelphia';
        if(market === 'philadelphia - urban') market = 'urban philadelphia';
        if(market === 'richmond-tidewater') market = 'richmond - tidewater';
        if(market === 'twin cities - suburban') market = 'suburban twin cities';
        if(market === 'twin cities - urban') market = 'urban twin cities';

        let rate = rating[market];
        if(!rate) rate = '';
        const color = (rate)? obj.color[rate] : '';

        const name = market;

        // Frontend implementation of geodata
        // require(`../../data/geo/markets/${_name}.json`).forEach(a => {

        // Backend implementation of geodata
        const response = await fetch(`${process.env.REACT_APP_API}/markets?type=coords&filter=${_name}`)
        const res = await response.json();
        const markets = res.data[0];

        markets.forEach(a => {
        //
          a.geometry.coordinates.forEach(b => {
            obj.iMarket++;
            const data = [];
            b.forEach(c => {
              if(Array.isArray(c[0])){
                c.forEach(d => {
                  const coords = {lng: d[0], lat: d[1]}
                  data.push(coords);
                });
              } else {
                const coords = {lng: c[0], lat: c[1]}
                data.push(coords);
              }
            });

            const id = obj.iMarket;
            obj.polyMarket[id] = polygon(data, color);
            if(!obj.isFeats() || obj.isZip())
              obj.polyMarket[id].setVisible(false);
            obj.polyMarket[id].setMap(map);

            obj.polyMarket[id].addListener('mouseover', function(){
              this.origStrokeWeight = this.strokeWeight;
              this.setOptions({
                strokeColor: hover,
                strokeWeight: obj.strokeWeight
              });
              label.set(name.toUpperCase());
            });

            obj.polyMarket[id].addListener('mouseout', function(){
              this.setOptions({
                strokeColor: this.fillColor,
                strokeWeight: this.origStrokeWeight
              });
              label.set('');
            });

            if(creds.signin){
              obj.polyMarket[id].addListener('click', (evnt) => {
                if(!infowindow[name]){
                  // The passed data will be used in history component
                  document.querySelector('.map').dispatchEvent(
                    new CustomEvent('click', { detail: {
                      id: obj.polyMarket[id],
                      details: {
                        trigger: maps.event.trigger,
                        evnt
                      }
                    }})
                  );

                  infowindow[name] = InfoWindow(map, maps, evnt.latLng);
                  getData(infowindow, name);
                } else {
                  closeInfo(infowindow, name);
                }
              });
            }
          });
        });

        // });
      });
    } catch(err){
      loaderErrMsg();
      console.log(err);
    }
  });
}

export default Market;