const types = {
  image: {
    bytes: {
      jpg: '',
      jpeg: 'ffd8ff',
      png: '89504e47',
      gif: '47494638',
      bmp: '424d'
    },
    size: 2 // 2MB limit
  },
  pdf: {
    bytes: {
      pdf: '25504446'
    },
    size: 20 // 20MB limit
  }
};

const validatefile = async (mime, file) => {
  let passed = 0;
  // Validate file type
  const type = file.type;
  if(type.indexOf(mime) >= 0){
    // Validate file name
    const names = file.name.split('.');
    if(names.length === 2){
      // Validate file extension
      const ext = names.slice(-1)[0].toLowerCase();

      const props = types[mime];
      const magic = props.bytes;

      if(Object.keys(magic).indexOf(ext) >= 0){
        // Validate file magic bytes
        let header = '';
        const buffer = await file.arrayBuffer();
        const bytes = new Uint8Array(buffer).slice(0, 4);
        bytes.forEach(a => header += a.toString(16));

        const mimetype = type.split('/').slice(-1)[0];
        if(header.indexOf(magic[mimetype]) >= 0){
          // Validate file size
          if(file.size <= (1024 * 1024 * props.size))
            passed = 1;
        }
      }
    }
  }
  return passed;
};

export default validatefile;