import { useEffect } from 'react';
import { Row } from 'reactstrap';
import './index.css';

import Filter from '../Filter';

const calendar = (evnt) => {
  evnt.target.blur();
  evnt.target.parentElement
  .previousElementSibling
  .showPicker();
};

const datelabels = {};
const datedisplay = (evnt) => {
  const self = evnt.target;
  const select = self.nextElementSibling.childNodes[0];
  if(!datelabels[self.name]) datelabels[self.name] = select.innerText;
  select.options[0].innerText = (!self.value)? datelabels[self.name] : self.value;
};

function Dates(){
  useEffect(() => {
    const dates = document.querySelectorAll('.date select');
    for(const date of dates){
      date.addEventListener('click', calendar);
      date.parentElement.previousElementSibling
      .addEventListener('change', datedisplay);
    }
  }, []);

  return (
    <Row className="dates">
      <input type="date" name="from_date" /><Filter data={[]} label="From Date" classes="date" />
      <input type="date" name="to_date" /><Filter data={[]} label="To Date" classes="date" />
    </Row>
  );
}

export default Dates;
