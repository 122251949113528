import { Row, Col } from 'reactstrap';
/* Commented out for the theme */
// import { RiFilterOffLine } from 'react-icons/ri';
import { MdOutlineFilterAlt as RiFilterOffLine } from 'react-icons/md';
import './index.css';

import Filter from '../Filter';

const status = [
  'Inactive Only',
  'Active / Inactive'
]

function Filters2(){
  const color = '#606060';
  return (
    <>
      <Col className="filters2" md={7}>
        <Row>
          <Filter data={[]} name="all_accounts" label="All Accounts" classes="allaccounts" />
          <Filter data={status} name="all_status" label="Active Only" classes="allstatus" />
          <Filter data={[]} name="all_classes" label="All Classes" classes="allclasses" />
          <RiFilterOffLine size="18" color={color} className="clear" title="Clear filters" />
          <span className="cleartext2">Clear Filter</span>
        </Row>
      </Col>
    </>
  );
}

export default Filters2;
