import { useState } from 'react';

import {
  Button, Modal, ModalHeader,
  ModalBody, ModalFooter,
} from 'reactstrap';

let toggleDelete = () => void 0;
let delData = {};

function Delete({confirm}){
  const [modalDelete, setModalDelete] = useState(false);
  toggleDelete = (evnt, data) => {
    setModalDelete(!modalDelete);

    if(!modalDelete){
      const target = evnt.currentTarget;
      if(!data.elem1) data.elem1 = data.elem;
      data.elem = target.closest(data.elem1);

      evnt.stopPropagation();

      (function retry(){
        const modalElem = document.querySelector('.deleteModal');
        if(modalElem){
          const name = modalElem.querySelector('.modal-body strong');
          name.innerText = data.name;

          delData = data;
        } else setTimeout(retry, 400);
      })();
    }
  };

  return (
    <Modal isOpen={modalDelete} toggle={toggleDelete} centered={true} modalClassName="deleteModal">
      <ModalHeader toggle={toggleDelete}>Confirm</ModalHeader>
      <ModalBody>
        Are you sure you want to delete "<strong></strong>"?
      </ModalBody>
      <ModalFooter>
        <Button size="sm" color="secondary" onClick={toggleDelete}>
          Cancel
        </Button>
        <Button size="sm" color="primary" onClick={() => confirm(delData)}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default Delete;
export { toggleDelete };