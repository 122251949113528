import { useState } from 'react';
import Context, { useContext } from '../../context';
import './index.css';

import {
  loaderShow, loaderHide,
  loaderMsg, loaderErrMsg
} from '../Loader';

import {
  Button, Modal, ModalHeader,
  ModalBody, Form, FormGroup,
  Label, Input
} from 'reactstrap';

import validation from '../../utils/validation';

let signin = () => void 0;
let toggle = () => void 0;
let togglePass = () => void 0;
let emailAdd = '';
let serverTime = 0;
let localTime = 0;

const newvalidation = new validation();
newvalidation.rule = {
  email: () => {
    let msg = required();
    if(msg === ' ') msg = email();
    return msg;
  },
  pass: () => required()
};
const required = () => newvalidation.required();
const email = () => newvalidation.email();
const validate = (evnt) => newvalidation.validate(evnt);

let locked = 'You have reached the maximum login retries<br>';
locked += 'Try again later or contact the administrator';

const submit = (evnt) => newvalidation.submit(evnt, (data) => {
  loaderShow();

  fetch(`${process.env.REACT_APP_API}/auth`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .then(res => {
      if(res.status === 'OK'){
        /* if(res.data[0].returnCode.indexOf('Not') >= 0)
          loaderMsg('This email address is not recognized'); */

        /* if(res.data[0].returnCode.indexOf('OTP') >= 0)
          loaderMsg(res.data[0].returnCode);
        else if(res.data[0].returnCode.indexOf('Blocked') >= 0) */

        if(res.data[0].returnCode.indexOf('Blocked') >= 0)
          loaderMsg(locked);
        else {
          serverTime = res.data[0].time;
          localTime =  new Date().getTime();

          loaderHide();
          toggle();
          togglePass();
          emailAdd = data.email;

          const passcode = () => {
            setTimeout(() => {
              const pass = document.querySelector('[name="pass"]');
              if(pass) pass.focus();
              else passcode();
            }, 400);
          };

          passcode();
        }
      } else {
        if(
          res.message &&
          res.message !== 'No data found'
        )
          loaderMsg(res.message);
        else loaderErrMsg();
      }
    })
    .catch(err => {
      loaderErrMsg();
      console.log(err);
    });
});

const submitPass = (evnt) => newvalidation.submit(evnt, (data) => {
  data.pass = data.pass.trim();
  data.email = emailAdd;
  loaderShow();
  fetch(`${process.env.REACT_APP_API}/auth/signin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(async res => {
      if(res.status === 'OK'){
        if(res.data[0].returnCode.indexOf('Not') >= 0)
          loaderMsg('Incorrect, you have maximum of 3 attemps');
        else if(res.data[0].returnCode.indexOf('Expired') >= 0){
          loaderMsg('Expired code, you have maximum of 3 attemps');
          setTimeout(() => {
            toggle();
          }, 3000);
        } else if(res.data[0].returnCode.indexOf('Blocked') >= 0)
          loaderMsg(locked);
        else {
          loaderHide();

          // Get time difference in seconds
          /* const diff = (res.data[0].time - new Date().getTime()) / 1000;
          sessionStorage.exptime = Math.ceil(diff); // Diff in seconds */

          /* const diff = (serverTime - localTime) / 1000;
          sessionStorage.exptime = Math.ceil(diff); // Diff in seconds */

          // Get time difference even if negative/advanced device time
          sessionStorage.exptime = serverTime - localTime; // Diff in milliseconds

          sessionStorage.signin = 1;
          sessionStorage.token = res.data[0].token;
          sessionStorage.level = res.data[0].level;
          sessionStorage.name = res.data[0].name;
          sessionStorage.email = emailAdd;
          sessionStorage.conts = JSON.stringify(res.data[0].conts);
          sessionStorage.relate = JSON.stringify(res.data[0].relate);
          sessionStorage.comms = JSON.stringify(res.data[0].comms);
          sessionStorage.summary = JSON.stringify(res.data[0].summary);
          sessionStorage.viewmode = res.data[0].level;

          // Replaced with USD or ILS not 0 then it is active
          // const stats = {};
          // res.data[0].accts.forEach(a => stats[a.id] = a.Active__c);
          // sessionStorage.stats = JSON.stringify(stats);

          const trans = JSON.stringify(res.data[0].trans);
          const navs = JSON.stringify(res.data[0].navs);
          const accts = JSON.stringify(res.data[0].accts);
          try {
            localStorage.trans = trans;
            localStorage.navs = navs;
            try {
              localStorage.accts = accts;
            } catch(err){
              sessionStorage.accts = accts;
            }
          } catch(err){
            delete localStorage.trans;
            delete localStorage.navs;

            const appCache = await caches.open('appCache');
            appCache.put('/trans.json', new Response(trans));
            appCache.put('/navs.json', new Response(navs));
            appCache.put('/accts.json', new Response(accts));
          }

          signin();
        }
      } else {
        if(
          res.message &&
          res.message !== 'No data found'
        )
          loaderMsg(res.message);
        else loaderErrMsg();
      }
    })
    .catch(err => {
      loaderErrMsg();
      console.log(err);
    });
});

const focus = () => document.querySelector('[name="email"]').focus();

function Signin(){
  const { reloader, toggles } = useContext(Context);
  const { toggleAbout, toggleTerms, toggleDisclaimer } = toggles;

  signin = () => reloader();

  const [modal, setModal] = useState(true);
  toggle = () => {
    if(modalPass)
      setModalPass(!modalPass);

    setModal(!modal);
  }

  const [modalPass, setModalPass] = useState(false);
  togglePass = () => setModalPass(!modalPass);

  return (
    <>
      <Button outline size="sm" className="signin" onClick={toggle}>Investor Login</Button>
      <Modal isOpen={modal} toggle={toggle} centered={true} /* autoFocus={false} */ backdrop={false} onOpened={focus} modalClassName="signin-modal email">
        <ModalHeader toggle={toggle}>Investor / Advisor Login</ModalHeader>
        <ModalBody>
          <Form onSubmit={submit}>
            <FormGroup>
              <Label>Email</Label>
              <Input name="email" bsSize="sm" onChange={validate} autoFocus={true} />
              <span className="err"></span>
            </FormGroup>
            <Button block size="sm">Sign In</Button>
          </Form>
          <br />
          <div className="note">
            Please provide the email address associated with your fund investment. For non-investors interested in learning about the Phoenix Real Estate Debt Fund, click <span onClick={toggleAbout}>here</span>.
            <br /><br />
            By clicking the "Sign In" button, you hereby acknowledge your acceptance of the <span onClick={toggleTerms}>Terms of Use</span> and <span onClick={toggleDisclaimer}>Disclaimer</span> of the site.
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalPass} toggle={togglePass} centered={true} backdrop={false} modalClassName="signin-modal">
        <ModalHeader toggle={toggle}>Authentication</ModalHeader>
        <ModalBody>
          <Form onSubmit={submitPass}>
            <FormGroup>
              <Label>Security Code</Label>
              <Input name="pass" bsSize="sm" onChange={validate} autoComplete="off" />
              <span className="err"></span>
            </FormGroup>
            <Button block size="sm">Sign In</Button>
          </Form>
          <br />
          <div className="note">
            If you are an active investor, please check your email (inbox, spam or junk) for a security code that was just sent to you. To try again, click <span onClick={toggle}>here</span>.
          </div>
        </ModalBody>
      </Modal>
      <div className="mapblocker"></div>
    </>
  );
}

export default Signin;