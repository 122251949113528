import { Row } from 'reactstrap';
import './index.css';

import { encodeFilters } from '../../utils/filters';
import { NewToken } from '../Token';
// import { shorten } from '../../utils/number';

let filters = [];
let loaded = 0;

const initsummary = () => {
  if(!loaded){
    filters = document.querySelectorAll('.filters .filter select');
    for(const filter of filters){
      filter.addEventListener('change', summary);
    }

    summary();
    loaded = 1;
  }
};

const template = (title, value) => {
  // let details = '<div class="col-md-3">';
  let details = '<div class="col-md-4">';
  details += '<div class="cards">';
  details += `<h6>${title}</h6>`;
  details += `<h2><center>${value? value : 0}</center></h2>`;
  details += '</div>';
  details += '</div>';
  return details;
};

const summary = () => {
  NewToken().then(newtoken => {
    const enfil = encodeFilters(filters);
    fetch(`${process.env.REACT_APP_API}/loandetails?type=getSummary&filters=${enfil}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    })
      .then(res => res.json())
      .then(res => {
        if(res.status === 'OK'){
          const data = res.data[0];

          let details = template('# of Active Loans', data.loans);
          // details += template('Total Loan Amount', `$${shorten(data.loan)}`);
          const ltv = (data.ltv)? data.ltv : '0%';
          details += template('Average After Repair LTV', ltv);
          // details += template('Average Loan To Cost', data.ltc);
          details += template('# of States', data.states);

          const summary = document.querySelector('.summary');
          summary.innerHTML = details;
        }
      })
      .catch(err => console.log(err));
  });
};

function Summary(){
  return <Row className="summary"></Row>;
}

export default Summary;
export { initsummary };
