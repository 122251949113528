// Frontend implementation of geodata
// import names from '../../data/geo/submarkets/_.json';
import InfoWindow, { infoContent } from '../InfoWindow';
import { label as Label } from '../Label';
import { rate as loanrate } from '../../utils/loans';
import { NewToken } from '../Token';
import {
  loaderShow, loaderHide,
  loaderMsg, loaderErrMsg
} from '../Loader';

let loaded = 0;
const rating = {};
const loadedSubmarkets = [];

// let names = [];
let submarketIds = [];
fetch(`${process.env.REACT_APP_API}/markets?type=submarketNames`)
  .then(res => res.json())
  // .then(res => names = res.data[0])
  .then(res => submarketIds = res.data[0])
  .catch(err => console.log(err));

const init = () => {
  return new Promise((resolve) => {
    if(!loaded){
      fetch(`${process.env.REACT_APP_API}/markets?type=submarkets`)
        .then(res => res.json())
        .then(res => {
          res.data.forEach(a => {
            const submarket = a.submarket.toLowerCase().trim();
            const market = a.market.toLowerCase().trim();

            // ('key' in obj)?
            const key =
            (submarket in rating)?
            submarket+market : submarket;

            const rate = a.rating;
            rating[key] = (rate)? a.rating.toLowerCase().trim() : ' ';
          });

          loaded = 1;
          resolve(1);
        })
        .catch(err => console.log(err));
    } else resolve(0);
  });
};

const getData = (infowindow, name) => {
  NewToken().then(newtoken => {
    fetch(`${process.env.REACT_APP_API}/markets/${encodeURIComponent(name)}?type=submarkets`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    })
      .then(res => res.json())
      .then(res => {
        if(res.status === 'OK'){
          const data = res.data[0];
          data.rating = loanrate(data.rating).num;

            const details = [
              {
                title: 'Location Information',
                fields: [
                  'submarket', 'market', 'zip',
                  'rating',

                  'vacant_units_ratio_3m', 'vacant_units_ratio_1m', 'total_population_3m',
                  'total_population', 'total_housing_3m', 'total_housing',
                  'population_density_3m', 'population_density'
                ]
              },
              {
                title: 'Pricing Information',
                fields: [
                  'last_actual_rent_psf_3_year_ratio', 'last_actual_rent_psf_1_year_ratio', 'last_actual_rent_3_year_ratio',
                  'last_actual_rent_3_year', 'last_actual_rent_1_year_ratio', 'last_actual_rent_1_year',
                  'last_actual_rent', 'last_actual_psf_rent'
                ]
              },
              {
                title: 'Market Momentum',
                fields: [
                  'number_of_units_under_construction', 'number_of_units_prospective', 'number_of_units_planned',
                  'number_of_units_completed', 'number_prospective_properties', 'number_planned_properties',
                  'number_in_construction_properties', 'number_completed_properties',

                  'median_hh_income_3m', 'median_hh_income',

                  'last_occupancy_date', 'last_occupancy_3_year_ratio', 'last_occupancy_3_year',
                  'last_occupancy_1_year_ratio', 'last_occupancy_1_year', 'last_occupancy',

                  'compound_growth_rate_3m', 'compound_growth_rate', '5y_population_projection_ratio_3m',
                  '5y_population_projection_ratio_1m'
                ]
              },
              {
                title: 'Rating & Ranking',
                fields: ['last_zip_rank', 'rating']
              }
            ];

          infoContent(infowindow[name], details, data, () => closeInfo(infowindow, name));
        } else {
          loaderMsg(res.message);
          closeInfo(infowindow, name);
        }
      })
      .catch(err => {
        loaderErrMsg();
        closeInfo(infowindow, name);
        console.log(err);
      });
  });
};

const closeInfo = (infowindow, name) => {
  infowindow[name].close();
  delete infowindow[name];
};

const infowindow = {};
function Submarket(obj, map, maps, polygon, hover, creds){
  if(obj.isZip() && obj.active.length){
    loaderShow();

    init(obj).then(async () => {
      // Streaming samples and references
        /* fetch(`${process.env.REACT_APP_API}/submarkets/files/_all1.json`)
        .then(async (response) => {
          // Text
          // const text = await new Response(response.body).text();
          // console.log(text);

          // JSON
          // const json = await new Response(response.body).json();
          // console.log(json);
        }); */


        /* const response = await fetch(`${process.env.REACT_APP_API}/submarkets/files/_all1.json`);
        const readableStream = response.body;
        const reader = readableStream.getReader();
        while(true){
          const { done, value } = await reader.read();
          if(done) break;
          const text = new TextDecoder('utf-8').decode(value);
          console.log('Received ', text);
        } */


        /* fetch(`${process.env.REACT_APP_API}/submarkets/files/_all1.json`)
          .then(res => res.json())
          .then(res => {
            console.log(res);
          }); */

        /* const response = await fetch(`${process.env.REACT_APP_API}/submarkets/files/_all1.json`);
        const reader = response.body.getReader();


        let result;
        let string = '';
        while(!result || !result.done){
          result = await reader.read();
          string += new TextDecoder('utf-8')
          .decode(result.value);
        } */
      // /Streaming samples and references

      const label = new Label();

      try {
        // names.forEach(async _name => {
          // Frontend implementation of geodata
          // require(`../../data/geo/submarkets/${_name}.json`).forEach(a => {

        const base = obj.active[1];
        const range = 1;
        const start = base - range;
        const end = base + range;

        const latBase = obj.active[3];

        // Iteration for lng
        for(let i = start; i <= end; i++){
          obj.active[1] = i;

          const latStart = latBase - range;
          const latEnd = latBase + range;

          // Iteration for lat
          for(let i1 = latStart; i1 <= latEnd; i1++){
            obj.active[3] = i1;
            const active = obj.active.join('');

            if(submarketIds.indexOf(active) >= 0 && loadedSubmarkets.indexOf(active) < 0){
              // Backend implementation of geodata
              // const response = await fetch(`${process.env.REACT_APP_API}/markets?type=submarketCoords&filter=${_name}`)
              const response = await fetch(`${process.env.REACT_APP_API}/markets?type=submarketCoords&filter=${active}`)
              const res = await response.json();
              const submarkets = res.data[0];

              loadedSubmarkets.push(active);
              submarkets.forEach(a => {
              //
                let submarket = a.properties.Submarket || a.properties.submarket;
                /* if(!isNaN(submarket) &&
                !isNaN(parseFloat(submarket)))
                  submarket = a.properties.AreaNumber; */
                if(/^\d+$/.test(submarket)) submarket = a.properties.AreaNumber;
                submarket = submarket.toLowerCase().trim();

                let market = a.properties.Market || a.properties.layer || '';
                market = market.replace(' 4-27-17', '');
                market = market.toLowerCase().trim();

                submarket = submarket.replace('st charles/waldorf', 'st. charles/waldorf');
                submarket = submarket.replace(/duport/g, 'dupont');
                submarket = submarket.replace('st elizabeth', 'saint elizabeths');
                submarket = submarket.replace('riveredale', 'riverdale');
                submarket = submarket.replace('sotuh', 'south');
                submarket = submarket.replace('rossmoor', 'roosmoor'); // Correct: rossmoor
                submarket = submarket.replace(/\\/g, '');
                submarket = submarket.replace('anchorage county east', 'anchorage east');
                submarket = submarket.replace('virgina', 'virginia');
                submarket = submarket.replace('st. helena', 'st. helena island');
                if(submarket === 'fairfiel') submarket = 'fairfield';
                submarket = submarket.replace('briarcreek -oakhurst', 'briarcreek - oakhurst');
                submarket = submarket.replace('cragmor', 'cragmoor');
                submarket = submarket.replace('ivy/stratton', 'ivywild/stratton');
                submarket = submarket.replace("farmers'", 'farmers');
                submarket = submarket.replace('st. claire shoresgross pointe', 'st. clair shoresgrosse pointe');
                submarket = submarket.replace('rotal', 'royal');
                if(submarket === 'crestwood/river oaks/sansom pa') submarket = 'crestwood/river oaks/sansom park';
                if(submarket === 'fairmount/morningside/worth he') submarket = 'fairmount/morningside/worth heights';
                submarket = submarket.replace('colton/grandterrace', 'colton/grand terrace');
                if(submarket === 'montclair/north onta') submarket = 'montclair/north ontario';
                submarket = submarket.replace('whitewater', 'white water');
                if(submarket === 'victorville/apple valley') submarket = submarket+'/big bear';
                submarket = submarket.replace('kansas city -west', 'kansas city - west');
                submarket = submarket.replace('parkland -shawnee', 'parkland/shawnee');
                submarket = submarket.replace('hollysprings', 'holly springs');
                submarket = submarket.replace('nw orleans', 'new orleans');
                submarket = submarket.replace('babtist', 'baptist');
                if(submarket === 'vivant-venetian isles') submarket = 'venetian isles';
                if(submarket === 'old town alexandria/potomac yard') submarket = submarket+'/taylor run';
                submarket = submarket.replace('highlands/roslyn', 'highlands/rosslyn'); // Correct: roslyn
                if(submarket === 'ballston/east falls church') submarket = submarket+'/seven corners';
                if(submarket === 'lee highway/mclean') submarket = 'lee highway corridor/mcclean/sugarland run';
                submarket = submarket.replace('farlington/seminary', 'fairlington/seminary');
                if(submarket === 'arlington heights/clarendon') submarket = submarket+'/courthouse';
                if(submarket === 'eisenhower east/fort hunt') submarket = submarket+'/franconia';
                if(submarket === 'pearl distr') submarket = 'pearl district';
                if(submarket === 'hillside/no') submarket = 'hillside/northwest';
                if(submarket === 'goose hollo') submarket = 'goose hollow';
                if(submarket === 'downtown po') submarket = 'downtown portland';
                if(submarket === 'salmon cree') submarket = 'salmon creek';
                if(submarket === 'downtown va') submarket = 'downtown vancouver';
                if(submarket === 'walnut grov') submarket = 'walnut grove';
                if(submarket === 'fort vancou') submarket = 'fort vancouver';
                if(submarket === 'raleigh hil') submarket = 'raleigh hills';
                if(submarket === 'milwaukie/g') submarket = 'milwaukie/gladstone';
                submarket = submarket.replace('tualitin', 'tualatin');
                if(submarket === 'kerns/buckm') submarket = 'kerns/buckman';
                submarket = submarket.replace('happy valle', 'happy valley');
                if(submarket === 'brentwood/d') submarket = 'brentwood/darlington';
                if(submarket === 'fairfbrooklyn/moiel') submarket = 'brooklyn/moreland';
                if(submarket === 'southwest h') submarket = 'southwest hills';
                if(submarket === 'pleasant va') submarket = 'pleasant valley';
                if(submarket === 'madison sou') submarket = 'madison south';
                if(submarket === 'cully/rosew') submarket = 'cully/roseway';
                if(submarket === 'st johns/un') submarket = 'st johns/university park';
                if(submarket === 'east greenhaven/south land par') submarket = 'east greenhaven/south land park';
                if(submarket === 'central folsom/south orangeval') submarket = 'central folsom/south orangevale';
                if(submarket === 'greater folsom/el dorado hills') submarket = submarket+'/shingle springs';
                if(submarket === 'northeast carmichael/west fair') submarket = 'northeast carmichael/west fair oaks';
                if(submarket === 'foothills farms/west citrus he') submarket = 'foothills farms/west citrus heights';
                if(submarket === 'laguna wes') submarket = 'laguna west';
                if(submarket === 'university of texas at san ant') submarket = 'university of texas at san antonio';
                if(submarket === 'east palo alto') submarket = 'menlo park/east palo alto';
                if(submarket === 'millbrae') submarket = 'millbrae / airport';
                submarket = submarket.replace('deanwwod', 'deanwood');

                // Not so sure
                if(submarket === 'oakland east') submarket = 'east oakland/oakland hills';
                if(submarket === 'nw contra costa (richmond)') submarket = 'richmond';
                if(submarket === 'kansas city northwest - rivers') submarket = 'kansas city northwest/riverside';
                if(submarket === 'los angeles - downtown') submarket = 'downtown los angeles';
                let rate = rating[submarket+market] || rating[submarket];

                let _submarket = submarket;
                if(!rate){
                  _submarket = submarket.replace(/ /g, '');
                  rate = rating[_submarket+market] || rating[_submarket];
                  if(rate) submarket = _submarket;
                }

                if(!rate){
                  submarket = submarket.replace(/_/g, ' - ');
                  rate = rating[submarket+market] || rating[submarket];
                }

                if(!rate){
                  _submarket = submarket.replace(/ - /g, '/');
                  rate = rating[_submarket+market] || rating[_submarket];
                  if(rate) submarket = _submarket;
                }

                if(!rate){
                  // submarket = submarket.replace(/st\. /g, 'st.');
                  submarket = submarket.replace(/st\./g, 'st. ');
                  rate = rating[submarket+market] || rating[submarket];
                }

                if(!rate){
                  submarket = submarket.replace('st. anthony', 'st.anthony');
                  rate = rating[submarket+market] || rating[submarket];
                }

                if(!rate){
                  _submarket = submarket.replace(/  +/g, ' ');
                  rate = rating[_submarket+market] || rating[_submarket];
                  if(rate) submarket = _submarket;
                }

                // Not so sure
                if(!rate){
                  _submarket = submarket.replace(/ - /g, ' ');
                  rate = rating[_submarket+market] || rating[_submarket];
                  if(rate) submarket = _submarket;
                }

                if(!rate) rate = '';
                const color = (rate)? obj.color[rate] : '';

                const name = submarket;

                a.geometry.coordinates.forEach(b => {
                  obj.iSubmarket++;
                  const data = [];
                  b.forEach(c => {
                    if(Array.isArray(c[0])){
                      c.forEach(d => {
                        const coords = {lng: d[0], lat: d[1]}
                        data.push(coords);
                      });
                    } else {
                      const coords = {lng: c[0], lat: c[1]}
                      data.push(coords);
                    }
                  });

                  const id = obj.iSubmarket;
                  obj.polySubmarket[id] = polygon(data, color);
                  if(!obj.isFeats() || !obj.isZip())
                    obj.polySubmarket[id].setVisible(false);
                  obj.polySubmarket[id].setMap(map);

                  obj.polySubmarket[id].addListener('mouseover', function(){
                    this.origStrokeWeight = this.strokeWeight;
                    this.setOptions({
                      strokeColor: hover,
                      strokeWeight: obj.strokeWeight
                    });
                    label.set(name.toUpperCase());
                  });

                  obj.polySubmarket[id].addListener('mouseout', function(){
                    this.setOptions({
                      strokeColor: this.fillColor,
                      strokeWeight: this.origStrokeWeight
                    });
                    label.set('');
                  });

                  if(creds.signin){
                    obj.polySubmarket[id].addListener('click', (evnt) => {
                      if(!infowindow[name]){
                        // The passed data will be used in history component
                        document.querySelector('.map').dispatchEvent(
                          new CustomEvent('click', { detail: {
                            id: obj.polySubmarket[id],
                            details: {
                              trigger: maps.event.trigger,
                              evnt
                            }
                          }})
                        );

                        infowindow[name] = InfoWindow(map, maps, evnt.latLng);
                        getData(infowindow, name);
                      } else {
                        closeInfo(infowindow, name);
                      }
                    });
                  }
                });
              });
            }
          }
        }

          // });
        // });
        loaderHide();
      } catch(err){
        loaderErrMsg();
        console.log(err);
      }
    });
  }
}

export default Submarket;