import './index.css';

class label {
  constructor() {
    this.elem = document.querySelector('.label');
  }

  set(label) {
    this.elem.innerText = label;
  }
}

function Label(){
  return <div className="label"></div>;
}

export default Label;
export { label };
