class validation {
  constructor() {
    this.rule = {};
    this.number = {
      press(evnt){
        const key = evnt.keyCode;
        if((key < 48 || key > 57)
        && (key < 96 || key > 105)
        && (key !== 8 && key !== 13))
          evnt.preventDefault();
      },
      format(evnt){
        const target = evnt.target;
        target.type = 'text';

        const val = target.value;
        if(isNaN(val) === true
        || val <= 0)
          target.value = '';
      }
    }
  }

  required(){
    const parent = this.elem.parentElement;
    const err = parent.querySelector('.err');

    let msg = ' ';
    if(!this.elem.value){
      msg = 'This is required';
    }

    err.innerHTML = msg;
    return msg;
  }

  _required(){
    const parent = this.elem.parentElement;
    const err = parent.querySelector('.err');

    let msg = ' ';
    const val = this.elem.value;
    if(isNaN(val) === true || val <= 0){
      msg = 'This is required';
    }

    err.innerHTML = msg;
    return msg;
  }

  reformat(evnt){
    evnt.target.type = 'number';
  }

  email(){
    const parent = this.elem.parentElement;
    const err = parent.querySelector('.err');

    let msg = ' ';
    if(!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.elem.value)){
      msg = 'This is invalid email';
    }

    err.innerHTML = msg;
    return msg;
  }

  validate(evnt){
    this.elem = evnt.target;
    this.rule[this.elem.name]();
  }

  // This is commented out just for reference
  /* validate(elem){
    this.elem = elem;
    this.rule[this.elem.name]();
  }, */

  async submit(evnt, callback){
    evnt.preventDefault();
    const form = evnt.target;
    const data = {}; // Get form data

    let fieldErr = '';
    for(const elem of form.querySelectorAll('[name]')){
      this.elem = elem;
      if(this.rule[this.elem.name]){
        const result = await this.rule[this.elem.name]();
        if(!fieldErr && result !== ' ') fieldErr = this.elem;
      }
      data[this.elem.name] = this.elem.value; // Get form data
    }

    if(!fieldErr){
      // This is commented out just for reference
      /* const body = {};
      const formData = new FormData(form);
      formData.forEach((val, prop) => body[prop] = val);
      const data = JSON.stringify(body); */
      callback(data);
    } else {
      fieldErr.focus();
    }
  }
};

export default validation;