import { useState, useEffect } from 'react';
/* Commented out for the theme */
// import { RiFilterOffLine } from 'react-icons/ri';
import { MdOutlineFilterAlt as RiFilterOffLine } from 'react-icons/md';
import './index.css';

import { encodeFilters } from '../../utils/filters';

let addr = {};
let feat = {};
let feat2 = {};
let filters = [];
let setClear = () => void 0;

const clear = () => {
  addr.value = '';
  addr.form.requestSubmit();

  const change = new Event('change', { bubbles: true });

  feat.selectedIndex = 0;
  feat.dispatchEvent(change);

  feat2.selectedIndex = 0;
  feat2.dispatchEvent(change);

  let filter = {};
  for(filter of filters){
    filter.selectedIndex = 0;
  }
  filter.dispatchEvent(change);
  setClear(false);
};

const clearFilter = () => {
  const enfil = encodeFilters(filters);
  if(addr.value || feat.value !== '-' ||
    feat2.value !== '-' || enfil
  )
    setClear(true);
  else setClear(false);
};

function Clear(){
  const [isClear, setIsClear] = useState(false);
  setClear = setIsClear;

  useEffect(() => {
    addr = document.querySelector('.filters input');
    addr.addEventListener('input', clearFilter);

    feat = document.querySelector('[name="features"]');
    feat.addEventListener('change', clearFilter);

    feat2 = document.querySelector('[name="features2"]');
    feat2.addEventListener('change', clearFilter);

    filters = document.querySelectorAll('.filters .filter select');
    for(const filter of filters){
      filter.addEventListener('change', clearFilter);
    }
  }, []);

  const color = (!isClear)? '#606060' : '#fc6400';

  return (
    <>
      <RiFilterOffLine size="18" color={color} className="clear" title="Clear filters" onClick={clear} />
      <span className="cleartext" onClick={clear}>Clear Filter</span>
    </>
  );
}

export default Clear;
