// Frontend implementation of geodata
// import state from '../../data/geo/state.json';
import InfoWindow, { infoContent } from '../InfoWindow';
import { label as Label } from '../Label';
import { NewToken } from '../Token';
import {
  loaderShow, loaderHide,
  loaderMsg, loaderErrMsg
} from '../Loader';

let loaded = 0;
const rating = {};

const init = () => {
  return new Promise((resolve) => {
    if(!loaded){
      fetch(`${process.env.REACT_APP_API}/states`)
        .then(res => res.json())
        .then(res => {
          res.data.forEach(a => {
            rating[a.state_name] = a.zip_rating_num;
          });
          loaded = 1;
          resolve(1);
        })
        .catch(err => console.log(err));
    } else resolve(0);
  });
};

// const getData = (infowindow, name, fullname, rate) => {
const getData = (infowindow, name, level) => {
  NewToken(level).then(newtoken => {
    fetch(`${process.env.REACT_APP_API}/states/${name}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    })
      .then(res => res.json())
      .then(res => {
        if(res.status === 'OK'){
          const data = res.data[0];
          /* data.state = fullname;
          data.rating = loanrate(rate).num; */

          data.state_name = name.toUpperCase();

          const details = [
            {
              title: 'ZIP CODE DATA',
              fields: [
                'state_name', 'zip_rating', 'zip_rating_num'
              ]
            },
            {
              title: 'KEY STATISTICS',
              fields: [
                'year',
                'median_home_value', 'median_home_value_1_year', 'median_home_value_5_years',
                'median_household_income', 'median_household_income_1_years', 'median_household_income_5_years',
                'median_rent', 'median_rent_1_years', 'median_rent_5_years',
                'total_population', 'total_population_1_year', 'total_population_5_year',
                'unemploy_rate', 'unemploy_rate_1_year', 'unemploy_rate_5_year',
                'day_on_market', 'day_on_market_3_year',
                'rentvsown_own', 'rentvsown_rent',
                'home_construction_year'
              ]
            },
            {
              title: 'GRADES',
              fields: [
                'grade_overall', 'grade_access_to_bars', 'grade_access_to_coffee_shops',
                'grade_access_to_nightlife', 'grade_access_to_restaurants', 'grade_health_and_fitness',
                'grade_public_schools', 'grade_weather'
              ]
            },
            {
              title: 'DEMOGRAPHICS',
              fields: [
                'diversity_breakdown_asian', 'diversity_breakdown_hispanic', 'diversity_breakdown_white',
                'edu_breakdown_college_less', 'edu_breakdown_college_some', 'edu_breakdown_degree_associates',
                'edu_breakdown_degree_bachelors', 'edu_breakdown_degree_doctorate', 'edu_breakdown_degree_masters',
                'edu_breakdown_degree_professional', 'edu_breakdown_high_school', 'edu_breakdown_none'
              ]
            },
            {
              title: 'COMMUTE',
              fields: [
                'commute_method_bicycle', 'commute_method_carpooled', 'commute_method_drove_alone',
                'commute_method_motorcycle', 'commute_method_public_transportation', 'commute_method_taxicab',
                'commute_method_walked', 'commute_method_worked_at_home', 'commute_time_under_5',
                'commute_time_5_dash_9', 'commute_time_10_dash_14', 'commute_time_15_dash_19',
                'commute_time_20_dash_24', 'commute_time_25_dash_29', 'commute_time_30_dash_34',
                'commute_time_35_dash_39', 'commute_time_40_dash_44', 'commute_time_45_dash_59',
                'commute_time_over_90'
              ]
            },
            {
              title: 'HOME STATISTICS',
              fields: [
                'home_type_large_apt_building', 'home_type_mobile_home', 'home_type_single_family',
                'home_type_townhouse'
              ]
            },
            {
              title: 'UNEMPLOYMENT STATISTICS',
              fields: []
            },
            {
              title: 'ADDITIONAL INFO',
              fields: ['total_score']
            }
          ];

          infoContent(infowindow[name], details, data, () => closeInfo(infowindow, name));
        } else {
          loaderMsg(res.message);
          closeInfo(infowindow, name);
        }
      })
      .catch(err => {
        loaderErrMsg();
        closeInfo(infowindow, name);
        console.log(err);
      });
  });
};

const closeInfo = (infowindow, name) => {
  infowindow[name].close();
  delete infowindow[name];
};

const infowindow = {};
function State(obj, map, maps, polygon, hover, creds){
  if(creds.signin) loaderShow();

  init(obj).then(async () => {
    const label = new Label();

    try {
      // Backend implementation of geodata
      const response = await fetch(`${process.env.REACT_APP_API}/states?type=coords`)
      const res = await response.json();
      const state = res.data[0];
      //

      state.features.forEach(a => {
        let name = a.properties.STUSPS.toUpperCase();
        const fullname = a.properties.NAME;

        if(!obj.idLoaded)
          obj.id[a.properties.STATEFP] = [name, fullname];

        name = fullname;
        const nameUpCase = name.toUpperCase();

        const rate = obj.rate1(rating[name]);
        const color = obj.color[rate];

        if(a.geometry.coordinates){
          obj.iState++;
          const data = [];
          a.geometry.coordinates[0].forEach(c => {
            const coords = {lng: c[0], lat: c[1]}
            data.push(coords);
          });

          const id = obj.iState;
          obj.polyState[id] = polygon(data, color);
          if(obj.isFeats()) obj.polyState[id].setVisible(false);
          obj.polyState[id].setMap(map);

          obj.polyState[id].addListener('mouseover', function(){
            this.origStrokeWeight = this.strokeWeight;
            this.setOptions({
              strokeColor: hover,
              strokeWeight: obj.strokeWeight
            });
            label.set(nameUpCase);
          });

          obj.polyState[id].addListener('mouseout', function(){
            this.setOptions({
              strokeColor: this.fillColor,
              strokeWeight: this.origStrokeWeight
            });
            label.set('');
          });

          if(creds.signin){
            obj.polyState[id].addListener('click', (evnt) => {
              if(!infowindow[name]){
                // The passed data will be used in history component
                document.querySelector('.map').dispatchEvent(
                  new CustomEvent('click', { detail: {
                    id: obj.polyState[id],
                    details: {
                      trigger: maps.event.trigger,
                      evnt
                    }
                  }})
                );

                const level = creds.getLevel();
                infowindow[name] = InfoWindow(map, maps, evnt.latLng, level);
                // getData(infowindow, name, fullname, rate);
                getData(infowindow, name, level);
              } else {
                closeInfo(infowindow, name);
              }
            });
          }
        } else {
          a.geometry.geometries.forEach(b => {
            obj.iState++;
            const data = [];
            b.coordinates[0].forEach(d => {
              const coords = {lng: d[0], lat: d[1]}
              data.push(coords);
            });

            const id = obj.iState;
            obj.polyState[id] = polygon(data, color);
            if(obj.isFeats()) obj.polyState[id].setVisible(false);
            obj.polyState[id].setMap(map);

            obj.polyState[id].addListener('mouseover', function(){
              this.origStrokeWeight = this.strokeWeight;
              this.setOptions({
                strokeColor: hover,
                strokeWeight: obj.strokeWeight
              });
              label.set(nameUpCase);
            });

            obj.polyState[id].addListener('mouseout', function(){
              this.setOptions({
                strokeColor: this.fillColor,
                strokeWeight: this.origStrokeWeight
              });
              label.set('');
            });

            if(creds.signin){
              obj.polyState[id].addListener('click', (evnt) => {
                if(!infowindow[name]){
                  // The passed data will be used in history component
                  document.querySelector('.map').dispatchEvent(
                    new CustomEvent('click', { detail: {
                      id: obj.polyState[id],
                      details: {
                        trigger: maps.event.trigger,
                        evnt
                      }
                    }})
                  );

                  const level = creds.getLevel();
                  infowindow[name] = InfoWindow(map, maps, evnt.latLng, level);
                  // getData(infowindow, name, fullname, rate);
                  getData(infowindow, name, level);
                } else {
                  closeInfo(infowindow, name);
                }
              });
            }
          });
        }
      });
      obj.done = 1;
      loaderHide();
    } catch(err){
      loaderErrMsg();
      console.log(err);
    }
  });
}

export default State;