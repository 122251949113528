const getFilters = (filters) => {
  const arr = [];
  for(const filter of filters){
    if(filter.value !== '-')
      arr.push(filter.name+':'+filter.value);
  }
  return arr;
};

const encodeFilters = (filters) => {
  return encodeURIComponent(
    getFilters(filters)
  );
};

export { getFilters, encodeFilters };