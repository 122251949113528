const polygonConfig = {
  strokeOpacity: 1,
  strokeWeight: 0.1,
  fillOpacity: 0.5
};

// lightblue
const hover = '#4C4CFF';
// lightyellow
// const polcol = '#FFFF8A';
const polcol = 'white';

// Rate by range
const rate = (rank) => {
  let rate = '';
  if((rank && rank !== '') || rank === 0){
    rate = 'aaa';
    if(rank <= 280 && rank > 180)
      rate = 'aa';
    else if(rank <= 180 && rank > 100)
      rate = 'a';
    else if(rank <= 100 && rank > 60)
      rate = 'bbb';
    else if(rank <= 60 && rank > 20)
      rate = 'bb';
    else if(rank <= 20 && rank > -20)
      rate = 'b';
    else if(rank <= -20 && rank > -1000)
      rate = 'ccc';
    else if(rank <= -1000)
      rate = 'cc';
  }
  return rate;
};

// Rate by numeric rating
const rate1 = (rank) => {
  let rate = '';
  if((rank && rank !== '') || rank === 0){
    rate = 'aaa';
    if(rank <= 8.5 && rank > 7)
      rate = 'aa';
    else if(rank <= 7 && rank > 5.5)
      rate = 'a';
    else if(rank <= 5.5 && rank > 4)
      rate = 'bbb';
    else if(rank <= 4 && rank > 2.5)
      rate = 'bb';
    else if(rank <= 2.5 && rank > 1)
      rate = 'b';
    else if(rank <= 1)
      rate = 'ccc';
  }
  return rate;
};

const colors = {
    // darkgreen
  aaa: '#284F52',
    // green
   aa: '#3A7176',
    // lightgreen
    a: '#759B9F',
    // lightorange
  bbb: '#F5A99B',
    // orange
   bb: '#F18571',
    // darkorange
    b: '#A85D4F',
    // lightred
  ccc: '#C9714C',
    // red
   cc: '#B23500',
    // darkred
    c: '#7C2500'
};

const obj = {
  rate,
  rate1,
  color: colors,
  strokeWeight: 1,

  id: {},
  idLoaded: 0,
  done: 0,
  level: '',
  active: [],
  isZip: () => obj.level === 'zip',
  isFeats: () => void 0,
  reset: () => {
    obj.iState = -1;
    obj.polyState = [];

    obj.iCounty = -1;
    obj.polyCounty = [];

    obj.done = 0;
  },

  // State
  iState: -1,
  polyState: [],

  // County
  iCounty: -1,
  polyCounty: [],

  // Zip
  iZip: -1,
  polyZip: [],
  loadedZip: [],
  zipdone: 0,
  resetZip: () => {
    obj.visibility(obj.polyZip, false);
    obj.iZip = -1;
    obj.polyZip = [];
    obj.loadedZip = [];
    obj.zipdone = 0;
  },

  // Market
  iMarket: -1,
  polyMarket: [],

  // Submarket
  iSubmarket: -1,
  polySubmarket: [],

  visibility: (polys, state) => {
    for(const poly of polys){
      if(poly.visible === state)
        continue;

      poly.setVisible(state);
    }
  },
  A2OneVisibility: () => {
    if(!obj.isFeats()){
      const level = obj.level;
      obj.visibility(obj.polyState, level === 'state');
      obj.visibility(obj.polyCounty, level === 'county');
      obj.visibility(obj.polyZip, obj.isZip());
    }
  },
  marketVisibility: () => {
    obj.visibility(obj.polyMarket, !obj.isZip());
    obj.visibility(obj.polySubmarket, obj.isZip());
  }
};

const coordID = (lng, lat) => {
  let lngid = Math.trunc(lng);
  lngid = (lngid < 0)? 'N'+(lngid * -1) : 'P'+lngid;

  let latid = Math.trunc(lat);
  latid = (latid < 0)? 'N'+(latid * -1) : 'P'+latid;

  return lngid+latid;
};

const coordIDArr = (lng, lat) => {
  const coords = [];

  let prefix = 'P';
  let lngNum = Math.trunc(lng);
  if(lngNum < 0){
    prefix = 'N';
    lngNum = lngNum * -1;
  }
  coords.push(prefix);
  coords.push(lngNum);

  let prefix1 = 'P';
  let latNum = Math.trunc(lat);
  if(latNum < 0){
    prefix1 = 'N';
    latNum = latNum * -1;
  }
  coords.push(prefix1);
  coords.push(latNum);

  return coords;
};

const ziplevel = 10;

export {
  polygonConfig, hover,
  obj, coordID, coordIDArr,
  polcol, ziplevel
};