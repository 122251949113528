import Context, { useContext } from '../../context';
import ReactDOMServer from 'react-dom/server';
/* Commented out for the theme */
// import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { BsQuestionLg as AiOutlineQuestionCircle } from 'react-icons/bs';
import './index.css';

import { encodeFilters } from '../../utils/filters';
import { rate } from '../../utils/loans';
import { NewToken } from '../Token';
import { convert } from '../../utils/date';
import { shorten } from '../../utils/number';

let filters = [];
let loaded = 0;
let level = '';
let viewmode = '';

const inittotals = () => {
  if(!loaded){
    filters = document.querySelectorAll('.filters .filter select');
    for(const filter of filters){
      filter.addEventListener('change', totals);
    }

    totals();
    loaded = 1;
  }
};

const template = (title, value) => {
  let details = '<div class="col-md-2">';
  details += '<div class="cards">';
  details += `<h6>${title}: ${info}</h6>`;
  details += `<h2><center>${value}</center></h2>`;
  details += '</div>';
  details += '</div>';
  return details;
};

const showInfo = (evnt, msg) => {
  const p = document.querySelector('.totals h6 p');
  if(!p && evnt.type === 'click'){
    const target = evnt.currentTarget;

    const pop = document.createElement('p');
    const left = (target.getBoundingClientRect().left - 118);
    pop.style.left = left+'px';
    pop.innerHTML = msg;

    target.parentElement.append(pop);
  } else {
    if(p) p.remove();
  }
};

const info = ReactDOMServer.renderToString(
  <AiOutlineQuestionCircle size="18" color="#2e6ca4" />
);

const totals = () => {
  NewToken().then(newtoken => {
    const enfil = encodeFilters(filters);
    fetch(`${process.env.REACT_APP_API}/loandetails?type=getTotals&filters=${enfil}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    })
      .then(res => res.json())
      .then(res => {
        if(res.status === 'OK'){
          const data = res.data[0];
          const msgs = [];

          let details = '';

          const calcs = (data.calc)? data.calc.split('|') : [''];
          details += `<div class="infonote">Date of calculation ${convert(calcs.slice(-1)[0]).replace(/\//g, '.')}</div>`;

          details += '<div class="row">';

          msgs.push('Number of active loans in the loan portfolio.');
          const loans = (data.loans)? data.loans : '0';
          details += template('# of Active Loans', loans);

          msgs.push('Number of states (within the US) with active loans.');
          const states = (data.states)? data.states : '0';
          details += template('# of States', states);

          msgs.push('A portfolio-level weighted average of the rating of all active loans, determined by using A2One, the fund proprietary big-data model, on a rating scale of 1 to 5, where 5 is best and 1 is worst.');
          // const rating = rate(data.rating).num;
          const rating = 4.5;
          details += template('Rating (1 to 5)', rating);

          msgs.push('The aggregate amount of all the loans approved to borrowers across all active loans.<br><br> The fund\'s portion in each loan may vary (usually between 10-90%) depending, inter-alia, on loan size, legal structure vis-à-vis lender-partner (for example – co-invest, JV, SMA etc.), fund\'s guidelines and other considerations.');
          details += template('Total Loan Amount', `$${shorten(data.loan)}`);

          msgs.push('Total Loan Amount divided by the number of Active Loans.<br><br> The fund\'s portion in each loan may vary (usually between 10-90%) depending, inter-alia, on loan size, legal structure vis-à-vis lender-partner (for example – co-invest, JV, SMA etc.), fund\'s guidelines and other considerations.');
          let loansize = data.loan / data.loans;
          loansize = (loansize)? loansize : 0;
          details += template('Average Loan Size', `$${shorten(loansize)}`);

          msgs.push('The ratio of loans (by amount) bearing a variable interest rate linked to a common benchmark rate (e.g., SOFR, WSP, Libor). These loans are less impacted by changes in the interest environment (e.g., rising interest) as loan interest payments follow the changes in the underlying benchmark.');
          const fr = (data.floatrate)? data.floatrate.toFixed(1) : '0';
          details += template('% in Floating Rate', `${Number(fr)}%`);

          msgs.push('Generally, the loan portfolio is comprised of loans with an initial term (at closing) of 18 to 36 months and no interim repayments. The Average Loan Term indicates a portfolio-level weighted average of the remaining period till loan maturity and full repayment.');
          details += template('Average Loan Term', `${shorten(data.loanterm)} Mo`);

          msgs.push('A portfolio-level weighted average of the ratio between a Total Loan Amount of each loan and the value of the collateralized real-estate asset securing it.<br><br> For example, if a loan of $60M is secured by a multifamily asset appraised at a value of $100M - the LTV would be 60%.');
          const ltv = (data.ltv)? data.ltv.toFixed(1) : '0';
          details += template('Average LTV', `${Number(ltv)}%`);

          msgs.push('A portfolio-level weighted average of the ratio between a Total Loan Amount of each loan and the estimated budget for purchasing and completing the collateralized real estate asset.<br><br> For example, if a loan of $60M is designated for ground-up construction of a multifamily asset, whereas the total project budget (land + construction + soft + financing costs) is estimated at $80M - the LTC would be 75%.');
          const ltc = (data.ltc)? data.ltc.toFixed(1) : '0';
          details += template('Average LTC', `${Number(ltc)}%`);

          msgs.push('A portfolio-level weighted average of the ratio between the value of the sponsor\'s assets net of the sponsor\'s debt (subject asset and loan excluded) and the Total Loan Amount of the loan extended to the sponsor. The sponsor\'s net worth may indicate its financial capabilities to support the asset or cover its personal commitment in case the fund decides to trigger a personal guarantee stipulated in the loan documents.<br><br> For example, a sponsor asking for a $100M loan may have 3 assets with an aggregate value of $350M and a total debt of $200m. The net value would be $350M-$200M=$150M, and the ratio will be $150M / $100M or 150%.');
          const nw = (data.networth)? data.networth.toFixed(1) : '0';
          details += template('Sponsor Net Worth', `${Number(nw)}%`);

          msgs.push('The ratio between a sponsor\'s liquid assets (e.g., bank accounts and deposits, tradeable securities) and the full loan amount extended to him. The sponsor may be asked to use this liquidity to rebalance the project in case of budget overruns, unexpected events, replenish or rebalance an interest reserve account etc.<br><br> For example, a sponsor asking for a $100M loan may have $10M in its personal bank accounts or deposits. The ratio would be $10M / $100M or 10%.');
          const lq = (data.liquidity)? data.liquidity.toFixed(1) : '0';
          details += template('Sponsor Liquidity', `${Number(lq)}%`);

          msgs.push('The ratio between losses incurred (whereas a loan repayment was below the amount originally invested) and the aggregate Total Loan Amount of the fund since inception.<br><br> For example, assuming the aggregate amount of loans extended by the fund since inception amounts to $4B, and assuming that over time the fund has recognized an amount of $10M in actual losses – the ratio would be $10M / $4,000M or 0.25%.');
          details += template('Actual Capital Loss', data.loss+'%');

          details += '</div>';

          if(
            viewmode === 'admin' ||
            viewmode === 'lender'
          ){
            details += `<div class="row level ${level}">`;

            msgs.push('Test');
            details += template('Total Outstanding Amount', `$${shorten(data.outstanding)}`);

            msgs.push('Test');
            details += template('Total Unfunded Amount', `$${shorten(data.unfunded)}`);

            msgs.push('Test');
            details += template('Total Commitment Amount', `$${shorten(data.commitment)}`);

            msgs.push('Test');
            const recovery = (data.recovery)? data.recovery.toFixed(1) : '0';
            details += template('Average Recovery Rate', `${Number(recovery)}%`);

            msgs.push('Test');
            const interest = (data.interest)? data.interest.toFixed(1) : '0';
            details += template('Average Loan Interest Rate', `${Number(interest)}%`);

            msgs.push('Test');
            const pred = (data.pred)? data.pred.toFixed(1) : '0';
            details += template('Average PRED Rate', `${Number(pred)}%`);

            msgs.push('Test');
            details += template('Total Leverage Amount', `$${shorten(data.leverage)}`);

            msgs.push('Test');
            details += template('Total PRED Leverage', `$${shorten(data.predlev)}`);

            msgs.push('Test');
            const percent = (data.percent)? data.percent.toFixed(1) : '0';
            details += template('Average Leverage Percent', `${Number(percent)}%`);

            msgs.push('Test');
            const spread = (data.spread)? data.spread.toFixed(1) : '0';
            details += template('Average Leverage Spred', `${Number(spread)}%`);

            msgs.push('Test');
            const levint = (data.levint)? data.levint.toFixed(1) : '0';
            details += template('Average Leverage Interest Rate', `${Number(levint)}%`);

            msgs.push('Test');
            let curloan = 0;
            const curloans = (data.curloan)? data.curloan.split('|') : [];
            curloans.forEach(a => curloan += rate(a).num);
            curloan = curloan / curloans.length;
            curloan = (curloan)? curloan : 0;
            details += template('Average Current Loan Rating', `${shorten(curloan)}`);

            msgs.push('Test');
            let non = (data.leverage / data.outstanding) * 100;
            non = (non)? non : 0;
            non = non.toFixed(2);
            details += template('Non-Performing<br> Outstanding Ratio', `${Number(non)}%`);

            details += '</div>';
          }

          const totals = document.querySelector('.totals');
          totals.innerHTML = details;

          const svgs = totals.querySelectorAll('h6 svg');
          svgs.forEach((a, b) => {
            a.addEventListener('click', (evnt) => showInfo(evnt, msgs[b]));
            a.addEventListener('focusout', (evnt) => showInfo(evnt, ''));
          });
        }
      })
      .catch(err => console.log(err));
  });
};

function Totals(){
  const Contexts = useContext(Context);
  level = Contexts.level;
  viewmode = Contexts.viewmode;
  return <div className="totals"></div>
}

export default Totals;
export { inittotals };
