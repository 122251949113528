import { useEffect } from 'react';
import Context, { useContext } from '../../context';
import './index.css';

import Filter from '../Filter';

function ViewMode(){
  const Contexts = useContext(Context);
  const signin = Contexts.signin;
  const level = Contexts.level;
  const viewmode = Contexts.viewmode;

  // This is not working yet because components are not responding to it because of the design of the scripts
  // To make it working components should have events for this filter just like how other filters work
  // The temporary fix is to use window.location.reload(); but everything will be reset including filters
  // const reloader = Contexts.reloader;

  useEffect(() => {
    if(signin){
      const viewmodeElem = document.querySelector('.viewmode select');
      if(viewmodeElem){
        viewmodeElem.value = level[0].toUpperCase()+level.slice(1);
        viewmodeElem.addEventListener('change', (evnt) => {
          const elem = evnt.currentTarget;
          const val = elem.value;
          if(val !== '-')
            Contexts.level = val.toLowerCase();
          else {
            // elem.value = viewmode[0].toUpperCase()+viewmode.slice(1);
            elem.selectedIndex = 1;
            Contexts.level = viewmode;
          }

          // reloader();
          // window.location.reload();
        });
      }
    }
  }, [signin]); // eslint-disable-line

  return !signin || viewmode !== 'admin'? '' : <Filter data={['Admin', 'Investor']} name="viewmode" label="View Mode" classes="viewmode" />;
}

export default ViewMode;