import { useState } from 'react';

import {
  Button, Modal, ModalHeader,
  ModalBody, ModalFooter,
} from 'reactstrap';

let toggleConfirm = () => void 0;

function Confirm(){
  const [modalConfirm, setModalConfirm] = useState(false);
  toggleConfirm = (data) => {
    setModalConfirm(!modalConfirm);

    if(!modalConfirm){
      const {action, label, confirm} = data;
      (function retry(){
        const modalElem = document.querySelector('.confirmModal');
        if(modalElem){
          modalElem.querySelector
          ('.modal-body span')
          .innerHTML = action;

          const button = modalElem.querySelector
          ('.modal-footer .btn-primary');
          button.innerHTML = label;
          button.addEventListener('click', confirm);
        } else setTimeout(retry, 400);
      })();
    }
  };

  return (
    <Modal isOpen={modalConfirm} toggle={toggleConfirm} centered={true} modalClassName="confirmModal">
      <ModalHeader toggle={toggleConfirm}>Confirm</ModalHeader>
      <ModalBody>
        Are you sure you want to <span></span>?
      </ModalBody>
      <ModalFooter>
        <Button size="sm" color="secondary" onClick={toggleConfirm}>
          Cancel
        </Button>
        <Button size="sm" color="primary">
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default Confirm;
export { toggleConfirm };