import ReactDOMServer from 'react-dom/server';
import { RiMapPinFill } from 'react-icons/ri';
/* Commented out for the theme */
// import { BsArrowRightSquareFill } from 'react-icons/bs';
import { FiSearch as BsArrowRightSquareFill } from 'react-icons/fi';
import { Form, Col, Input } from 'reactstrap';
import './index.css';

import { ziplevel } from '../../utils/areas';
import { center, zoom } from '../../utils/map';

import Geocode from 'react-geocode';
Geocode.setApiKey(process.env.REACT_APP_MAPKEY);

let map = {};
let maps = {};
let obj = {};
const setAddressObjs = (_map, _maps, _obj) => {
  map = _map;
  maps = _maps;
  obj = _obj;
};

let marker = '';

const submit = (evnt) => {
  evnt.preventDefault();
  const form = evnt.target;
  const address = form.querySelector('[name="address"]');
  const name = address.value;
  if(name){
    if(obj.result){
      // if(obj.polyZip[obj.result].visible){
        // Trigger click to close infowindow
        new maps.event.trigger(
          obj.polyZip[obj.result],
          'click'
        );

        // Revert polygon color
        const poly = obj.polyZip[obj.result];
        poly.strokeColor = poly.fillColor;
        poly.strokeWeight = poly.origStrokeWeight;
        poly.setMap(null);
        setTimeout(() => {
          poly.setMap(map);
        }, 1);

        obj.resetZip();

        // Delete to reset
        delete obj.search;
        // delete obj.result;
      // }
    }

    // Remove white marker
    if(marker) marker.setMap(null);

    map.setCenter(center);
    map.setZoom(zoom);

    const icon = <RiMapPinFill className="addresspin" size="30" color="#0000B2" />;
    const svgMarker = ReactDOMServer.renderToString(icon);

    Geocode.fromAddress(name).then(
      (response) => {
        const result = response.results[0];
        const { lat, lng } = result.geometry.location;

        setTimeout(() => {
          map.setCenter({lat, lng});
          map.setZoom(ziplevel);

          if(result.types[0] === 'postal_code'){
            // obj.search = name;
            obj.search = result.address_components[0].long_name;
            const showInfo = () => {
              setTimeout(() => {
                if(obj.zipdone){
                  // This is to set the latLng evnt data
                  const evnt = {
                    latLng: {lat, lng}
                  };

                  new maps.event.trigger(
                    obj.polyZip[obj.result],
                    'click', evnt
                  );
                } else showInfo();
              }, 1000);
            };
            showInfo();
          } else {
            marker = new maps.Marker({
              position: {lat: lat, lng: lng},
              icon: {
                url: 'data:image/svg+xml;utf-8,'+encodeURIComponent(svgMarker)
              }
            });
            marker.setMap(map);
          }
        }, 1000);
      },
      (error) => {
        console.error(error);
      }
    );
  }
};

function Address(){
  return (
    <Col md={2} className="address">
      <Form onSubmit={submit}>
        <Input name="address" placeholder="Address" />
        <button type="submit">
          <BsArrowRightSquareFill size="21" color="#606060" />
        </button>
      </Form>
    </Col>
  );
}

export default Address;
export { setAddressObjs };
