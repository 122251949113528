import InfoWindow, { infoContent } from '../InfoWindow';
import { label as Label } from '../Label';
import { NewToken } from '../Token';
import {
  loaderShow, loaderHide,
  loaderMsg, loaderErrMsg
} from '../Loader';

let loaded = 0;
const rating = {};

let zip = [];
fetch(`${process.env.REACT_APP_API}/zips?type=zip`)
  .then(res => res.json())
  .then(res => zip = res.data[0])
  .catch(err => console.log(err));

// let fullname = {};
const init = (obj) => {
  return new Promise((resolve) => {
    if(!loaded){
      fetch(`${process.env.REACT_APP_API}/zips`)
        .then(res => res.json())
        .then(res => {
          res.data.forEach(a => {
            rating[a.zipcode_name.trim()] = a.zip_rating.toLowerCase();
          });

          // State fullnames using abbrv name as keys
          /* for(const key in obj.id){
            fullname[obj.id[key][0]] = obj.id[key][1];
          }; */

          loaded = 1;
          resolve(1);
        })
        .catch(err => console.log(err));
    } else resolve(0);
  });
};

const getData = (infowindow, name, level) => {
  NewToken(level).then(newtoken => {
    fetch(`${process.env.REACT_APP_API}/zips/${name}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    })
      .then(res => res.json())
      .then(res => {
        if(res.status === 'OK'){
          const data = res.data[0];

          /* if(data.state && data.state !== 'NULL')
            data.state = fullname[data.state]; */

          data.town_name = data.town_name.toUpperCase();
          data.county_name = data.county_name.toUpperCase();
          data.state_name = data.state_name.toUpperCase();

          const details = [
            {
              title: 'ZIP CODE DATA',
              fields: [
                'zipcode_name', 'town_name', 'county_name',
                'state_name', 'zip_rating', 'zip_rating_num'
              ]
            },
            {
              title: 'KEY STATISTICS',
              fields: [
                'year',
                'median_home_value', 'median_home_value_1_year', 'median_home_value_5_years',
                'median_household_income', 'median_household_income_1_years', 'median_household_income_5_years',
                'median_rent', 'median_rent_1_years', 'median_rent_5_years',
                'total_population', 'total_population_1_year', 'total_population_5_year',
                'unemploy_rate', 'unemploy_rate_1_year', 'unemploy_rate_5_year',
                'day_on_market', 'day_on_market_3_year',
                'rentvsown_own', 'rentvsown_rent',
                'home_construction_year'
              ]
            },
            {
              title: 'GRADES',
              fields: [
                'grade_overall', 'grade_access_to_bars', 'grade_access_to_coffee_shops',
                'grade_access_to_nightlife', 'grade_access_to_restaurants', 'grade_health_and_fitness',
                'grade_public_schools', 'grade_weather'
              ]
            },
            {
              title: 'DEMOGRAPHICS',
              fields: [
                'diversity_breakdown_asian', 'diversity_breakdown_hispanic', 'diversity_breakdown_white',
                'edu_breakdown_college_less', 'edu_breakdown_college_some', 'edu_breakdown_degree_associates',
                'edu_breakdown_degree_bachelors', 'edu_breakdown_degree_doctorate', 'edu_breakdown_degree_masters',
                'edu_breakdown_degree_professional', 'edu_breakdown_high_school', 'edu_breakdown_none'
              ]
            },
            {
              title: 'COMMUTE',
              fields: [
                'commute_method_bicycle', 'commute_method_carpooled', 'commute_method_drove_alone',
                'commute_method_motorcycle', 'commute_method_public_transportation', 'commute_method_taxicab',
                'commute_method_walked', 'commute_method_worked_at_home', 'commute_time_under_5',
                'commute_time_5_dash_9', 'commute_time_10_dash_14', 'commute_time_15_dash_19',
                'commute_time_20_dash_24', 'commute_time_25_dash_29', 'commute_time_30_dash_34',
                'commute_time_35_dash_39', 'commute_time_40_dash_44', 'commute_time_45_dash_59',
                'commute_time_over_90'
              ]
            },
            {
              title: 'HOME STATISTICS',
              fields: [
                'home_type_large_apt_building', 'home_type_mobile_home', 'home_type_single_family',
                'home_type_townhouse'
              ]
            },
            {
              title: 'UNEMPLOYMENT STATISTICS',
              fields: []
            },
            {
              title: 'ADDITIONAL INFO',
              fields: ['total_score']
            }
          ];

          infoContent(infowindow[name], details, data, () => closeInfo(infowindow, name));
        } else {
          loaderMsg(res.message);
          closeInfo(infowindow, name);
        }
      })
      .catch(err => {
        loaderErrMsg();
        closeInfo(infowindow, name);
        console.log(err);
      });
  });
};

const closeInfo = (infowindow, name) => {
  infowindow[name].close();
  delete infowindow[name];
};

const infowindow = {};
function Zip(obj, map, maps, polygon, hover, creds){
  if(obj.active.length){
    loaderShow();

    init(obj).then(async () => {
      const label = new Label();

      try {
        const base = obj.active[1];
        const range = 1;
        const start = base - range;
        const end = base + range;

        const latBase = obj.active[3];

        // Iteration for lng
        for(let i = start; i <= end; i++){
          obj.active[1] = i;

          const latStart = latBase - range;
          const latEnd = latBase + range;

          // Iteration for lat
          for(let i1 = latStart; i1 <= latEnd; i1++){
            obj.active[3] = i1;
            const active = obj.active.join('');

            if(zip.indexOf(active) >= 0 && obj.loadedZip.indexOf(active) < 0){
              const response = await fetch(`${process.env.REACT_APP_API}/zips?type=coords&filter=${active}`)
              const res = await response.json();
              const zips = res.data[0];

              obj.loadedZip.push(active);
              zips.forEach(a => {
                const name = a.name;

                let rate = rating[name];
                rate = (rate)? rate.replace(/[^\w ]/g, '') : '';
                const color = obj.color[rate];

                a.coordinates.forEach(b => {
                  obj.iZip++;
                  const data = [];
                  b.forEach(c => {
                    const coords = {lng: c[0], lat: c[1]}
                    data.push(coords);
                  });

                  const id = obj.iZip;
                  const pol = polygon(data, color);

                  if(obj.search){
                    if(obj.search === name){
                      obj.result = id;

                      // darkblue
                      const stroke = '#0000B2';
                      const weight = 1.1;

                      pol.strokeColor = stroke;
                      pol.origStrokeWeight = pol.strokeWeight;
                      pol.strokeWeight = weight;
                    }
                  }

                  obj.polyZip[id] = pol;
                  if(obj.isFeats()) obj.polyZip[id].setVisible(false);
                  obj.polyZip[id].setMap(map);

                  obj.polyZip[id].addListener('mouseover', function(){
                    if(obj.result !== id){
                      this.origStrokeWeight = this.strokeWeight;
                      this.setOptions({
                        strokeColor: hover,
                        strokeWeight: obj.strokeWeight
                      });
                    }
                    label.set(name);
                  });

                  obj.polyZip[id].addListener('mouseout', function(){
                    if(obj.result !== id){
                      this.setOptions({
                        strokeColor: this.fillColor,
                        strokeWeight: this.origStrokeWeight
                      });
                    }
                    label.set('');
                  });

                  // No click event if no color aka no zip_scale match like "98033": "aa-"
                  // if(color && creds.signin){
                  if(creds.signin){
                    obj.polyZip[id].addListener('click', (evnt) => {
                      if(evnt && !infowindow[name]){
                        // The passed data will be used in history component
                        document.querySelector('.map').dispatchEvent(
                          new CustomEvent('click', { detail: {
                            id: obj.polyZip[id],
                            details: {
                              trigger: maps.event.trigger,
                              evnt
                            }
                          }})
                        );

                        const level = creds.getLevel();
                        infowindow[name] = InfoWindow(map, maps, evnt.latLng, level);
                        getData(infowindow, name, level);
                      } else if(infowindow[name]){
                        closeInfo(infowindow, name);
                      }
                    });
                  }
                });
              });
            }
          }
        }
        obj.done = 1;
        obj.zipdone = 1;
        loaderHide();
      } catch(err){
        loaderErrMsg();
        console.log(err);
      }
    });
  }
}

export default Zip;