import { Col } from 'reactstrap';
import CanvasJSReact from '../../libs/canvasjs/canvasjs.react';
import { RxDotFilled } from 'react-icons/rx';
import './index.css';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

let back = null;
let statEvnt = 0;

const chartopts = {
  // interactivityEnabled: false,
  data: [{
    type: '',
    startAngle: 270,
    /* radius:  '60%',
    innerRadius: '50%',
    showInLegend: false,
    fillOpacity: .7,
    indexLabel: '{name}: {y}', */
    yValueFormatString: "#,##0'%'",
    indexLabelFontColor: 'gray',
    explodeOnClick: false,

    /* Added data for the theme */
    radius:  '70%',
    innerRadius: '45%',
    fillOpacity: .9,
    indexLabel: ''
    // showInLegend: true // Comment this out when starting to improve the charts
  }],
  toolTip: {
    enabled: false
    /* fontSize: 12,
    cornerRadius: 6,
    fontColor: '#fff',
    backgroundColor: '#25252C',
    borderColor: '#25252C',
    borderThickness: 5,
    content: '{name} {y}' */
  },

  /* Added data for the theme */
  /* Comment this out when starting to improve the charts */
  /* legend: {
    horizontalAlign: 'right',
    verticalAlign: 'center'
  } */
};

const colors = {
  0: '#094d54',
  1: '#203c90',
  2: '#ed674d',
  3: '#228489',
  4: '#b2b2b0',
  5: '#33333d',
  6: '#5a5b6f',
  7: '#8d8ea3',
  8: '#d8d9df',
  9: '#000000'
}

const chartshaper = (arr, limit = 3) => {
  let other = 0;
  const newarr = arr.filter((a, b) => {
    let obj = '';
    if(a.y < limit)
      other += parseFloat(a.y);
    else {
      a.color = colors[(b % 10)];
      a.mouseover = charthover;
      a.mouseout = charthoverout;
      obj = a;
    }
    return obj;
  });

  if(other){
    const len = newarr.length;

    newarr.push({
      name: 'Other',
      y: other.toFixed(2),
      color: colors[(len % 10)],
      mouseover: charthover,
      mouseout: charthoverout
    });

    if(len && (len % 10) === 0)
      newarr[len].color = '#541112';
  }

  return newarr;
};

const chartclick = () => charthoverout();

const charthover = (evnt) => {
  const legends = evnt.chart.canvas
  .closest('[id*="canvasjs-react-chart-container-"]')
  .nextElementSibling.querySelectorAll('.legend');

  const legend = legends[evnt.dataPointIndex];

  if(back) back.remove();
  back = document.createElement('div');
  back.className = 'back';
  back.style.backgroundColor = evnt.dataPoint.color;

  legend.parentElement.insertBefore(back, legend);

  if(!statEvnt){
    statEvnt = 1;
    document.querySelector('.statistics')
    .addEventListener('mouseover', () => {
      charthoverout();
    });
  }
};

// Retain this for referrence that evnt is working here
/* const charthoverout = (evnt) => {
  console.log(evnt);
}; */

const charthoverout = () => back.remove();

const Legend = ({options}) => {
  let legends = [];
  const elems = [];
  const optsdata = options.data;
  if(optsdata){
    let counter = 1;
    let group = 1;
    const chart = optsdata[0].name;
    const dp = optsdata[0].dataPoints;
    dp.forEach((a, b) => {
      const icon = <RxDotFilled size="23" color={a.color} />;
      const name = <span>{a.name}</span>;
      /* let val = parseInt(a.y);
      val = (val)? val : 1 ;
      val = <span>{val}%</span>; */
      const val = <span>{(chart === 'stat')? Number(a.y).toFixed(1) : parseInt(a.y)}%</span>;
      legends.push(<div key={b} className="legend">{icon} {name} {val}</div>);

      if(counter === 5 || b === (dp.length - 1)){
        elems.push(<div key={group} className="group">{legends}</div>);
        legends = [];
        counter = 0;
        group++;
      }

      counter++;
    });
  }

  return <div className="legends">{elems}</div>;
};

function Chart({title, options}){
  return (
    <Col md={4}>
      <h5 className="title">{title}</h5>
      <CanvasJSChart options={options} />
      <Legend options={options} />
      <div className="blocker"></div>
    </Col>
  );
}

export default Chart;
export { chartopts, chartshaper, chartclick };
