import { memo } from 'react';
import Context, { useContext } from '../../context';
import * as jose from 'jose';

let NewToken = async () => void 0;

function Token(){
  const { token, exptime } = useContext(Context);
  NewToken = async (level = '', params = null) => {
    const claims = jose.decodeJwt(token);
    if(level) claims.level = level;

    if(params){
      for(const key in params)
        claims[key] = params[key];
    }

    delete claims.exp;
    delete claims.iat;

    const secret = new TextEncoder()
      .encode(process.env.REACT_APP_APIKEY);

    const header = {
      alg: 'HS256',
      typ: 'JWT'
    };
    
    const jwt = await new jose.SignJWT(claims)
      .setProtectedHeader(header)
      .setIssuedAt()
      // Server time is advanced with 1min 50secs or 110secs
      // I will set 2m to have 10s difference/validity
      // .setExpirationTime('140s') // 30s validity
      // .setExpirationTime(`${Number(exptime) + 6}s`)

      // This can now handle negative/advanced device time
      // Additional 6000 milliseconds/6 seconds as validity
      .setExpirationTime(new Date(new Date().getTime() + Number(exptime) + 6000))
      .sign(secret);

    return jwt;
  };
}

export default memo(Token);
export { NewToken };