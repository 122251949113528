const products = (name) => {
  name = name.toLowerCase();
  const products = {
    a1: {
      family: 'Distributing',
      fee: 1.2,
      success: 10
    },
    b1: {
      family: 'Accrued',
      fee: 1.2,
      success: 10
    },
    c: {
      family: 'Distributing',
      fee: 1.2,
      success: 10
    },
    d1: {
      family: 'Distributing',
      fee: 0.9,
      success: 7.5
    },
    e: {
      family: 'Accrued',
      fee: 0.9,
      success: 7.5
    },
    f: {
      family: 'Distributing',
      fee: 0.9,
      success: 10
    },
    g: {
      family: 'Accrued',
      fee: 0.9,
      success: 10
    },
    h: {
      family: 'Accrued',
      fee: 1.2,
      success: 10
    },
    i: {
      family: 'Accrued',
      fee: 0,
      success: 15
    },
    j: {
      family: 'Distributing',
      fee: 1.44,
      success: 10
    },
    k: {
      family: 'Accrued',
      fee: 1.44,
      success: 10
    },
    l: {
      family: 'Distributing',
      fee: 1.44,
      success: 10
    },
    m: {
      family: 'Accrued',
      fee: 1.44,
      success: 10
    },
    o: {
      family: 'Distributing',
      fee: 0.9,
      success: 10
    },
    p: {
      family: 'Accrued',
      fee: 0.9,
      success: 10
    },
    q: {
      family: 'Distributing',
      fee: 0.9,
      success: 7.5
    },
    r: {
      family: 'Accrued',
      fee: 0.9,
      success: 7.5
    },
    s: {
      family: 'Accrued',
      fee: 0.25,
      success: 15
    },
    eu1: {
      family: 'Accrued',
      fee: 1.44,
      success: 10
    },
    eu: {
      family: 'Distributing',
      fee: 1.44,
      success: 10
    },
    i1: {
      family: 'Accrued',
      fee: 0,
      success: 15
    },
    z: {
      family: 'Accrued',
      fee: 0.9,
      success: 10
    },
    t1: {
      family: 'Accrued',
      fee: 0,
      success: 0
    },
    'eur co': {
      family: 'Distributing',
      fee: 1.2,
      success: 10
    },
    'eur co 1': {
      family: 'Accrued',
      fee: 1.2,
      success: 10
    },
    t: {
      family: 'Distributing',
      fee: 0,
      success: 0
    },
    w: {
      family: 'Distributing',
      fee: 0,
      success: 0
    },
    w1: {
      family: 'Accrued',
      fee: 0,
      success: 0
    }
  };
  return products[name];
};

export { products };