const mobile = () => document
.querySelector('.filterIcon')
.checkVisibility();

const openFullInfo = (full, close) => {
  if(mobile()){
    full.click();
    close.click();
  }
};

const closeFullInfo = (close) => {
  let closeElem = null;
  if(mobile()){
    closeElem = document.createElement('button');
    closeElem.innerHTML = 'Close';
    closeElem.className = 'close1';
    closeElem.addEventListener('click', () => close.click());
  }
  return closeElem? closeElem : '';
};

const allImages = (display, images) =>
mobile()? images.length - 1 : display;

const closeAllImages = (closeImages) =>
<button className="close1" onClick={closeImages}>Close</button>;

export {
  openFullInfo, closeFullInfo, allImages,
  closeAllImages
};