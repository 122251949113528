import { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Modal, ModalBody } from 'reactstrap';
import { MdDelete, MdAdd } from 'react-icons/md';
import './index.css';

import {
  loaderShow, loaderHide,
  loaderMsg, loaderErrMsg
} from '../Loader';

import ModalSlider, { toggleSlider } from '../ModalSlider';
import Delete, { toggleDelete } from '../Delete';
import { openSelector } from '../Upload';
import { NewToken } from '../Token';
import { allImages, closeAllImages } from '../Mobile/utils';

let toggleImages = () => void 0;
let closeImages = () => void 0;
let callback = () => void 0;
let callback1 = () => void 0;
const container = '[class*="col"]';
let img = '';
let slider = '';
let show = 0;
let list = [];
let imgsElem = null;
let display = 3;
let end = 0;
let click = 1;
let predid = '';

const confirm = (data) => {
  loaderShow();

  NewToken().then(newtoken => {
    const { path, id } = data;

    fetch(`${process.env.REACT_APP_API}/images/${path}/${escape(id)}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    })
      .then(res => res.json())
      .then(res => {
        if(res.status === 'OK'){
          loaderMsg(res.message);
          setTimeout(() => {
            toggleDelete();
            setTimeout(() => {
              closeImages();
              list.splice(list.indexOf(id), 1);
              if(end > display) end--;
              click = 0;
              if(list.length){
                setTimeout(() => {
                  callback1(list);
                }, 400);
              } else callback();
            }, 400);
          }, 3000);
        } else {
          if(res.message)
            loaderMsg(res.message);
          else loaderErrMsg();
        }
      })
      .catch(err => {
        loaderErrMsg();
        console.log(err);
      });
  });
};

const prev = async () => {
  if(end > display){
    loaderShow();

    // This try catch is for setEvents() > getToken()
    try {
      end--;

      const images = imgsElem.querySelectorAll(container);

      // Clone first image
      const first = images[0];
      const clone = first.cloneNode(true);

      // Inject clone as new first image
      const elem = await setEvents(clone, (end - display));
      imgsElem.prepend(elem);

      // Remove last image
      const last = images[display];
      const add = last.querySelector('.add');
      const close = last.querySelector('.close');
      last.remove();

      // Create add and close to new last image
      const newlast = images[display - 1];
      if(add) newlast.prepend(add);
      newlast.prepend(close);

      loaderHide();
    } catch(err){
      loaderErrMsg();
      end++;
      console.log(err);
    }
  }
};

const next = async () => {
  if(end < list.length - 1){
    loaderShow();

    // This try catch is for setEvents() > getToken()
    try {
      end++;

      const images = imgsElem.querySelectorAll(container);

      // Clone last image
      const last = images[display];
      const clone = last.cloneNode(true);

      // Recreate add event to cloned image
      const add = clone.querySelector('.add');
      if(add){
        const event = () => openSelector(predid, (imgs) => selectorCallback(imgs));
        add.addEventListener('click', event);
      }

      // Recreate close event to cloned image
      const close = clone.querySelector('.close');
      close.addEventListener('click', closeImages);

      // Inject clone as new last image
      const elem = await setEvents(clone, end);
      imgsElem.append(elem);

      // Remove first image
      images[0].remove();
      if(add) last.querySelector('.add').remove();
      last.querySelector('.close').remove();

      loaderHide();
    } catch(err){
      loaderErrMsg();
      end--;
      console.log(err);
    }
  }
};

const setEvents = async (clone, index) => {
  const style = clone.querySelector('[style*="url"]').style;
  const url = style.backgroundImage;
  const uri = url.split('/').slice(-1);
  const uris = uri[0].split('?token=');

  const file = list[index];
  const name = uris[0];
  const token = uris[1];

  let bgi = url.replace(name, file);
  bgi = bgi.replace(token, await getToken());
  style.backgroundImage = bgi;

  const img = clone.querySelector('[style*="url"]');
  img.addEventListener('click', () => {
    slide();
    toggleSlider(index, predid, list, show);
  });

  if(show){
    const del = clone.querySelector('.delete');
    clone.querySelector('.name').innerHTML = file;

    const delData = {
      id: file,
      path: predid,
      name: file,
      elem: container
    };

    del.addEventListener('click', (evnt) => toggleDelete(evnt, delData));
  }

  return clone;
};

const getToken = async () => {
  const newtoken = await NewToken();

  const response = await fetch(`${process.env.REACT_APP_API}/access`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': newtoken
    }
  });
  const res = await response.json();
  const access = res.data[0].token;
  return access;
};

const selectorCallback = (imgs) => {
  setTimeout(() => {
    closeImages();
    imgs = [...new Set([...list , ...imgs])];
    click = 0;
    setTimeout(() => {
      callback1(imgs);
    }, 400);
  }, 3000);
};

const slide = () => {
  setTimeout(() => {
    slider = document.querySelector('.slider-backdrop');
    if(slider) slider.classList.add('inImages');
    else slide();
  }, 400);
};

function ModalImages(){
  const [modalImages, setModalImages] = useState(false);
  toggleImages = async (_callback, _callback1, _predid, images, _show) => {
    callback = _callback;
    callback1 = _callback1;
    predid = _predid;
    list = images.slice();
    show = _show;

    const access = await getToken();

    const temp = (file) => {
      const url = (file)? `${process.env.REACT_APP_API}/images/${predid}/${escape(file)}?token=${access}` : '';
      let html = '<div class="col-md-3">';
      html += `<div style="background-image: url(${url})"></div>`;

      if(show){
        html += ReactDOMServer.renderToString(<MdDelete size="20" className="delete" />);
        html += `<div class="name">${file}</div>`;
      }

      html += '</div>';
      return html;
    };

    display = allImages(display, images);
    if(click) end = display;
    else click = 1;

    img = '';
    for(let n = (end - display); n <= end; n++){
      if(images[n])
        img += temp(images[n]);
    }

    const setImagesEvents = () => {
      setTimeout(() => {
        const imgsModal = document.querySelector('.images-modal');
        if(imgsModal){
          imgsElem = imgsModal.querySelector('.images');

          const cols = imgsElem.querySelectorAll(container);
          const lastcol = cols[cols.length - 1];

          const add = imgsModal.querySelector('.add');
          if(add){
            const event = () => openSelector(predid, (imgs) => selectorCallback(imgs));
            add.addEventListener('click', event);
            lastcol.prepend(add);
          }

          const close = imgsModal.querySelector('.close');
          lastcol.prepend(close);

          cols.forEach((a, b) =>{
            // Image click event
            const index = (end > display)? (end - display) + b : b;
            const img = a.querySelector('[style*="url"]');
            img.addEventListener('click', () => {
              slide();
              toggleSlider(index, predid, images, show);
            });

            if(show){
              // Delete click event
              const del = a.querySelector('.delete');
              const name = a.querySelector('.name').innerHTML;

              const delData = {
                id: name,
                path: predid,
                name: name,
                elem: container
              };

              del.addEventListener('click', (evnt) => toggleDelete(evnt, delData));
            }
          });
        } else setImagesEvents();
      }, 400);
    };
    setImagesEvents();
    setModalImages(!modalImages);
  };

  closeImages = () => setModalImages(!modalImages);

  return (
    <>
      <Modal isOpen={modalImages} toggle={closeImages} centered={true} size="xl" modalClassName="images-modal">
        <ModalBody>
          <span className="close" onClick={closeImages}>&times;</span>
          {show? <MdAdd size="29" className="add" /> : ''}
          <div dangerouslySetInnerHTML={{__html: img}} className="row images" />
          {(() => {
            if(list.length > 4){
              return (
                <>
                  <span onClick={prev} className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span onClick={next} className="carousel-control-next-icon" aria-hidden="true"></span>
                </>
              );
            }
          })()}
          {closeAllImages(closeImages)}
        </ModalBody>
      </Modal>
      <ModalSlider />
      {show? <Delete confirm={confirm} /> : ''}
    </>
  );
}

export default ModalImages;
export { toggleImages };