import { useEffect, memo } from 'react';
// import Context, { useContext } from '../../context';

let tabs = null;
let pop = 0;
const pops = [];
const openers = {};
const closers = {};
let closed = 0;
let dels = null;
let views = null;
const events = {};
const inModals = {};
let predid = '';

function History(){
  useEffect(() => {
    const addHistory = (page, init) => {
      if(pop) pop = 0;
      else window.history.pushState({ page, init }, null, null);
    };

    // Initial history at page load
    // window.history.replaceState({ page: '0', init: true }, null, null);
    window.history.pushState({ page: '0', init: true }, null, null);

    // Tabs
    tabs = document.querySelectorAll('.maptabs a');
    tabs.forEach((a, b) => a.addEventListener('click', () => addHistory(b.toString(), false)));

    // Mobile filter icon
    const filterIcon = document.querySelector('.filterIcon');
    const mobile = () => filterIcon.checkVisibility();

    if(mobile()){
      filterIcon.addEventListener('click', () => {
        let statePage = 'myfilterIcon';
        let closer = '.myfilters .close';

        if(!document.body.classList.contains('acctpage')){
          statePage = 'filterIcon';
          closer = '.filters .only .close';
        }

        (function retry(){
          closers[statePage] = document.querySelector(closer);
          if(closers[statePage]){
            if(pop || window.history.state.page !== statePage){
              if(!pop || window.history.state.page === statePage)
                pops.push(filterIcon);

              addHistory(statePage, false);

              if(!openers[statePage]){
                openers[statePage] = filterIcon;
                closers[statePage].addEventListener('click', close);
              }
            } else close();
          } else setTimeout(retry, 400);
        })();
      });

      let dsOpen = 0;
      const ds = document.querySelector('.account .dropsearch');
      const dsSel = ds.querySelector('select');
      dsSel.addEventListener('click', () => {
        if(!dsOpen){
          const statePage = 'investors';
          inModals[statePage] = 1;
          (function retry(){
            // No need to add a script for .dsCloseIcon
            // because it triggers .close when it is clicked
            closers[statePage] = ds.querySelector('.close');
            if(closers[statePage]){
              openers[statePage] = dsSel;
              closers[statePage].addEventListener('click', function self(evnt){
                clear();
                setTimeout(() => {
                  dsOpen = 0;
                }, 400);
                closers[statePage].removeEventListener(evnt.type, self);
              });

              pops.push(closers[statePage]);
              addHistory(statePage, false);
            } else setTimeout(retry, 400);
          })();
          dsOpen = 1;
        }
      });
    }

    // Infowindow marker and polygons
    const map = document.querySelector('.map');
    const getFullViewer = () => map.querySelectorAll('.gm-style-iw-d .full');
    map.addEventListener('click', (evnt) => {
      const id = evnt.detail.id;
      if(id){
        const statePage = 'infowindow';
        if(mobile()){
          (function retry(){
            if(map.querySelector('.fullwindow .close')){
              // This evnt.detail.details holds the
              // trigger and evnt from map.js
              id.details = evnt.detail.details;
              fullViewEvent(statePage, id);
            } else setTimeout(retry, 400);
          })();
        } else {
          const len = getFullViewer().length;
          (function retry(){
            const fullViewer = getFullViewer();
            const newLen = fullViewer.length;
            if(newLen > len){
              const fullViewElem = fullViewer[newLen - 1];
              fullViewElem.addEventListener('click', () => {
                fullViewEvent(statePage, fullViewElem);
              });

              const closeInfo = () => {
                delete openers[statePage];
                pops.pop();
              };

              const parent = fullViewElem.parentElement;
              parent.querySelector('.close')
              .addEventListener('click', closeInfo);

              parent.querySelector('.bottom')
              .addEventListener('click', closeInfo);
            } else setTimeout(retry, 400);
          })();
        }
      }
    });

    const fullViewEvent = (statePage, opener) => {
      closers[statePage] = map.querySelector('.fullwindow .close');
      openers[statePage] = opener;
      closers[statePage].addEventListener('click', clear);
      pops.push(closers[statePage]);
      addHistory(statePage, false);
    };
    // /Infowindow

    /* const addHistoryOnPopup = (statePage, opener, closer) => {
      if(!openers[statePage]){
        openers[statePage] = opener;
        closers[statePage] = document.querySelector(closer);
        closers[statePage].addEventListener('click', close);
      }

      pops.push(closers[statePage]);
      addHistory(statePage, false);
    }; */

    const addHistoryOnModal = (statePage, opener, closer, _static = 0) => {
      (function retry(){
        closers[statePage] = document.querySelector(closer);
        if(closers[statePage]){
          // No need for the condition because this process should be repeated
          // This is because modal is deleting the element not simply hiding
          // Which means the attached listener is also deleted
          // if(!openers[statePage]){
            openers[statePage] = opener;
            closers[statePage].addEventListener('click', clear);

            const modal = closers[statePage].closest('.modal');

            if(!_static){
              modal.addEventListener('click', (evnt) => {
                if(evnt.target.matches('.modal.fade'))
                  clear();
              });
            }

            const button = modal.querySelector('.modal-footer .btn');
            if(button) button.addEventListener('click', clear);
          // }

          pops.push(closers[statePage]);
          addHistory(statePage, false);
        } else setTimeout(retry, 400);
      })();
    };

    // Options
    document.querySelector('.options .dots').addEventListener('click', () => {
      (function retry(){
        const items = document.querySelectorAll('.options .dropdown-item');
        if(items[0].checkVisibility()){
          items.forEach((a, b) => {
            const params = ['options'+b, footer[b], '.modal-header .btn-close'];
            a.addEventListener('click', () => addHistoryOnModal(...params));
          });
        } else setTimeout(retry, 400);
      })();
    });

    const footer = document.querySelectorAll('.footer .links span');
    footer.forEach((a, b) => {
      const params = ['options'+b, a, '.modal-header .btn-close'];
      a.addEventListener('click', () => addHistoryOnModal(...params));
    });

    // Profile
    const profile = document.querySelector('.signout .name');
    profile.addEventListener('click', () => {
      (function retry(){
        const items = document.querySelectorAll('.signout .dropdown-item');
        if(items[0].checkVisibility()){
          items.forEach((a, b) => {
            const params = ['profile'+b, profile, '.modal-header .btn-close'];
            a.addEventListener('click', () => addHistoryOnModal(...params));
          });

          if(pop) items[window.history.state.page.replace('profile', '')].click();
        } else setTimeout(retry, 400);
      })();
    });

    // Images
    const imagesEvent = () => {
      (function retry(){
        const table = document.querySelector('.loanlist table');
        if(table){
          const images = document.querySelectorAll('.loanlist td div svg[imgs]');
          if(images[0]){
            images.forEach((a) => {
              imagesEvents(a);
            });

            const fileElem = document.querySelector('[name="file"].d-none');
            fileElem.addEventListener('click', (evnt) => predid = evnt.detail);
            fileElem.addEventListener('change', (evnt) => {
              let msg = '';
              const imagesModal = getImagesModal();
              (function retry(){
                if(!msg) msg = getLoader().children[2].innerText;
                if(!evnt.target.value && !loaderVisible()){
                  if(msg.indexOf('success') >= 0){
                    const svg = document.querySelector
                    (`.loanlist td div svg[pred="${predid}"]`);
                    if(!imagesModal){
                      // This block is for no images icon
                      imagesEvents(svg);
                    } else {
                      // This block is for has images icon
                      const svgParent = svg.parentElement;
                      (function retry1(){
                        if(!imagesModal.checkVisibility()){
                          addAllEvents(svg, svgParent);
                        } else setTimeout(retry1, 400);
                      })();
                    }
                  }
                } else setTimeout(retry, 400);
              })();
            });
          }
        } else setTimeout(retry, 400);
      })();
    };

    const getImagesModal = () => document.querySelector('.images-modal');
    const getLoader = () => document.querySelector('.loader');
    const loaderVisible = () => getLoader().checkVisibility();

    const imagesEvents = (svg) => {
      const params = ['images', svg, '.images-modal .close'];
      svg.addEventListener('click', () => {
        addHistoryOnModal(...params);

        (function retry(){
          const imagesModal = getImagesModal();
          if(imagesModal){
            addDelEvent(imagesModal, svg);
            addViewEvent(imagesModal);
            addNavEvent(imagesModal, svg);
          } else setTimeout(retry, 400);
        })();
      });
    };

    const addDelEvent = (imagesModal, svg) => {
      const name = 'dels';
      inModals[name] = 1;
      dels = imagesModal.querySelectorAll('.images .delete');
      dels.forEach((a, b) => {
        const params = [name+b, a, '.modal-header .btn-close'];
        events[name+b] = () => {
          addHistoryOnModal(...params);

          (function retry(){
            // Get the confirm delete button
            const del = document.querySelector
            ('.modal-footer .btn:nth-child(2)');
            if(del){
              del.addEventListener('click', () => {
                let msg = '';
                (function retry1(){
                  // Get loader msg once available
                  if(!msg) msg = getLoader().children[2].innerText;
                  // This will be executed once msg is available
                  // and loader is already hidden
                  if(msg && !loaderVisible()){
                    // Proceed only if loader msg contains
                    // success otherwise the retry is end
                    if(msg.indexOf('success') >= 0){
                      // Get the svg parent before hand
                      // before the svg is replaced
                      const svgParent = svg.parentElement;
                      (function retry2(){
                        if(!imagesModal.checkVisibility()){
                          clear();
                          // This condition means, do not execute
                          // if imagesModal is not showing back
                          // because images are all deleted
                          if(dels.length > 1)
                            addAllEvents(svg, svgParent);
                          else {
                            // This timeout ensures that the first
                            // clear() is already done before
                            // executing this clear()
                            setTimeout(() => {
                              clear();
                            }, 400);
                          }
                        } else setTimeout(retry2, 400);
                      })();
                    }
                  } else setTimeout(retry1, 400);
                })();
              });
            } else setTimeout(retry, 400);
          })();
        };
        a.addEventListener('click', events[name+b]);
      });
    };

    const addViewEvent = (imagesModal) => {
      const name = 'views';
      inModals[name] = 1;
      views = imagesModal.querySelectorAll('.images [style*="url"]');
      views.forEach((a, b) => {
        const params = [name+b, a, '.slider-modal .close'];
        events[name+b] = () => {
          addHistoryOnModal(...params);
        };
        a.addEventListener('click', events[name+b]);
      });
    };

    const addNavEvent = (imagesModal, svg) => {
      let time = () => void 0;
      imagesModal.querySelectorAll('[class*=carousel-control').forEach((a) => {
        a.addEventListener('click', () => {
          removeEvent(dels, 'dels');
          removeEvent(views, 'views');
          clearTimeout(time);
          time = setTimeout(() => {
            addDelEvent(imagesModal, svg);
            addViewEvent(imagesModal);
            addCloseEvent();
          }, 400);
        });
      });
    };

    const removeEvent = (elems, name) => {
      elems.forEach((a, b) => {
        a.removeEventListener('click', events[name+b]);
      });
    };

    const addCloseEvent = (all = 0) => {
      const imagesCloser = dels[dels.length - 1]
      .parentElement.querySelector('.close');

      // Reset image close event
      imagesCloser
      .addEventListener('click', clear);

      if(all){
        const modal = imagesCloser.closest('.modal');
        modal.addEventListener('click', (evnt) => {
          if(evnt.target.matches('.modal.fade'))
            clear();
        });

        const button = modal.querySelector('.modal-footer .btn');
        if(button) button.addEventListener('click', clear);
      }

      // Replace image close element
      pops.pop();
      pops.push(imagesCloser);
    };

    const addAllEvents = (svg, svgParent) => {
      (function retry(){
        // Check images modal until it is back after delete
        /* const imagesModal = getImagesModal();
        if(imagesModal &&
           imagesModal.checkVisibility()
        ){ */

        // This condition is better and consistent,
        // it checks until svg parent is null
        // which occurs when svg is replaced
        // as response to the delete action
        // if(!svg.parentElement){

        // Combined the two condition because
        // the last one still fails sometimes
        const imagesModal = getImagesModal();
        if(imagesModal &&
           imagesModal.checkVisibility() &&
           !svg.parentElement
        ){
          const newSvg = svgParent.children[0];
          imagesEvents(newSvg);
          addDelEvent(imagesModal, newSvg);
          addViewEvent(imagesModal);
          addNavEvent(imagesModal, newSvg);

          // This timeout assures addCloseEvent
          // and openers[] = newSvg will work
          // properly because image close
          // element is already in place
          // and state.page is already
          // back to images
          setTimeout(() => {
            openers[window.history.state.page] = newSvg;
            addCloseEvent(1);
          }, 400);
        } else setTimeout(retry, 400);
      })();
    };

    const loanlist = document.querySelector('.maptabs #loanList');
    if(loanlist){
      loanlist.addEventListener('click', function self(evnt){
        imagesEvent();
        const elem = evnt.currentTarget;
        elem.removeEventListener(evnt.type, self);
      });

      document.querySelectorAll
      ('.filters .filter select')
      .forEach((a) => {
        a.addEventListener('change', () => {
          const svg = document.querySelector('.loanlist td div svg');
          (function retry(){
            // if(svg && !svg.checkVisibility()){
            // Condition means svg exist but
            // detached to the parent loanlist
            if(svg && !svg.closest('.loanlist')){
              (function retry1(){
                const newSvg = document.querySelector('.loanlist td div svg');
                // if(newSvg && newSvg.checkVisibility()){
                // Condition means if the new svg is
                // created/attached to parent loanlist
                if(newSvg){
                  imagesEvent();
                } else setTimeout(retry1, 400);
              })();
            } else setTimeout(retry, 400);
          })();
        });
      });
    }
    // /Images

    // Documents
    const docsEvent = () => {
      const add = document.querySelector('.documents .add');
      if(add){
        add.addEventListener('click', () => {
          const params = ['section', add, '.modal-header .btn-close', 1];
          addHistoryOnModal(...params);

          (function retry(){
            // Get the sumbmit button
            const submit = document.querySelector
            ('.modal-body form button');
            if(submit){
              submit.addEventListener('click', () => {
                // Get length before it changed
                const len = getSections().length;
                submitEvent(
                  () => {
                    (function retry1(){
                      const sections = getSections();
                      const newSection = sections[len];
                      if(newSection){
                        clear();
                        addSecEvent(newSection);
                      } else setTimeout(retry1, 400);
                    })();
                  }
                );
              });
            } else setTimeout(retry, 400);
          })();
        });

        const getSections = () => document.querySelectorAll('.documents .section');
        const getFiles = (section) => section.querySelectorAll('tbody tr');

        (function retry(tries = 0){
          const sections = getSections();
          if(sections[0]){
            sections.forEach((a) => {
              addSecEvent(a);
              addFileEvent(getFiles(a), a);
            });
          } else {
            // Only 10 retries, if element still
            // not exist the execution will stop
            if(tries < 9){
              tries++;
              setTimeout(() => retry(tries), 400);
            }
          }
        })();

        const addSecEvent = (section) => {
          const fonts = section.querySelectorAll('span font');
          fonts[0].addEventListener('click', () => {
            const params = ['file', fonts[0], '.modal-header .btn-close', 1];
            addHistoryOnModal(...params);

            (function retry(){
              // Get the submit button
              const submit = document.querySelector
              ('.modal-body form button');
              if(submit){
                submit.addEventListener('click', () => {
                  // Get length before it changed
                  const len = getFiles(section).length;
                  submitEvent(
                    () => {
                      (function retry1(){
                        const files = getFiles(section);
                        const newFile = files[len];
                        if(newFile){
                          clear();
                          addFileEvent(files, section);
                        } else setTimeout(retry1, 400);
                      })();
                    }
                  );
                });
              } else setTimeout(retry, 400);
            })();
          });

          fonts[1].addEventListener('click', () => {
            const params = ['section1', fonts[1], '.modal-header .btn-close', 1];
            addHistoryOnModal(...params);

            (function retry(){
              // Get the submit button
              const submit = document.querySelector
              ('.modal-body form button');
              if(submit){
                submit.addEventListener('click', () => {
                  submitEvent(clear);
                });
              } else setTimeout(retry, 400);
            })();
          });

          fonts[2].addEventListener('click', () => {
            const params = ['section2', fonts[2], '.modal-header .btn-close'];
            addHistoryOnModal(...params);

            (function retry(){
              // Get the confirm delete button
              const del = document.querySelector
              ('.modal-footer .btn:nth-child(2)');
              if(del){
                del.addEventListener('click', () => {
                  // Get length before it changed
                  const len = getSections().length;
                  submitEvent(
                    () => {
                      (function retry1(){
                        if(getSections().length < len){
                          clear();
                        } else setTimeout(retry1, 400);
                      })();
                    }
                  );
                });
              } else setTimeout(retry, 400);
            })();
          });
        };

        const addFileEvent = (files, section) => {
          files.forEach((a) => {
            const acts = a.querySelectorAll('.actions font');

            const update = acts[0];
            update.addEventListener('click', () => {
              const params = ['file1', update, '.modal-header .btn-close', 1];
              addHistoryOnModal(...params);

              (function retry(){
                // Get the submit button
                const submit = document.querySelector
                ('.modal-body form button');
                if(submit){
                  submit.addEventListener('click', () => {
                    submitEvent(
                      () => {
                        (function retry1(){
                          if(!update.closest('.table')){
                            clear();
                            addFileEvent(getFiles(section), section);
                          } else setTimeout(retry1, 400);
                        })();
                      }
                    );
                  });
                } else setTimeout(retry, 400);
              })();
            });

            acts[1].addEventListener('click', () => {
              const params = ['file2', acts[1], '.modal-header .btn-close'];
              addHistoryOnModal(...params);

              (function retry(){
                // Get the confirm delete button
                const del = document.querySelector
                ('.modal-footer .btn:nth-child(2)');
                if(del){
                  del.addEventListener('click', () => {
                    // Get length before it changed
                    const len = getFiles(section).length;
                    submitEvent(
                      () => {
                        (function retry1(){
                          if(getFiles(section).length < len){
                            clear();
                          } else setTimeout(retry1, 400);
                        })();
                      }
                    );
                  });
                } else setTimeout(retry, 400);
              })();
            });
          });
        };
      }
    };

    const submitEvent = (success) => {
      // This time out ensures that the loader
      // already exist after clicking
      setTimeout(() => {
        // This condition ensures that data is
        // ready to submit and passed the validation
        if(loaderVisible()){
          let msg = '';
          // Get loader initial msg
          const initMsg = getLoader().children[2].innerText;
          (function retry(){
            if(!msg){
              // Get loader current msg
              const curMsg = getLoader().children[2].innerText;
              // Set loader final msg
              if(initMsg !== curMsg){
                if(curMsg) msg = curMsg;
                else msg = initMsg;
              }
            }
            // This will be executed once final msg is
            // available and loader is already hidden
            if(msg && !loaderVisible()){
              // Proceed only if loader msg contains
              // success otherwise the retry is end
              if(msg.indexOf('success') >= 0){
                success();
              }
            } else setTimeout(retry, 400);
          })();
        }
      }, 400);
    };

    const documents = document.querySelector('.maptabs #documents');
    if(documents){
      documents.addEventListener('click', function self(evnt){
        docsEvent();
        const elem = evnt.currentTarget;
        elem.removeEventListener(evnt.type, self);
      });
    }
    // /Documents

    // Commissions
    const comms = document
    .querySelector('.commissions');
    if(comms){
      comms.querySelectorAll
      ('.file').forEach((a) => {
        a.addEventListener('click', () => {
          const params = ['commissions', a, '.modal-header .btn-close', 1];
          addHistoryOnModal(...params);
        });
      });

      if(mobile()){
        let dsOpen = 0;
        const dsComms = comms.querySelector('.commissions .dropsearch');
        const dsCommsSel = dsComms.querySelector('select');
        dsCommsSel.addEventListener('click', () => {
          if(!dsOpen){
            const statePage = 'accounts';
            (function retry(){
              // No need to add a script for .dsCloseIcon
              // because it triggers .close when it is clicked
              closers[statePage] = dsComms.querySelector('.close');
              if(closers[statePage]){
                openers[statePage] = dsCommsSel;
                closers[statePage].addEventListener('click', function self(evnt){
                  clear();
                  setTimeout(() => {
                    dsOpen = 0;
                  }, 400);
                  closers[statePage].removeEventListener(evnt.type, self);
                });

                pops.push(closers[statePage]);
                addHistory(statePage, false);
              } else setTimeout(retry, 400);
            })();
            dsOpen = 1;
          }
        });
      }
    }
    // /Commissions

    // Loan new loans
    let newloans = document.querySelector('.newloans');
    if(newloans){
      newloans = newloans.parentElement;
      newloans.addEventListener('click', () => {
        const params = ['newloans', newloans, '.modal-header .btn-close'];
        addHistoryOnModal(...params);

        (function retry(){
          // Get the sumbmit button
          const submit = document.querySelector
          ('.modal-footer .btn:nth-child(2)');
          if(submit){
            submit.addEventListener('click', () => {
              submitEvent(clear);
            });
          } else setTimeout(retry, 400);
        })();
      });

      // Rollback loans
      const rollBackLoans = document.querySelector('.rollBackLoans').parentElement;
      rollBackLoans.addEventListener('click', () => {
        const params = ['rollBackLoans', rollBackLoans, '.modal-header .btn-close'];
        addHistoryOnModal(...params);

        (function retry(){
          // Get the sumbmit button
          const submit = document.querySelector
          ('.modal-footer .btn:nth-child(2)');
          if(submit){
            submit.addEventListener('click', () => {
              submitEvent(clear);
            });
          } else setTimeout(retry, 400);
        })();
      });
      // /Rollback loans
    }
    // /Load new loans

    const close = () => {
      closed = 1;
      window.history.back();
    };

    const clear = () => {
      if(pop) pop = 0;
      else close();
    };
  }, []);

  // Tabs only
  /* onpopstate = (evnt) => {
    pop = 1;
    const page = evnt.state.page;
    if(page) tabs[page].click();
  }; */

  // Including pop-ups
  onpopstate = (evnt) => {
    pop = 1;
    const page = evnt.state.page;
    if(pops.length && !inModals[page.replace(/\d+/g, '')]){
      const popped = pops.pop();
      if(closed) pop = 0;
      else popped.click();
      closed = 0;
    } else {
      // const page = evnt.state.page;
      if(!isNaN(page)) tabs[page].click();
      else {
        const opener  = openers[page];
        // Added a condition for opener because there is an error when
        // forward history is clicked and the small infowindow is closed
        // so upon closing of small inforwindow the opener is also deleted
        // then when forward history is clicked it will just back or like
        // nothing is happened because of the else block calling window.history.back()
        if(opener){
          if(opener.click) opener.click();
          else {
            try {
              opener.dispatchEvent(new Event('click'));
            } catch(err){
              // This is for map marker/polygon as opener which
              // dont have click and dispatchEvent and only triggered
              // like below scripts with parameters marker/polygon, click
              // and the evnt because it causes an error if I did not
              // populate the third parameter event if I put {}
              // it still have errors
              if(opener.details){
                new opener.details.trigger
                (opener, 'click', opener.details.evnt);
              } else console.log(err);
            }
          }
        } else window.history.back();
      }
    }
  };
}

export default memo(History);
