import { useState, useEffect } from 'react';
import Context, { useContext } from '../../context';
import ReactDOMServer from 'react-dom/server';
import GoogleMapReact from 'google-map-react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

import Pin, { setPinVars, showInfo } from '../Pin/pin';

import State from '../State';
import County from '../County';
import Zip from '../Zip';
import ModalSlider, { toggleSlider } from '../ModalSlider';
import {
  polygonConfig, hover,
  obj, coordIDArr, polcol,
  ziplevel
} from '../../utils/areas';
import { center, zoom /*, styles */ } from '../../utils/map';

import { setAddressObjs } from '../Address';
import { encodeFilters } from '../../utils/filters';
import { NewToken } from '../Token';
import Submarket from '../Submarket';
import Market from '../Market';

let dragTimer = '';
let filters = [];
let max = 0;
let min = 0;
let creds = {};
let level = '';
let name = null;
let handleMarkers = () => void 0;
let markers = [];
let clusters = {};
let loaded = 0;
let loadMarkets = () => void 0;

// This fixes the issue in load new loans not updating the markers if map page is not yet visited
// This is also essential to keep the data/markers updated especially if there are changes to data before visiting map page
const initmap = () => {
  if(!loaded){
    (function retry(){
      if(filters.length){
        filters[0]
        .dispatchEvent
        (new Event('change'));
      } else setTimeout(retry, 400);
    })();

    loaded = 1;
  }
};

const setZoomLevel = (call) => {
  setTimeout(() => {
    if(obj.done) call();
    else setZoomLevel(call);
  }, 300);
};

const handleApiLoaded = (map, maps, coordinates) => {
  handleMarkers = (coords) => {
    // Add space on the same locations
    const locs = {};

    markers = coords.map(a => {
      // No space on the same locations
      /* const lat = a.lat;
      const lng = a.lng; */

      // Add space on the same locations
      let lat = a.lat;
      let lng = a.lng;
      const loc = lat+' '+lng;

      if(!locs[loc]) locs[loc] = 1;
      else locs[loc] += 1;

      const space = 0.00010;
      switch(locs[loc]){
        case 2:
          lat = lat + space;
          lng = lng + space;
          break;
        case 3:
          lat = lat - space;
          lng = lng + space;
          break;
        case 4:
          lat = lat - space;
          lng = lng - space;
          break;
        case 5:
          lat = lat + space;
          lng = lng - space;
          break;
        default:
          // Remove warning
          break;
      }
      // /Add space on the same locations

      const svgMarker = encodeURIComponent(
        ReactDOMServer.renderToString(
          <Pin status={a.loan_status}
            outstanding={a.outstanding_amount} images={a.images}
          />
        )
      );

      const marker = new maps.Marker({
        position: {lat: lat, lng: lng},
        icon: {
          url: 'data:image/svg+xml;utf-8,'+svgMarker
        }
      });

      const loanname = a.loan_name;
      if(loanname){
        marker.addListener('mouseover', (evnt) => {
          name = document.createElement('div');
          name.className = 'loanname';
          name.innerHTML = loanname;

          const div = evnt.domEvent.currentTarget;
          div.style.overflow = 'unset';
          div.append(name);

          // Use this to access the marker dom
          // console.log(evnt.domEvent);
        });

        marker.addListener('mouseout', (evnt) => {
          const div = evnt.domEvent.currentTarget;
          div.style.overflow = 'hidden';
          name.remove();
        });
      }

      marker.addListener('click', (evnt) => {
        // The passed data will be used in history component
        /* document.querySelector('.map').dispatchEvent(
          new CustomEvent('click', { detail: { id: a.id }})
        ); */
        document.querySelector('.map').dispatchEvent(
          new CustomEvent('click', { detail: {
            id: marker,
            details: {
              trigger: maps.event.trigger,
              evnt
            }
          }})
        );

        showInfo(evnt.domEvent, map, maps, a.id, lat, lng, toggleSlider);
      });

      return marker;
    });

    const renderer = {
      // render: (cluster, stats) => {
      render: (cluster) => {
        const { count, position } = cluster;

        // change color if this cluster has more markers than the mean cluster
        /* const color =
        count > Math.max(10, stats.clusters.markers.mean)
          ? "#ff0000"
          : "#0000ff"; */

        const color = '#0000ff';

        // create svg url with fill color
        const svg = window.btoa(`
        <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
        <circle cx="120" cy="120" opacity=".6" r="70" />
        <circle cx="120" cy="120" opacity=".3" r="90" />
        <circle cx="120" cy="120" opacity=".2" r="110" />
        <circle cx="120" cy="120" opacity=".1" r="130" />
        </svg>`);

        // create marker using svg icon
        return new maps.Marker({
        position,
        icon: {
          url: `data:image/svg+xml;base64,${svg}`,
          scaledSize: new maps.Size(45, 45),
        },
        label: {
          text: String(count),
          color: "rgba(255,255,255,0.9)",
          fontSize: "12px",
        },
        // adjust zIndex to be above other markers
        zIndex: 1000 + count,
        });
      }
    };

    clusters = new MarkerClusterer({ markers, map, renderer });
  };

  handleMarkers(coordinates);
  setAddressObjs(map, maps, obj);

  const polygon = (coords, color) => {
    color = (!color)? polcol : color;
    return new maps.Polygon({
      paths: coords,
      strokeColor: color,
      fillColor: color,
      ...polygonConfig
    });
  };

  const params = [
    obj, map, maps,
    polygon, hover, creds
  ];

  // Default zoom level
  obj.level = 'state';
  State(...params);

  // Change zoom level
  setZoomLevel(() => map.setZoom(5));

  map.addListener('zoom_changed', function(){
    const zoomLevel = map.getZoom();

    if(zoomLevel <= 4){
      if(obj.level !== 'state'){
        obj.level = 'state';
      }
    } else if(zoomLevel >= 5 && zoomLevel <= 9){
      if(obj.level !== 'county'){
        obj.level = 'county';
        if(!obj.polyCounty.length)
          County(...params);
      }
    } else if(zoomLevel >= ziplevel){
      if(obj.level !== 'zip'){
        obj.active = coordIDArr(
          map.getCenter().lng(),
          map.getCenter().lat()
        );

        obj.level = 'zip';
        loadZipLevel();
      }
    }

    if(!obj.isFeats())
      obj.A2OneVisibility();
    else
      obj.marketVisibility();
  });

  map.addListener('drag', function(){
    if(obj.level === 'zip'){
      if(dragTimer) clearTimeout(dragTimer);
      dragTimer = setTimeout(() => {
        obj.active = coordIDArr(
          map.getCenter().lng(),
          map.getCenter().lat()
        );
        loadZipLevel();
      }, 500);
    }
  });

  loadMarkets = () => {
    Market(...params);
    Submarket(...params);
  };

  const loadZipLevel = () => {
    if(!obj.isFeats())
      Zip(...params);
    else
      Submarket(...params);
  };
};

const clear = () => {
  // Remove markers
  markers.forEach(a => a.setMap(null));
  // Remove clusters
  if(clusters.clearMarkers)
    clusters.clearMarkers();
};

function Map(){
  const [coordinates, setCoordinates] = useState([]);

  const Contexts = useContext(Context);
  const signin = Contexts.signin;
  level = Contexts.level;

  creds = {signin, getLevel: () => level};

  const handleFilter = () => {
    NewToken().then(newtoken => {
      let url = `${process.env.REACT_APP_API}/loandetails`;

      const enfil = encodeFilters(filters);
      if(enfil) url = `${url}?filters=${enfil}`;

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': newtoken
        }
      })
        .then(res => res.json())
        .then(res => {
          clear();
          if(res.status === 'OK'){
            const data = res.data.map(a => {
              // Get total commitment, if zero get outstanding amount
              const oa = (a.total_commitment)? a.total_commitment * -1 : a.outstanding_amount;

              // Delete total commitment
              delete a.total_commitment;

              // Replace outstanding amount
              a.outstanding_amount = oa;

              return a;
            });

            handleMarkers(data);
          } else handleMarkers([]);
        })
        .catch(err => console.log(err));
    });
  };

  useEffect(() => {
    if(signin){
      NewToken().then(newtoken => {
        fetch(`${process.env.REACT_APP_API}/loandetails`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': newtoken
          }
        })
          .then(res => res.json())
          .then(res => {
            if(res.status === 'OK'){
              const data = res.data.map(a => {
                // Get total commitment, if zero get outstanding amount
                const oa = (a.total_commitment)? a.total_commitment * -1 : a.outstanding_amount;

                // Delete total commitment
                delete a.total_commitment;

                // Replace outstanding amount
                a.outstanding_amount = oa;

                // Set max and min oa
                if(oa > max) max = oa;
                if(oa < min) min = oa;

                return a;
              });
              setPinVars(max, min, level);
              setCoordinates(data);
            }
          })
          .catch(err => console.log(err));

        const feat = document.querySelector('[name="features"]');
        feat.addEventListener('change', (evnt) => {
          const val = evnt.currentTarget.value;
          if(val === 'Show A2One Only') clear();
          else if(clusters.addMarkers)
            clusters.addMarkers(markers);
        });

        const feat2 = document.querySelector('[name="features2"]');
        feat2.addEventListener('change', function self(evnt){
          const elem = evnt.currentTarget;
          if(elem.value === 'A2One - Multifamily Data'){
            loadMarkets();
            elem.removeEventListener(evnt.type, self);
          }
        });

        obj.isFeats = () => {
          return (
            feat.value === 'Show Loans Only' ||
            feat2.value === 'A2One - Multifamily Data'
          );
        };

        filters = document.querySelectorAll('.filters .filter select');
        for(const filter of filters){
          filter.addEventListener('change', handleFilter);
        }

        const mode = (val) => {
          level = val.toLowerCase();
          setPinVars(max, min, level);
        };

        const view = document.querySelector('.viewmode select');
        if(view) view.addEventListener('change', () => mode(view.value));

        obj.reset();
      });
    } else setCoordinates([]);
  }, [
    setCoordinates, signin
  ]);

  let height = window.innerHeight
  || document.documentElement.clientHeight
  || document.body.clientHeight;
  height = height - (70 + 64 + 35.5);
  // 70 (Nav height); 64 (Filter height); 35.5 (Footer height)

  return (
    <>
      <div className="map" style={{height}}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAPKEY }}
          // bootstrapURLKeys={{ key: '' }}
          defaultCenter={center}
          defaultZoom={zoom}
          // options={{
          //   styles: styles
          // }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, coordinates)}
        >
        </GoogleMapReact>
      </div>
      <ModalSlider />
    </>
  );
}

export default Map;
export { initmap, obj };
