import { useState } from 'react';
import Context, { useContext } from '../../context';
import { Container, Button } from 'reactstrap';
import Excel, { ExcelIcon } from '../Excel';
import './index.css';

let loaded = 0;
let level = '';
let sortfield = 'id desc';
let trans = [];
let newtrans = [];
let settrans = () => void 0;
let sorting = 0;
let currencies = {};

// Currency selection
// let actcur = '';

// Currency deselection
let inactcurs = [];
let tempcur = '';

let statval = true;
let accountids = [];
let stats = {};
let lastDate = '';

let newfields = {};

const initinvestment = () => {
  if(!loaded){
    setNewFields();

    const view = document.querySelector('.viewmode select');
    if(view) view.addEventListener('change', () => mode(view.value));

    const sortTrans = localStorage.sortTrans;
    if(sortTrans) sortfield = sortTrans;
    loaded = 1;
    // console.log('trans', trans);
  }
};

const setNewFields = () => {
  newfields = JSON.parse(JSON.stringify(fields));
  if(level !== 'admin') delete newfields.Comments__c;
};

const fields = {
  Tzur_Name__c: ['ACCOUNT NAME', 'string'],
  Series_Name__c: ['SERIES NAME', 'string'],
  Date__c: ['DATE', 'date'],
  Investment_Type__c: ['TRANSACTION TYPE', 'string'],
  Class__c: ['CLASS', 'string'],
  Amount__c: ['AMOUNT', 'number'],
  Comments__c: ['COMMENT', 'string']
};

const mode = (val) => {
  level = val.toLowerCase();
  setNewFields();
  filter();
};

const TempTh = ({id, children}) => {
  const sf = sortfield.split(' ');
  let down = '', up = '';
  if(sf[0] === id){
    if(!sf[1]) up = ' active';
    else down = ' active';
  }

  return (
    <th id={id} onClick={() => sortby(id)}>
      {children.replace(/ /g, '\xa0')}&nbsp;
      <span className={"down"+down}>&#9660;</span>
      <span className={"up"+up}>&#9650;</span>
    </th>
  );
};

const string = (field) => {
  newtrans.sort((a, b) => {
    let fa = a[field],
        fb = b[field];

    fa = fa? fa.toLowerCase() : '';
    fb = fb? fb.toLowerCase() : '';

    if (fa < fb) return -1;
    if (fa > fb) return 1;
    return 0;
  });
};

const _string = (field) => {
  newtrans.sort((a, b) => {
    let fa = a[field],
        fb = b[field];

    fa = fa? fa.toLowerCase() : '';
    fb = fb? fb.toLowerCase() : '';

    if (fa < fb) return 1;
    if (fa > fb) return -1;
    return 0;
  });
};

const date = (field) => {
  newtrans.sort((a, b) => {
    let da = new Date(a[field]),
        db = new Date(b[field]);
    return da - db;
  });
};

const _date = (field) => {
  newtrans.sort((a, b) => {
    let da = new Date(a[field]),
        db = new Date(b[field]);
    return db - da;
  });
};

const sortby = (field, load = 0) => {
  sorting = 1;

  let sort = (field === sortfield)? ' desc' : '';

  if(!load){
    sortfield = field+sort;
    localStorage.sortTrans = sortfield;
  } else sort = (!sort)? ' desc' : '';

  if(field === 'id'){
    if(!sort) date('Date__c');
    else _date('Date__c');
  } else if(newfields[field][1] === 'number'){
    if(!sort)
      newtrans.sort((a, b) => a[field] - b[field]);
    else
      newtrans.sort((a, b) => b[field] - a[field]);
  } else if(newfields[field][1] === 'date'){
    if(!sort) date(field);
    else _date(field);
  } else {
    if(!sort) string(field);
    else _string(field);
  }

  settrans();
};

const filter = () => {
  setTimeout(() => {
    const account = document.querySelector('.account');
    const filters2 = account.querySelectorAll('.filter select');
    const accounts = filters2[1]; const classes = filters2[3];

    const accountsval = accounts.value;
    const classesval = classes.value;

    newtrans = [];
    let invested = 0;
    let returned = 0;
    let dividends = 0;

    trans.forEach(a => {
      const date = new Date(a.Date__c);
      const curcode = currencies[a.CurrencyIsoCode.toLowerCase()];
      const accid = a.Account__c;

      // Replaced with USD or ILS not 0 then it is active
      // const stat = stats[accid];
      const stat = (stats[accid])? stats[accid] : false;

      const clss = a.Class__c;

      // Currency selection
      // const curval = (actcur)? actcur : curcode;

      // Currency deselection
      const incurlist = Object.values(currencies).indexOf(curcode) >= 0;
      let curval = true;
      if(tempcur) curval = tempcur === curcode;
      else if(inactcurs.length) curval = inactcurs.indexOf(curcode) < 0;

      const accsval = (accountsval !== '-')? accountsval : accid;
      const sttval = (statval !== '')? statval : stat;
      const clssval = (classesval !== '-')? classesval : clss;
      const accids = (accountids.length)? accountids : accid;
      const fdate = date.toLocaleDateString('en-us', { year: 'numeric', month: 'short'});

      if(
        // Currency selection
        // curval === curcode &&

        // Currency deselection
        // The incurlist is to filter the data based on listed currency
        // In case of new currency it will not be included
        incurlist &&
        curval &&

        accsval === accid &&
        sttval === stat &&
        clssval === clss &&
        accids.indexOf(accid) >= 0 &&
        new Date('01 '+fdate) <= new Date('01 '+lastDate)
      ){
        newtrans.push(a);

        // Currency selection
        // let amount = (actcur)? a.Amount__c : a.USD_Amount;

        // Currency deselection
        let amount = (tempcur)? a.Amount__c : a.USD_Amount;

        amount = (amount)? amount : 0;

        if(
          a.Investment_Type__c === 'Subscription' ||
          (
            a.Investment_Type__c &&
            a.Investment_Type__c.toLowerCase() === 'assignment in'
          )
        )
          invested += amount;

        if(
          a.Investment_Type__c === 'Redemption' ||
          a.Investment_Type__c === 'Assignment Out'
        )
          returned += amount;

        if(a.Investment_Type__c === 'Dividend Distribution')
          dividends += amount;
      }
    });

    // Currency selection
    // const cur = (actcur)? actcur : currencies.usd;

    // Currency deselection
    const cur = (tempcur)? tempcur : currencies.usd;

    let ac = invested - returned;
    ac = cur+ac.toLocaleString('en-US', {maximumFractionDigits: 0});
    const rtrnd = cur+returned.toLocaleString('en-US', {maximumFractionDigits: 0});
    const dvnds = cur+dividends.toLocaleString('en-US', {maximumFractionDigits: 0});

    const details = account.querySelectorAll('.details .cards');
    if(details.length){
      details[1].querySelector('h4 center').innerText = ac;
      details[2].querySelector('h4 center').innerText = rtrnd;
      details[3].querySelector('h4 center').innerText = dvnds;
    }

    sortby(sortfield.split(' ')[0], 1);
  }, 300);
};

const asExcel = () => {
  const newdata = newtrans.map(a => {
    const columns = {};
    for(const key in newfields){
      let val = a[key];
      val = (val)? val : '';
      if(key === 'Amount__c'){
        const cur = currencies[a.CurrencyIsoCode.toLowerCase()];
        val = cur+val.toLocaleString('en-US', {maximumFractionDigits: 0});
      }

      columns[newfields[key][0]] = val;
    }
    return columns;
  });

  newdata.sort((a, b) => {
    let da = new Date(a['DATE']),
        db = new Date(b['DATE']);
    return db - da;
  });

  Excel(newdata, 'transactions');
};

function Investment(){
  if(loaded && !sorting) filter(); else sorting = 0;

  const Contexts = useContext(Context);
  level = Contexts.level;
  trans = JSON.parse(Contexts.trans);

  // Replaced with USD or ILS not 0 then it is active
  // stats = JSON.parse(Contexts.stats);
  stats = Contexts.stats;

  const [transactions, setTransactions] = useState(trans);
  settrans = () => setTransactions(newtrans);

  currencies = Contexts.currencies;

  // Currency selection
  // actcur = Contexts.actcur;

  // Currency deselection
  inactcurs = Contexts.inactcurs;
  tempcur = Contexts.tempcur;

  const actacct = Contexts.actacct;
  accountids = actacct.AccountIds;
  statval = Contexts.statval;
  const acctnames = Contexts.acctnames;
  lastDate = Contexts.lastDate;

  newtrans = transactions.slice();

  return (
    <Container fluid className="investment">
      <div className="details">
        <div className="infonote">Information presented as of {lastDate}</div>
        <h5>Transaction List:</h5>
        <Button color="primary" size="sm" className="accountexport" onClick={asExcel}>
          <ExcelIcon /> Export to Excel
        </Button>
        <div className="table">
          <table>
            <thead>
              <tr>
                {/* <TempTh key="id" id="id">#</TempTh> */}
                {(() => {
                  const columns = [];
                  for(const key in newfields){
                    columns.push(<TempTh key={key} id={key}>{newfields[key][0]}</TempTh>);
                  }
                  return columns;
                })()}
              </tr>
            </thead>
            <tbody>
              {
                transactions.map((a, b) => {
                  return (
                    <tr key={b}>
                      {/* <td key="id"><div>{b + 1}</div></td> */}
                      {(() => {
                        const columns = [];
                        for(const key in newfields){
                          let val = a[key];
                          val = (val)? val : '';
                          if(key === 'Tzur_Name__c') val = acctnames[a.Account__c];
                          else if(key === 'Amount__c'){
                            if(val){
                              val = val.toLocaleString('en-US', {maximumFractionDigits: 0});
                              val = currencies[a.CurrencyIsoCode.toLowerCase()]+val;
                            } else val = '';
                          }

                          columns.push(<td key={key}><div>{val}</div></td>);
                        }
                        return columns;
                      })()}
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </Container>
  );
}

export default Investment;
export { initinvestment };
