import { ReactComponent as DownloadIcon } from '../../libs/theme/download.svg';
import XLSX from 'xlsx-js-style';
import './index.css';

let trans = [];
let navs = [];

const download = (data) => {
  // console.log(data);

  const acctTrans = trans.filter(
    a => a.Account__c === data.id
  );

  acctTrans.sort((a, b) => {
    let da = new Date(a.Date__c),
        db = new Date(b.Date__c);
    return da - db;
  });

  // Create a new workbook and a new worksheet
  const wb = XLSX.utils.book_new();

  // Create the content
  const ws = XLSX.utils.aoa_to_sheet([
    ['Phoenix RE Debt - Investor Report'],
    [],
    [
      'Initial Investment Amount',
      'Initial Investment Date',
      'Last Reported Date',
      'Investor Name',
      'Class',
      'Total Distributions',
      'Total Return %'
    ],
    [
      acctTrans[0].USD_Amount,
      { v: new Date(acctTrans[0].Date__c), t: 'd' },
      { v: new Date(data.lastReportedDate), t: 'd' },
      data.Name,
      data.Class,
      // { v: '', f: 'A4' }, // Reference for setting f (formula)
      { v: '' },
      { v: '' }
    ],
    [],
    [],
    [
      'Distributions Report'
    ]
    /* [
      'Investment Type',
      'Date',
      'Amount'
    ] */
  ]);

  // Define the merge ranges
  ws['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }, // Merge cells from A1 to C1
    { s: { r: 6, c: 0 }, e: { r: 6, c: 2 } }
  ];

  // Set column widths
  ws['!cols'] = [
    { wch: 20 }, // Set width for the first column
    { wch: 20 },
    { wch: 18 },
    { wch: 28 },
    { wch: 10 },
    { wch: 15 },
    { wch: 18 }
  ];

  // Encase of additional columns in dynamic data use below example to set the width of the new column
  // ws['!cols'].push({ wch: 20 });

  // Set row heights
  ws['!rows'] = [
    { hpx: 30 }, // Set height for the first row
    {},
    {},
    { hpx: 20 }
  ];

  // Encase of height adjustment in dynamic data use below example to set the height
  // ws['!rows'].push({ hpx: 30 });

  // Apply styles to the cells
  const font = {
    font: {
      name: 'Arial',
      sz: 11
    }
  };

  const fill = {
    fill: {
      fgColor: { rgb: 'dceaf7' }
    }
  };

  const center = {
    alignment: {
      horizontal: 'center'
    }
  };

  const border = {
    border: {
      top: { style: 'thin', color: { rgb: '000' } },
      bottom: { style: 'thin', color: { rgb: '000' } },
      left: { style: 'thin', color: { rgb: '000' } },
      right: { style: 'thin', color: { rgb: '000' } }
    }
  };

  const dollar = {
    numFmt: '$#,##0.00'
  };

  const usd = {
    numFmt: '"USD "#,##0.00'
  };

  const date = {
    numFmt: 'mm/dd/yyyy'
  };

  const percent = {
    numFmt: '0.0000"%"'
  };

  // Combined styles
  const header = JSON.parse(JSON.stringify({ ...font, ...fill, ...center }));
  header.font.sz = 14;
  header.font.bold = true;

  const vCenter = JSON.parse(JSON.stringify(center));
  vCenter.alignment.vertical = 'center';
  vCenter.alignment.wrapText = true;

  const header1 = JSON.parse(JSON.stringify({ ...font, ...vCenter, ...border }));
  header1.font.bold = true;

  const header2 = JSON.parse(JSON.stringify({ ...font, ...fill }));
  header2.font.bold = true;
  header2.font.color = { rgb: '56585b' };
  header2.fill.fgColor.rgb = 'e9e8e5';

  const header3 = JSON.parse(JSON.stringify({ ...header2, ...vCenter }));

  const footer = JSON.parse(JSON.stringify({ ...font, ...fill }));
  footer.font.bold = true;

  const values = JSON.parse(JSON.stringify({ ...font, ...vCenter }));

  // Apply style to specific cells
  ws['A1'].s = header;

  ws['A3'].s = { ...header1 };
  ws['B3'].s = { ...header1 };
  ws['C3'].s = { ...header1 };
  ws['D3'].s = { ...header1 };
  ws['E3'].s = { ...header1 };
  ws['F3'].s = { ...header1, ...fill };
  ws['G3'].s = { ...header1, ...fill };

  ws['A4'].s = { ...values, ...dollar, ...border };
  ws['B4'].s = { ...values, ...date, ...border };
  ws['C4'].s = { ...values, ...date, ...border };
  ws['D4'].s = { ...values, ...border };
  ws['E4'].s = { ...values, ...border };
  ws['F4'].s = { ...header1, ...dollar, ...fill };
  ws['G4'].s = { ...header1, numFmt: '0.00"%"', ...fill };

  ws['A7'].s = { ...values, ...fill };

  // Get the range of the worksheet
  // const range = XLSX.utils.decode_range(ws['!ref']);
  // console.log(range)

  // Manual version of Distributions Report header
  /* ws['A8'].s = header2;
  ws['B8'].s = header2;
  ws['C8'].s = header2; */

  // Data driven version of Distributions Report header
  const distroHeader = ['Investment Type', 'Date', 'Amount'];

  const distros = acctTrans.filter(
    a => a.Investment_Type__c === 'Dividend Distribution'
  ).map(a => {
    return {
      [distroHeader[0]]: a.Investment_Type__c,
      [distroHeader[1]]: new Date(a.Date__c),
      [distroHeader[2]]: a.USD_Amount
    }
  });

  // Provided 7 instead of 8 in r because 8 will result to A9 but I need A8
  distroHeader.forEach((a, b) => {
    const cell = XLSX.utils.encode_cell({ r: 7, c: b });
    ws[cell] = { v: a, s: header2 };
  });

  // Distributions Report body
  distros.forEach((a, b) => {
    distroHeader.forEach((c, d) => {
      const cell = XLSX.utils.encode_cell({ r: 8 + b, c: d });
      ws[cell] = { v: a[c], s: font };
      if(c === 'Date'){
        ws[cell].v = a[c].toLocaleDateString('de-de');
      } else if(c === 'Amount'){
        ws[cell].t = 'n';
        ws[cell].s = { ...ws[cell].s, ...usd };
      }
    });
  });

  // Distributions Report footer
  const distrosLength = distros.length;
  let row = 8 + distrosLength;
  distroHeader.forEach((a, b) => {
    const cell = XLSX.utils.encode_cell({ r: row, c: b });
    ws[cell] = { v: '', s: footer };
    if(b === 0) ws[cell] = { v: 'Total', s: footer };
    else if(a === 'Amount'){
      if(distrosLength){
        const start = XLSX.utils.encode_cell({ r: 8, c: b });
        const end = XLSX.utils.encode_cell({ r: row - 1, c: b });
        const formula = `SUM(${start}:${end})`;
        ws[cell] = { f: formula };
      } else ws[cell] = { v: 0, t: 'n' };

      ws[cell].s = { ...footer, ...dollar};

      // Set formula/value for F4
      ws['F4'].f = XLSX.utils.encode_cell({ r: row, c: b });
    }
  });

  const acctNavs = navs.filter(
    a => a.Account__c === data.id
  );

  acctNavs.sort((a, b) => {
    let da = new Date(a.Date__c),
        db = new Date(b.Date__c);
    return db - da;
  });

  const investments = acctNavs.map(a => {
    return {
      Date: new Date(a.Date__c),
      'Investment Value': a.USD_Amount__c,
      '# of Units': a.Unit__c,
      'Unit Value': a.PerUnit__c,
      'Monthly Return': a.Net_MTD__c,
      'Return since Inception': a.Net_ITD__c
    }
  });

  row += 3;
  const investmentsHeader = Object.keys(investments[0]);
  investmentsHeader.forEach((a, b) => {
    const cell = XLSX.utils.encode_cell({ r: row, c: b });
    ws[cell] = { v: a, s: header3 };
  });

  row += 1;
  let g4 = '';
  investments.forEach((a, b) => {
    investmentsHeader.forEach((c, d) => {
      const cell = XLSX.utils.encode_cell({ r: row + b, c: d });
      ws[cell] = { v: a[c], s: values };
      if(c === 'Date'){
        ws[cell].v = a[c].toLocaleDateString('de-de');
      } else if(c === 'Investment Value'){
        ws[cell].t = 'n';
        ws[cell].s = { ...ws[cell].s, ...usd };
      } else if(
        c === 'Monthly Return' ||
        c === 'Return since Inception'
      ){
        ws[cell].t = 'n';
        ws[cell].s = { ...ws[cell].s, ...percent };

        if(b === 0 && c === 'Return since Inception')
          g4 = cell;
      } else ws[cell].t = 'n';
    });
  });

  const investmentsLength = investments.length;
  row += investmentsLength;

  // Set formula/value for G4
  ws['G4'].f = g4;

  // Update the worksheet range, this is to include the dynamic data
  ws['!ref'] = XLSX.utils.encode_range({ r: 0, c: 0}, { r: row, c: ws['!cols'].length - 1});

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Generate the Excel file and trigger download
  XLSX.writeFile(wb, 'report.xlsx');
};

const getTrans = (data) => trans = data;

const getNavs = (data) => navs = data;

function ExcelReport({data}){
  return (
    <div className="report" onClick={() => download(data)}>
      <DownloadIcon /> Download
    </div>
  );
}

export default ExcelReport;
export { getTrans, getNavs };