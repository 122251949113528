import { useState } from 'react';
import Context, { useContext } from '../../context';
/* Commented out for the theme */
// import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { BsQuestionLg as AiOutlineQuestionCircle } from 'react-icons/bs';
import { Container, Row, Col, Button } from 'reactstrap';
import Excel, { ExcelIcon } from '../Excel';
import './index.css';

import Investor, { initinvestor } from '../Investor';
import Investment, { initinvestment } from '../Investment';
import Graph, { graphopts } from '../Graph';
import Filters2 from '../Filters2';
import Funds from '../Funds';
import Dates from '../Dates';
import Dropmultisearch from '../Dropmultisearch';
// import ViewMode from '../ViewMode';
import { NewToken } from '../Token';
import { convert } from '../../utils/date';

import {
  loaderShow, loaderHide,
  loaderErrMsg
} from '../Loader';

let loaded = 0;
let navs = [];
let newnavs = [];
let trans = [];
let setoptions = () => void 0;
let stropts = JSON.stringify(graphopts);
let count = 0;
let dataPoints = [];
let lineDataPoints = [];

const currencies = {
  usd: '$',
  ils: '₪',
  eur: '€'
};

// Currency selection
/* let actcur = '';
let origactcur = '';
let tempactcur = ''; */

// Currency deselection
let inactcurs = [];
let usetempcur = 0;
let tempcur = '';

let account = {};

// let redebt = {};
let accounts = {};
let status = {};
let classes = {};

let lastnav = 0;

let ttmDate = 0;
let ttmStart = -1;
let ttm = 0;

let lastmtd = 0;
let itd = 0;

let classesOpts = {};
let clear = {};

let lastDate = '';
let actFilBtns = 0;

let dates = {};
let relate = [];
let stats = {};

let conts = [];
let statval = true;
let accountids = [];

let actacct = {};
// let tempactacct = {};

let cur = '';
let acctsData = {};

let dropsearch = null;
let nonavs = true;

let summary = {};
let sumnavs = [];
let lastsum = '';
let sumaccts = [];
let all = 1;

let currentClasses = {};
let currentFunds = {};

const showInfo = (evnt, msg) => {
  const p = account.querySelector('.details h6 p');
  if(!p && evnt.type === 'click'){
    const target = evnt.currentTarget;

    const pop = document.createElement('p');
    const left = (target.getBoundingClientRect().left - 118);
    pop.style.left = left+'px';
    pop.innerHTML = msg;

    target.parentElement.append(pop);
  } else {
    if(p) p.remove();
  }
};

const curcodes = {
  36: 'USD',
  164: 'ILS'
};

const initaccount = () => {
  if(!loaded){
    if(!nonavs) newnavs = navs.slice();
    else {
      let latest = 0;
      for(const key in summary){
        const classes = summary[key];
        const date = convert(key);
        for(const key1 in classes){
          const amount = classes[key1].Amount;
          const usd = classes[key1]['NAV USD'];

          const code = classes[key1].CurrencyIsoCode;
          let currency = curcodes[code];
          currency = (currency)? currency : '';

          const mtd = classes[key1].MTD;
          sumnavs.push({
            USD_Amount__c: usd,
            Series_Name__c: '',
            Net_MTD__c: mtd,
            Date__c: date,
            CurrencyIsoCode: currency,
            Class__c: key1,
            Amount__c: amount,
            Account__c: key1
          });
        }

        // Get latest date
        const dat = new Date(date);
        if(dat > latest){
          latest = dat;
          lastsum = key;
        }
      }

      for(const key in summary[lastsum]){
        const data = summary[lastsum][key];
        const code = data.CurrencyIsoCode;
        let currency = curcodes[code];
        currency = (currency)? currency : '';
        sumaccts.push({
          CurrencyIsoCode: currency,
          Name: key,
          id: key
        });
      }

      newnavs = sumnavs.slice();
    }

    if(newnavs.length) prepdata();

    account = document.querySelector('.account');

    const curbtns = account.querySelectorAll('.currencies button');
    curbtns.forEach((a) => a.addEventListener('click', () => filByCur(a.innerText, a)));

    const filters2 = account.querySelectorAll('.filter select');
    /* redebt = filters2[0]; */ accounts = filters2[1];
    status = filters2[2]; classes = filters2[3];
    accounts.addEventListener('change', filByAcc);
    status.addEventListener('change', filByStat);
    classes.addEventListener('change', filter);

    clear = account.querySelector('.clear');
    clear.addEventListener('click', clearfil);

    const cleartext = account.querySelector('.cleartext2');
    cleartext.addEventListener('click', clearfil);

    // This worked when dispatch was added in Dropsearch component
    dropsearch = account.querySelector('.dropsearch select');
    dropsearch.addEventListener('change', () => filtration(dropsearch.value));

    const filbuttons = account.querySelectorAll('.filterbuttons button');
    filbuttons.forEach((a) => {
      a.classList.add('active');
      a.addEventListener('click', filBtns);
    });

    dates = account.querySelectorAll('.dates [type="date"]');
    dates.forEach((a) => a.addEventListener('change', graph));

    const jsonopts = JSON.parse(stropts);
    // jsonopts.width -= 60;
    stropts = JSON.stringify(jsonopts);

    const investors = account.querySelectorAll('.dropsearch .res button');

    // This is used before when users have navs
    /* if(!nonavs && investors.length === 1){
      const selopt = dropsearch.options[0];
      const id = investors[0].id;

      const td = investors[0].querySelector('td');
      selopt.innerText = (td)? td.innerText : investors[0].innerText;
      selopt.value = id;

      filtration(id);
    } else filByCur(); */

    if(investors.length === 1){
      const selopt = dropsearch.options[0];
      const id = investors[0].id;

      const td = investors[0].querySelector('td');
      selopt.innerText = (td)? td.innerText : investors[0].innerText;
      selopt.value = id;

      setTimeout(() => {
        dropsearch.dispatchEvent(new Event('change'));
      }, 1);
    } else filByCur();

    initinvestor();
    initinvestment();

    const svgs = account.querySelectorAll('.details h6 svg');
    svgs.forEach(a => a.addEventListener('focusout', (evnt) => showInfo(evnt, '')));

    loaded = 1;
    /* console.log('conts', conts);
    console.log('navs', navs);
    console.log('relate', relate);
    console.log('summary', summary); */
  }
};

const prepdata = () => {
  dataPoints = [];
  acctsData = {};

  // Sort navs
  newnavs.sort((a, b) => {
    let da = new Date(a.Date__c),
        db = new Date(b.Date__c);
    return da - db;
  });

  newnavs.forEach(a => {
    // Get USD and ILS to use for status identification
      const fdate = new Date(a.Date__c).toLocaleDateString('en-us', { year: 'numeric', month: 'short'});
      const index = dataPoints.findIndex(x => x.label === fdate);

      // USD
      let amount = a.USD_Amount__c;
      amount = (amount)? amount : 0;

      // ILS
      let amountIls = a.Amount__c;
      amountIls = (amountIls)? amountIls : 0;

      const accid = a.Account__c;

      if(!acctsData[accid]){
        acctsData[accid] = {
          nav: [],
          navIls: []
        };
      }

      if(index >= 0){
        // USD
        if(!acctsData[accid].nav[index])
          acctsData[accid].nav[index] = 0;

        acctsData[accid].nav[index] += amount;

        // ILS
        if(a.CurrencyIsoCode === 'ILS'){
          if(!acctsData[accid].navIls[index])
            acctsData[accid].navIls[index] = 0;

          acctsData[accid].navIls[index] += amountIls;
        }
      } else {
        dataPoints.push({label: fdate});

        // USD
        acctsData[accid].nav.push(amount);

        // ILS
        if(a.CurrencyIsoCode === 'ILS')
          acctsData[accid].navIls.push(amountIls);
      }
    // /Get USD and ILS to use for status identification
  });

  // Identify status per account
    for(const key in acctsData){
      let nav = acctsData[key].nav.slice(-1)[0];
      nav = (nav)? nav : 0;

      let navIls = acctsData[key].navIls.slice(-1)[0];
      navIls = (navIls)? navIls : 0;

      // Set status per account based on navs
      stats[key] = (nav || navIls) > 0;
    }
  // /Identify status per account

  // Get max date: newnavs.slice(-1)[0].Date__c
  ttmDate = new Date(newnavs.slice(-1)[0].Date__c);
  ttmDate.setFullYear(ttmDate.getFullYear() - 1);
};

const sort = (data, field) => {
  data.sort((a, b) => {
    let fa = a[field],
        fb = b[field];

    fa = fa? fa.toLowerCase() : '';
    fb = fb? fb.toLowerCase() : '';

    if (fa < fb) return -1;
    if (fa > fb) return 1;
    return 0;
  });
};

const filByCur = (text = '', button = null) => {
  // Currency selection
  /* const active = account.querySelector('.currencies button.active');
  if(active) active.classList.remove('active');

  if(button && button !== active){
    button.classList.add('active');
    origactcur = text;
  } else origactcur = ''; */

  // Currency deselection
  if(button){
    if(button.classList.contains('active')){
      button.classList.remove('active');
      inactcurs.push(text);
    } else {
      button.classList.add('active');
      inactcurs.splice(inactcurs.indexOf(text), 1);
    }
  }

  resfil(accounts);
  filByAcc();
};

const filByAcc = () => {
  resfil(classes);
  filter();
  buildfil(classesOpts, classes);
};

const filByStat = () => {
  const val = status.value;
  if(val === '-') statval = true;
  else if(val === 'Inactive Only')
    statval = false;
  else statval = '';

  resfil(accounts);
  filByAcc();
};

const clearfil = () => {
  // Currency deselection
  inactcurs = [];

  accounts.selectedIndex = 0;
  status.selectedIndex = 0;
  classes.selectedIndex = 0;
  statval = true;
  filByCur();
};

const filtration = async (id) => {
  // This try catch is for getDetails()
  try {
    loaderShow();

    // Currency deselection
    inactcurs = [];

    accountids = [];
    if(id !== '-'){
      all = 0;
      actacct = conts.find(a => a.id === id);

      relate.forEach(a => {
        if(a.ContactId === id)
          accountids.push(a.AccountId);
      });

      if(nonavs) await getDetails(id);
    } else {
      all = 1;
      actacct = {};

      if(nonavs){
        newnavs = sumnavs.slice();
        trans = [];
      }
    }

    if(newnavs.length) prepdata();
    filByCur();

    loaderHide();
  } catch(err){
    loaderErrMsg();
  }
};

const getDetails = async (id) => {
  const newtoken = await NewToken();

  const response = await fetch(`${process.env.REACT_APP_API}/auth/details/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': newtoken
    }
  });
  const res = await response.json();
  const data = res.data[0];
  newnavs = data.Navs;
  trans = data.Transactions;

  /* console.log('newnavs', newnavs);
  console.log('newtrans', trans); */
};

const refilter = (currency) => {
  // Set temp active currency
  // Currency selection
  // tempactcur = currency;

  // Currency deselection
  usetempcur = 1;
  tempcur = currency;

  filter();

  // Set temp active investor
  /* const acctid = accounts.value;
  if(acctid && dropsearch.value === '-'){
    const cont = relate.find(a => a.AccountId === acctid);
    const id = cont.ContactId;
    tempactacct = conts.find(a => a.id === id);
  } */
};

const resfil = (elem) => {
  const option = elem.querySelector('option');
  elem.innerHTML = '';
  elem.append(option);
};

const buildfil = (options, elem) => {
  const arr = [];
  for(const key in options){
    arr.push({key, val: options[key]});
  }

  sort(arr, 'val');

  arr.forEach(a => {
    elem.innerHTML += `<option value="${a.key}">${a.val}</option>`;
  });
};

const build = (a, date) => {
  const fdate = date.toLocaleDateString('en-us', { year: 'numeric', month: 'short'});
  const index = dataPoints.findIndex(x => x.label === fdate);

  // Currency selection
  // let amount = (actcur)? a.Amount__c : a.USD_Amount__c;

  // Currency deselection
  let amount = (tempcur)? a.Amount__c : a.USD_Amount__c;

  amount = (amount)? amount : 0;

  const mtdp = a.Net_MTD__c / 100;
  const mtd = amount * mtdp;

  const accid = a.Account__c;
  const clss = a.Class__c;
  const srsn = a.Series_Name__c;

  if(!acctsData[accid]){
    acctsData[accid] = {
      nav: [],
      navIls: [],
      mtd: [],
      mtdIls: [],
      ttmStart: -1
    };
  }

  acctsData[accid].class = clss;
  acctsData[accid].series = srsn;
  // For excel report
  acctsData[accid].lastReportedDate = a.Date__c;

  if(index >= 0){
    dataPoints[index].y += amount;
    lineDataPoints[index].y += mtd;

    if(!acctsData[accid].nav[index]){
      acctsData[accid].nav[index] = 0;
      acctsData[accid].mtd[index] = 0;
    }

    acctsData[accid].nav[index] += amount;
    acctsData[accid].mtd[index] += mtd;
  } else {
    dataPoints.push({label: fdate, y: amount, x: count++});
    lineDataPoints.push({label: fdate, y: mtd, x: new Date().getTime()});

    acctsData[accid].nav.push(amount);
    acctsData[accid].mtd.push(mtd);
  }

  // Get the ILS amount
  // Currency selection
  // if(!actcur && a.CurrencyIsoCode === 'ILS'){

  // Currency deselection
  if(!tempcur && a.CurrencyIsoCode === 'ILS'){
    const amountIls = a.Amount__c;
    const mtdIls = amountIls * mtdp;

    if(index >= 0){
      if(!acctsData[accid].navIls[index]){
        acctsData[accid].navIls[index] = 0;
        acctsData[accid].mtdIls[index] = 0;
      }

      acctsData[accid].navIls[index] += amountIls;
      acctsData[accid].mtdIls[index] += mtdIls;
    } else {
      acctsData[accid].navIls.push(amountIls);
      acctsData[accid].mtdIls.push(mtdIls);
    }
  }

  // The ttmStart is count - 1 because count started with 1
  if(ttmStart < 0 && date > ttmDate) ttmStart = count - 1;

  if(acctsData[accid].ttmStart < 0 && date > ttmDate)
    acctsData[accid].ttmStart = count - 1;
};

const graph = () => {
  const from = dates[0].value;
  const to = dates[1].value;

  let fval = (from)? new Date(from) : new Date(0);
  fval = new Date(fval.toDateString());

  let tval = (to)? new Date(to) : new Date();
  tval = new Date(tval.toDateString());

  count = 0;
  const filData = [];
  const filLine = [];
  dataPoints.forEach((a, b) => {
    const date = new Date('01 '+a.label);
    if(fval <= date && tval >= date){
      const len = filData.push(a);
      const index = len - 1;
      filData[index].x = count++;

      filLine.push(lineDataPoints[b]);
      filLine[index].x = new Date().getTime();
    }
  });

  const jsonopts = JSON.parse(stropts);
  if(actFilBtns === 1){
    jsonopts.data[0].dataPoints = filData;
    jsonopts.data[0].lineDataPoints = [];
  } else if(actFilBtns === 2){
    jsonopts.data[0].dataPoints = [];
    jsonopts.data[0].lineDataPoints = filLine;
  } else {
    jsonopts.data[0].dataPoints = filData;
    jsonopts.data[0].lineDataPoints = filLine;
  }

  setoptions(jsonopts);
};

const filBtns = (evnt) => {
  const self = evnt.currentTarget;
  const parent = self.parentElement;
  const button = parent.querySelectorAll('button');

  let inactive = button[0];
  if(inactive.classList.contains('active'))
    inactive = button[1];

  if(self.classList.contains('active')){
    self.classList.remove('active');
    if(inactive !== self) inactive.classList.add('active');

    if(self.innerText !== 'Dividends')
      actFilBtns = 2;
    else actFilBtns = 1;
  } else {
    self.classList.add('active');
    actFilBtns = 0;
  }
  graph();
};

const getlast = (data) => {
  const lastdata = data.slice(-1);
  return (lastdata.length)? lastdata[0].y : 0;
};

const getlastDate = () => {
  const lastdata = lineDataPoints.slice(-1);
  return (lastdata.length)? lastdata[0].label : '';
};

const filter = () => {
  count = 0;
  dataPoints = [];
  lineDataPoints = [];
  ttmStart = -1;
  ttm = 0;
  classesOpts = {};
  itd = 0;
  acctsData = {};
  currentClasses = {};
  currentFunds = {};

  let accountsval = accounts.value;
  const classesval = classes.value;

  // Revert active currency
  // Currency selection
  // actcur = origactcur;

  if(
    // Currency selection
    // actcur ||

    // Currency deselection
    inactcurs.length ||
    accountsval !== '-' ||
    statval !== true ||
    classesval !== '-'
  ) clear.style = 'color: #fc6400';
  else clear.style = '';

  // Placed it here so clear filter will not be affected
  // The original placement of the script is after actcur = origactcur;
  // The clear filter will be highlighted using temp active currency which is wrong
  // Currency selection
  /* if(tempactcur){
    // Use temp active currency
    actcur = tempactcur;
    tempactcur = '';
  } */

  // Currency selection
  if(usetempcur) usetempcur = 0;
  else tempcur = '';

  newnavs.forEach(a => {
    const date = new Date(a.Date__c);
    const curcode = currencies[a.CurrencyIsoCode.toLowerCase()];
    const accid = a.Account__c;

    // Replaced with USD or ILS not 0 then it is active
    // const stat = stats[accid];
    const stat = (stats[accid])? stats[accid] : false;

    const clss = a.Class__c;

    // Currency selection
    // const curval = (actcur)? actcur : curcode;

    // Currency deselection
    const incurlist = Object.values(currencies).indexOf(curcode) >= 0;
    let curval = true;
    if(tempcur) curval = tempcur === curcode;
    else if(inactcurs.length) curval = inactcurs.indexOf(curcode) < 0;

    const accsval = (accountsval !== '-')? accountsval : accid;
    const sttval = (statval !== '')? statval : stat;
    const clssval = (classesval !== '-')? classesval : clss;
    const accids = (accountids.length)? accountids : accid;

    if(
      // Currency selection
      // curval === curcode &&

      // Currency deselection
      // The incurlist is to filter the data based on listed currency
      // In case of new currency it will not be included
      incurlist &&
      curval &&

      // redebt.innerText === a.Investor_Fund__c &&
      accsval === accid &&
      sttval === stat &&
      clssval === clss &&
      accids.indexOf(accid) >= 0
    )
      build(a, date);

    if(
      // Currency selection
      // curval === curcode &&

      // Currency deselection
      // The incurlist is to filter the data based on listed currency
      // In case of new currency it will not be included
      incurlist &&
      curval &&

      sttval === stat &&
      accids.indexOf(accid) >= 0
    ){
      if(
        clss &&
        accsval === accid
      )
        classesOpts[clss] = clss;
    }

    docsData(clss, a.Investor_Fund__c);
  });

  dataPoints.forEach((a, b) => {
    lineDataPoints[b].y = (a.y)? (lineDataPoints[b].y / a.y) * 100 : 0;
    itd += lineDataPoints[b].y;

    if(ttmStart >= 0 && b >= ttmStart) ttm += lineDataPoints[b].y;
  });

  // Currency selection
  // cur = (actcur)? actcur : currencies.usd;

  // Currency deselection
  cur = (tempcur)? tempcur : currencies.usd;

  lastnav = getlast(dataPoints);
  lastnav = cur+lastnav.toLocaleString('en-US', {maximumFractionDigits: 0});

  ttm = (ttm)? ttm.toFixed(2)+'%' : '0%';

  lastmtd = getlast(lineDataPoints);
  lastmtd = (lastmtd)? lastmtd.toFixed(2)+'%' : '0%';

  itd = (itd)? itd.toFixed(2)+'%' : '0%';

  lastDate = getlastDate();

  const jsonopts = JSON.parse(stropts);
  jsonopts.axisY.prefix = cur;
  const yval = jsonopts.data[0].yValueFormatString;
  jsonopts.data[0].yValueFormatString = cur+(yval.substr(1));
  jsonopts.data[0].dataPoints = dataPoints;
  jsonopts.data[0].lineDataPoints = lineDataPoints;
  setoptions(jsonopts);
  stropts = JSON.stringify(jsonopts);
  graph();
};

const asExcel = () => {
  const newdata = dataPoints.map((a, b) => {
    let nav = a.y;
    if(nav)
      nav = cur+nav.toLocaleString('en-US', {maximumFractionDigits: 2});
    else nav = cur+nav;

    let div = lineDataPoints[b].y;
    div = (div)? div.toFixed(2)+'%' : div+'%';

    return {'MONTH YEAR': a.label, 'NAV': nav, 'DIVIDENDS': div};
  });

  Excel(newdata, 'nav');
};

const Temp = ({label, value, msg}) => {
  const key = (label.indexOf('NAV') >= 0)? 1 : new Date().getTime();
  return (
    <Col md={3}>
      <div className="cards">
        <h6>
          <center>
            {label} <AiOutlineQuestionCircle size="18" color="#2e6ca4"
            onClick={(evnt) => showInfo(evnt, msg)} />
          </center>
        </h6>
        <h4><center key={key}>{value}</center></h4>
      </div>
    </Col>
  );
};

const TempButton = ({label}) => <Button outline size="sm">{label}</Button>;

const prepAcctsData = () => {
  const newAcctsData = {};
  for(const key in acctsData){
    newAcctsData[key] = {};

    const newacct = newAcctsData[key];
    const acct = JSON.parse(JSON.stringify(acctsData[key]));
    for(const key1 in acct){
      if(key1.indexOf('nav') >= 0){
        const val = acct[key1].slice(-1)[0];
        newacct[key1] = (val)? val : 0;
      } else if(key1 === 'mtd'){
        newacct.itd = 0;
        newacct.ttm = 0;

        acct.nav.forEach((a, b) => {
          acct[key1][b] = (a)? (acct[key1][b] / a) * 100 : 0;
          newacct.itd += acct[key1][b];

          if(acct.ttmStart >= 0 && b >= acct.ttmStart)
            newacct.ttm += acct[key1][b];
        });

        const val = acct[key1].slice(-1)[0];
        newacct[key1] = (val)? val : 0;
      } else if(key1 === 'mtdIls'){
        newacct.itdIls = 0;
        newacct.ttmIls = 0;

        acct.navIls.forEach((a, b) => {
          acct[key1][b] = (a)? (acct[key1][b] / a) * 100 : 0;
          newacct.itdIls += acct[key1][b];

          if(acct.ttmStart >= 0 && b >= acct.ttmStart)
            newacct.ttmIls += acct[key1][b];
        });

        const val = acct[key1].slice(-1)[0];
        newacct[key1] = (val)? val : 0;
      } else {
        if(key1 !== 'ttmStart')
          newacct[key1] = acct[key1];
      }
    }
  }
  return newAcctsData;
};

const docsData = (clss, fund) => {
  // Used in documents page's file restriction
  if(clss) currentClasses[clss] = clss;
  if(fund) currentFunds[fund] = fund;
};

function Account(){
  const Contexts = useContext(Context);
  navs = JSON.parse(Contexts.navs);
  conts = JSON.parse(Contexts.conts);
  relate = JSON.parse(Contexts.relate);
  summary = JSON.parse(Contexts.summary);

  // Replaced with USD or ILS not 0 then it is active
  // stats = JSON.parse(Contexts.stats);
  Contexts.stats = stats;

  const [options, setOptions] = useState(JSON.parse(stropts));
  setoptions = (opts) => setOptions(opts);

  Contexts.currencies = currencies;

  // Currency selection
  // Contexts.actcur = actcur;

  // Currency deselection
  Contexts.inactcurs = inactcurs;
  Contexts.tempcur = tempcur;

  // Revert active investor
  let newactacct = actacct;
  /* if(tempactacct.id){
    // Use temp active investor
    newactacct = tempactacct;
    tempactacct = {};
  } */

  newactacct.AccountIds = accountids;
  Contexts.actacct = newactacct;

  Contexts.statval = statval;
  Contexts.refilter = refilter;

  Contexts.acctsData = prepAcctsData();

  if(navs.length) nonavs = false;
  if(nonavs){
    Contexts.trans = JSON.stringify(trans);
    // For excel report
    Contexts.newnavs = JSON.stringify(newnavs);
  }

  Contexts.lastDate = lastDate;
  Contexts.sumaccts = (nonavs && all)? sumaccts : 0;

  // Used in documents page's file restriction
  const currentClassesVals = Object.values(currentClasses);

  // Get all classes for multiselect options
  if(!Contexts.allClasses && currentClassesVals.length)
    Contexts.allClasses = currentClassesVals.sort();

  Contexts.currentClasses = currentClassesVals;
  Contexts.currentFunds = Object.values(currentFunds);
  // /Used in documents page's file restriction

  return (
    <>
      <Investor />
      <Container fluid className="account">
        <Row className="myfilters">
          <div className="currencies">
            {(() => {
              const curs = [];
              for(const key in currencies){
                curs.push(<TempButton key={key} label={currencies[key]} />);
              }
              return curs;
            })()}
          </div>
          <Funds />
          <Filters2 />
          {/* <ViewMode /> */}
          <Dropmultisearch data={conts} value="id" options={['Name', 'Nick_Name__c', 'Email', 'MobilePhone']} name="investors" label="All Investors" />
        </Row>
        <div className="infos">
          <h4>Financial Overview</h4>
          <div className="infonote">Information presented as of {lastDate}</div>
          <Row className="details">
            <Temp label="Net Asset Value (NAV)" value={lastnav} msg="Net Asset Value is the net value of an investor’s assets less its liabilities, calculated at the end of each month." />
            {/* <Temp label="Active Capital" value={cur+0} msg="Active Capital is the principal or original capital invested, before income or returns are generated." /> */}
            <Temp label="Invested Capital" value={cur+0} msg="Invested Capital is the principal or original capital invested, before income or returns are generated." />
            <Temp label="Returned Capital" value={cur+0} msg="Returned Capital is the repayments to investors made via redemption requests." />
            <Temp label="Total Dividends" value={cur+0} msg="Dividends are the sum of net income distributed to investors and are distributed quarterly." />

            {/* <Temp label="TTM" value={ttm} msg="Test" />
            <Temp label="MTD" value={lastmtd} msg="Test" />
            <Temp label="ITD" value={itd} msg="Test" /> */}
          </Row>
          <h5>Nav & Monthly Dividend Chart</h5>
          <div className="filterbuttons">
            <TempButton label="Net Asset Value" />
            <TempButton label="Dividends" />
          </div>
          <Dates />
          <Button color="primary" size="sm" className="accountexport" onClick={asExcel}>
            <ExcelIcon /> Export to Excel
          </Button>
          <Graph title="Asset Type" options={options} />
          <div className="note">Earnings excluding principal</div>
        </div>
      </Container>
      <Investment />
    </>
  );
}

export default Account;
export { initaccount };