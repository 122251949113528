import { useState } from 'react';
import Context, { useContext } from '../../context';
import { HiDotsVertical } from 'react-icons/hi';
import './index.css';

import {
  Dropdown, DropdownToggle, DropdownMenu,
  DropdownItem, Modal, ModalHeader,
  ModalBody, ModalFooter, Button,
  Row, Col
} from 'reactstrap';

function Options(){
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
    if(!isOpen){
      (function retry(){
        const options = document.querySelectorAll('.options .dropdown-item');
        if(options[0].checkVisibility()){
          options[0].addEventListener('click', toggleAbout);
          options[1].addEventListener('click', toggleContact);
          options[2].addEventListener('click', toggleTerms);
          options[3].addEventListener('click', toggleDisclaimer);
          options[4].addEventListener('click', togglePrivacy);
        } else setTimeout(retry, 400);
      })();
    }
  };

  const [modalContact, setModalContact] = useState(false);
  const toggleContact = () => setModalContact(!modalContact);

  const Contexts = useContext(Context);
  const { toggles } = Contexts;
  const { toggleAbout, toggleTerms, toggleDisclaimer, togglePrivacy } = toggles;
  Contexts.toggles = { ...toggles, toggleContact };

  return (
    <>
      <Dropdown isOpen={isOpen} toggle={() => setTimeout(toggle, 100)} className="options">
        <DropdownToggle size="sm" className="dots"><HiDotsVertical size="17" color="#606060" /></DropdownToggle>
        <DropdownMenu>
          <DropdownItem>About the Fund</DropdownItem>
          <DropdownItem divider />
          <DropdownItem>Contact Us</DropdownItem>
          <DropdownItem divider />
          <DropdownItem>Terms of Use</DropdownItem>
          <DropdownItem divider />
          <DropdownItem>Disclaimer</DropdownItem>
          <DropdownItem divider />
          <DropdownItem>Privacy Policy</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Modal isOpen={modalContact} toggle={toggleContact} centered={true} size="xl" modalClassName="contact-modal">
        <ModalHeader toggle={toggleContact}>Contact Us <img src="/phoenix.jpg" alt="phoenix" /></ModalHeader>
        <ModalBody>
          <img src="/contact.png" alt="contact" />
          <Row>
            <Col md={3}>
              <b>Headquarters</b> |<br />
              HaShalom way 55, Givatayim, IL<br />
              Phone +972 747312637<br />
              <a href="https://www.bing.com/maps?where=HaShalom+way+55%2C+Givatayim%2C+IL&cp=32.07171%7E34.90919&lvl=21.8" target="blank"><b>Get direction</b></a>
            </Col>
            <Col md={1}></Col>
            <Col md={8}>
              <b>Sales team</b> | Get in touch with our sales team to learn more about the fund<br />
              <a href="mailto: PRED-SALES@fnx.co.il"><b>Pred-sales@fnx.co.il</b></a>
              <hr />
              <b>Support team</b> | You can reach our support team for any question regarding your investment<br />
              <a href="mailto: Officered@fnx.co.il"><b>Officered@fnx.co.il</b></a>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="primary" onClick={toggleContact}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Options;
