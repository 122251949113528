import { useState } from 'react';
import Context, { useContext } from '../../context';
import ReactDOMServer from 'react-dom/server';
/* Added download.svg for the theme */
import { ReactComponent as DownloadIcon } from '../../libs/theme/download.svg';
import { MdAdd, MdEdit, MdDelete } from 'react-icons/md';
import { MdFormatListBulletedAdd } from 'react-icons/md';
import './index.css';

import {
  loaderShow, loaderHide,
  loaderMsg, loaderErrMsg
} from '../Loader';

import {
  Container, Button, Modal, ModalHeader,
  ModalBody, ModalFooter, Form, FormGroup,
  Label, Input
} from 'reactstrap';

import DatePicker, { setDate } from '../DatePicker';
import validation from '../../utils/validation';
import validatefile from '../../utils/validatefile';
import { NewToken } from '../Token';

import Multiselect from 'multiselect-react-dropdown';

let loaded = 0;
let level = '';
let toggle = () => void 0;
let toggleFile = () => void 0;
let toggleDelete = () => void 0;
let setsecdocs = () => void 0;
let validFiles = [];
let sortfield = 'id';
let updateId = 0;
let sectId = 0;
let filename = '';
let form = null;
let nameElem = null;
let sectElem = null;
let deleteId = 0;
let delTable = '';
let delElem = null;
let classes = [];
let searchElem = null;
let selected = [];
let allClasses = [];
let currentClasses = [];
let currentFunds = [];

const initdocs = () => {
  if(!loaded){
    const sorter = localStorage.sortFiles;
    sortfield = (sorter)? sorter : sortfield;

   const dropsearch = document.querySelector
   ('.account .dropsearch select');

    // Added a timeout to populate currentClasses
    // and currentFunds before pulling sections data
    const delayedSections = () => {
      const docsPage = !document.body
      .classList.contains('docspage');

      if(docsPage || !loaded){
        setTimeout(() => {
          const params = [];
          if(level !== 'admin' &&
            dropsearch.value !== '-'
          ){
            params.push(level);
            params.push({
              classes: currentClasses,
              funds: currentFunds
            })
          }

          sections(params);
        }, 1);
      }
    };

    // Trigger sections every click on documents link
    document.querySelector('.maptabs #documents')
    .addEventListener('click', delayedSections);

    delayedSections();

    loaded = 1;
  }
};

// Sections validation
const newvalidation = new validation();
newvalidation.rule = {
  name: () => required()
};
const required = () => newvalidation.required();
const validate = (evnt) => newvalidation.validate(evnt);

const submit = (evnt) => newvalidation.submit(evnt, (data) => {
  loaderShow();

  NewToken().then(newtoken => {
    let url = `${process.env.REACT_APP_API}/sections`;
    let method = 'POST';

    if(updateId){
      url += '/'+updateId;
      method = 'PUT';
    }

    fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(res => {
        if(res.status === 'OK'){
          loaderMsg(res.message);
          setTimeout(() => {
            toggle();
            if(!updateId) sections();
            else sectElem.innerHTML = nameElem.value;
          }, 3000);
        } else {
          if(res.message)
            loaderMsg(res.message);
          else loaderErrMsg();
        }
      })
      .catch(err => {
        loaderErrMsg();
        console.log(err);
      });
  });
});

// File validation
const filevaldation = new validation();
filevaldation.rule = {
  description: () => requiredFile(),
  shared: () => requiredFile(),
  file: () => valFile()
};
const requiredFile = () => filevaldation.required();
const validateFile = (evnt) => filevaldation.validate(evnt);

const valFile = async () => {
  const elem = filevaldation.elem;
  const parent = elem.parentElement;
  const err = parent.querySelector('.err');

  // If required
  let msg = filevaldation.required();

  // Not required
  if(updateId) msg = ' ';

  validFiles = [];
  if(msg === ' '){
    const selectFiles = elem.files;
    for(const file of selectFiles){
      if(await validatefile('pdf', file))
        validFiles.push(file);
    }

    const sellen = selectFiles.length;
    if(validFiles.length !== sellen){
      if(sellen === 1)
        msg = 'Invalid file selected';
      else
        msg = 'There are invalid files selected';
    }
  }

  err.innerHTML = msg;
  return msg;
};

const submitFile = (evnt) => filevaldation.submit(evnt, (data) => {
  loaderShow();

  NewToken().then(newtoken => {
    let url = `${process.env.REACT_APP_API}/sectionfiles`;
    let method = 'POST';

    if(updateId){
      url += '/'+updateId;
      method = 'PUT';
      data.filename = filename;
    }

    data.classes = classes;
    data.section_id = sectId;

    delete data.search_name_input;
    delete data.files;

    if(validFiles.length)
      validFiles.forEach((a, b) => data[b] = a);

    const formData = new FormData();
    for(const key in data)
      formData.append(key, data[key]);

    // console.log(formData.get('name'));

    fetch(url, {
      method,
      headers: {
        'Authorization': newtoken
      },
      body: formData
    })
      .then(res => res.json())
      .then(res => {
        if(res.status === 'OK'){
          loaderMsg(res.message);
          setTimeout(() => {
            toggleFile();
            files(sectId);
          }, 3000);
        } else {
          if(res.message)
            loaderMsg(res.message);
          else loaderErrMsg();
        }
      })
      .catch(err => {
        loaderErrMsg();
        console.log(err);
      });
  });
});

const sections = (params = []) => {
  NewToken(...params).then(newtoken => {
    fetch(`${process.env.REACT_APP_API}/sections?sort=${sortfield}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    })
      .then(res => res.json())
      .then(res => {
        if(res.status === 'OK'){
          setsecdocs(res.data);
        } else setsecdocs([]);
      })
      .catch(err => console.log(err));
  });
};

const files = (sid) => {
  NewToken().then(newtoken => {
    fetch(`${process.env.REACT_APP_API}/sectionfiles?id=${sid}&sort=${sortfield}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    })
      .then(res => res.json())
      .then(res => {
        if(res.status === 'OK'){
          const data = {
            sid: sid,
            files: res.data
          };

          let rows = '';
          const table = <TempTable data={data} />;
          rows += ReactDOMServer.renderToString(table);

          const sectable = document.querySelector(`.sect${sid} .table`);
          sectable.innerHTML = rows;

          // Reset sortby
          const ths = sectable.querySelectorAll('thead th[id]');
          for(const th of ths){
            th.addEventListener('click', () => sortby(th.id, sid));
          }

          const trs = sectable.querySelectorAll('tbody tr');
          trs.forEach((a, b) => {
            const df = data.files[b];

            // Reset download
            const dls = a.querySelectorAll('.file');
            for(const dl of dls){
              dl.addEventListener('click', () => download(df.file, df.realfilename));
            }

            if(level === 'admin'){
              const acts = a.querySelectorAll('.actions font');
              // Reset edit
              acts[0].addEventListener('click', (evnt) => toggleFile(evnt, sid, df));

              // Reset delete
              const delData = {
                id: df.id,
                table: 'sectionfiles',
                name: df.filename,
                elem: 'tr'
              };
              acts[1].addEventListener('click', (evnt) => toggleDelete(evnt, delData));
            }
          });
        }
      })
      .catch(err => console.log(err));
  });
};

const download = async (file, filename) => {
  try {
    loaderShow();

    const newtoken = await NewToken();

    // Get access token
    const response = await fetch(`${process.env.REACT_APP_API}/access`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    });
    const res = await response.json();
    const access = res.data[0].token;

    // Get file data
    const filedata = await fetch(`${process.env.REACT_APP_API}/sectionfiles/files/${file}?token=${access}`);
    const url = window.URL.createObjectURL(await filedata.blob());

    // Auto download
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();

    // Clean up file
    window.URL.revokeObjectURL(url);

    loaderHide();
  } catch(err){
    loaderErrMsg();
  }
};

/* Commented out for the theme */
/* const toggleSection = (evnt) => {
  const elem = evnt.currentTarget;
  elem.classList.toggle('active');
  const panel = elem.parentElement;
  if(!panel.classList.contains('hide')){
    panel.style.height = '48px';
    panel.classList.add('hide');
  } else {
    panel.style.height = 'auto';
    panel.classList.remove('hide');
  }
}; */

const Temp = ({data}) => {
  const delData = {
    id: data.sid,
    table: 'sections',
    name: data.name,
    elem: '.section'
  };

  return (
    <div className={`section sect${data.sid}`} style={{height: 'auto'}}>
      {/* Commented out for the theme */}
      {/* <div className="accordion h5 active" onClick={toggleSection} tabIndex="-1"> */}
      <div className="accordion h5 active">
        <font>{data.name}</font>
        {(() => {
          if(level === 'admin'){
            return (
              <span>
                <font onClick={(evnt) => toggleFile(evnt, data.sid)}><MdAdd size="20" color="#0d6efd" /></font>
                <font onClick={(evnt) => toggle(evnt, data)}><MdEdit size="17" color="#0d6efd" /></font>
                <font onClick={(evnt) => toggleDelete(evnt, delData)}><MdDelete size="17" color="#fc6400" /></font>
              </span>
            );
          }
        })()}
      </div>
      <div className="table">
        <TempTable data={data} />
      </div>
    </div>
  );
};

const TempTable = ({data}) => {
  return (
    <table>
    <thead>
      <tr>
        <TempTh id="description" sid={data.sid}>DOCUMENT NAME</TempTh>
        <TempTh id="shared" sid={data.sid}>DATE SHARED</TempTh>
        <th>ACTIONS</th>
      </tr>
    </thead>
    <tbody>
      {
        data.files.map((a, b) => {
          const delData = {
            id: a.id,
            table: 'sectionfiles',
            name: a.filename,
            elem: 'tr'
          };

          return (
            <tr key={b}>
              <td>
                <div className="file"
                onClick={() => download(a.file, a.realfilename)}
                >
                  {a.filename}
                </div>
              </td>
              <td><div>{new Date(a.shared).toLocaleDateString('es-pa').replace(/\//g, '.')}</div></td>
              <td>
                <div className={`actions level ${level}`}>
                  <font onClick={(evnt) => toggleFile(evnt, data.sid, a)}><MdEdit size="16" color="#0d6efd" /></font>
                  <font onClick={(evnt) => toggleDelete(evnt, delData)}><MdDelete size="16" color="#fc6400" /></font>
                </div>
                <div className={`file level ${level}`} onClick={() => download(a.file, a.realfilename)}>
                  <DownloadIcon /> Download
                </div>
              </td>
            </tr>
          )
        })
      }
    </tbody>
    </table>
  );
};

const TempTh = ({id, sid, children}) => {
  const sf = sortfield.split(' ');
  let down = '', up = '';
  if(sf[0] === id){
    if(!sf[1]) up = ' active';
    else down = ' active';
  }

  return (
    <th id={id} onClick={() => sortby(id, sid)}>
      {children.replace(/ /g, '\xa0')}&nbsp;
      <span className={"down"+down}>&#9660;</span>
      <span className={"up"+up}>&#9650;</span>
    </th>
  );
};

const sortby = (field, id) => {
  field = (field === '#')? 'id' : field;
  sortfield = (field === sortfield)? field+' desc' : field;
  localStorage.sortFiles = sortfield;

  files(id);
};

/* const test = () => {
  console.log(document.querySelector('[name="section_id"]'));
}; */

const confirmDelete = (callback) => {
  loaderShow();

  NewToken().then(newtoken => {
    fetch(`${process.env.REACT_APP_API}/${delTable}/${deleteId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    })
      .then(res => res.json())
      .then(res => {
        if(res.status === 'OK'){
          loaderMsg(res.message);
          setTimeout(() => {
            if(delTable === 'sections') sections();
            else delElem.remove();

            callback();
          }, 3000);
        }
      })
      .catch(err => {
        loaderErrMsg();
        console.log(err);
      });
  });
};

const holder = (type) => {
  const holders = searchElem.placeholder.split(' ');
  searchElem.placeholder = `${type} ${holders[1]}`;
};

function Documents(){
  const Contexts = useContext(Context);
  level = Contexts.level;
  allClasses = Contexts.allClasses;
  currentClasses = Contexts.currentClasses;
  currentFunds = Contexts.currentFunds;

  const [modal, setModal] = useState(false);
  toggle = (evnt, data) => {
    setModal(!modal);

    if(!modal){
      evnt.stopPropagation();

      (function retry(){
        const modalElem = document.querySelector('.sectionModal');
        if(modalElem){
          form = modalElem.querySelector('form');
          if(data){
            const title = modalElem.querySelector('.modal-title');
            title.innerHTML = title.innerHTML.replace('Add', 'Edit');

            updateId = data.sid;
            sectElem = document.querySelector(`.sect${data.sid} .accordion font`);
            nameElem = form.querySelector('[name="name"]');
            nameElem.value = sectElem.innerHTML;
          }
        } else setTimeout(retry, 400);
      })();
    } else updateId = 0;
  };

  const [modalFile, setModalFile] = useState(false);
  toggleFile = (evnt, sid, data) => {
    setModalFile(!modalFile);

    if(!modalFile){
      evnt.stopPropagation();

      // Set selected classes before displaying the modal
      selected = (data && data.classes)? data.classes.split(',') : [];
      classes = selected;

      // Best implementation of named self-executing function for retries purposes
      (function retry(){
        const modalElem = document.querySelector('.fileModal');
        if(modalElem){
          sectId = sid;
          searchElem = document.querySelector('#search_input');
          if(data){
            const title = modalElem.querySelector('.modal-title');
            title.innerHTML = title.innerHTML.replace('Add', 'Edit');

            updateId = data.id;
            form = modalElem.querySelector('form');
            form.querySelector('[name*="desc"]').value = data.filename;
            form.querySelector('[name="fund"]').value = data.fund;
            if(selected.length) holder('Search');

            const date = form.querySelector('[name="shared"]');
            date.value = data.shared;
            setDate(date);

            filename = data.file;
          }
        } else setTimeout(retry, 400);
      })();
    } else updateId = 0;
  };

  const [modalDelete, setModalDelete] = useState(false);
  toggleDelete = (evnt, data) => {
    setModalDelete(!modalDelete);

    if(!modalDelete){
      const target = evnt.currentTarget;
      delElem = target.closest(data.elem);

      evnt.stopPropagation();

      (function retry(){
        const modalElem = document.querySelector('.deleteModal');
        if(modalElem){
          deleteId = data.id;
          delTable = data.table;
          const name = modalElem.querySelector('.modal-body strong');
          name.innerText = data.name;
        } else setTimeout(retry, 400);
      })();
    }
  };

  const [secdocs, setSecdocs] = useState([]);
  setsecdocs = (data) => setSecdocs(data);

  const rows = {};
  secdocs.forEach(a => {
    const sid = a.sid
    if(!rows[sid]){
      rows[sid] = {
        sid: sid,
        name: a.name,
        files: []
      };
    }

    if(a.file){
      rows[sid].files.push({
        id: a.id,
        filename: a.filename,
        fund: a.fund,
        classes: a.classes,
        realfilename: a.realfilename,
        file: a.file,
        shared: a.shared
      });
    }
  });

  return (
    <>
      <Container fluid className="documents">
        {level !== 'admin'? '' : <Button color="primary" size="sm" className="add" onClick={toggle}><MdFormatListBulletedAdd /> Add Section</Button>}
        {(() => {
          const elems = [];
          for(const key in rows){
            elems.push(<Temp key={key} data={rows[key]} />);
          }
          return elems;
        })()}
      </Container>
      <Modal isOpen={modal} toggle={toggle} centered={true} autoFocus={false} backdrop="static" modalClassName="sectionModal">
        <ModalHeader toggle={toggle}>Add Section</ModalHeader>
        <ModalBody>
          <Form onSubmit={submit}>
            <FormGroup>
              <Label>Section name</Label>
              <Input name="name" bsSize="sm" onChange={validate} autoFocus={true} autoComplete="off" />
              <span className="err"></span>
            </FormGroup>
            <Button block size="sm">Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalFile} /* onOpened={test} */ toggle={toggleFile} centered={true} autoFocus={false} backdrop="static" modalClassName="fileModal">
        <ModalHeader toggle={toggleFile}>Add File</ModalHeader>
        <ModalBody>
          <Form onSubmit={submitFile}>
            <FormGroup>
              <Label>Description</Label>
              <Input name="description" bsSize="sm" onChange={validateFile} autoFocus={true} autoComplete="off" />
              <span className="err"></span>
            </FormGroup>
            <FormGroup>
              <Label>Fund</Label>
              <Input type="select" name="fund" bsSize="sm">
                <option>Phoenix PRED</option>
                <option>Phoenix Rated</option>
                <option>Fattal Feder</option>
                <option value="">All Funds</option>
              </Input>
              <span className="err"></span>
            </FormGroup>
            <FormGroup>
              <Label>Class</Label>
              <Multiselect
                isObject={false}
                // displayValue="text"
                // id="css_custom"
                // onKeyPressFn={function noRefCheck(){}}
                // onSearch={function noRefCheck(){}}
                onSelect={(selectedList, selectedItem) => {
                  classes = selectedList;
                  // classes.push(selectedItem);
                  if(selectedList.length === 1) holder('Search');
                }}
                onRemove={(selectedList, selectedItem) => {
                  classes = selectedList;
                  // classes.splice(classes.indexOf(selectedItem), 1);
                  if(!selectedList.length) holder('All');
                }}
                options={allClasses}
                selectedValues={selected}
                placeholder="All Classes"
              />
              <span className="err"></span>
            </FormGroup>
            <FormGroup>
              <Label>Shared</Label>
              <DatePicker name="shared" label="Date" onChange={validateFile} value={new Date().toLocaleDateString('es-pa')} />
              <span className="err"></span>
            </FormGroup>
            <FormGroup>
              <Input type="file" name="file" bsSize="sm" onChange={validateFile} accept="application/pdf" />
              <span className="err"></span>
            </FormGroup>
            <Button block size="sm">Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalDelete} toggle={toggleDelete} centered={true} modalClassName="deleteModal">
        <ModalHeader toggle={toggleDelete}>Confirm</ModalHeader>
        <ModalBody>
          Are you sure you want to delete "<strong></strong>"?
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="secondary" onClick={toggleDelete}>
            Cancel
          </Button>
          <Button size="sm" color="primary" onClick={() => confirmDelete(toggleDelete)}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Documents;
export { initdocs };