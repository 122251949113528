import { useEffect } from 'react';
import { memo } from 'react';
import './index.css';

let elem = null;

const loaderElem = () => {
  return document.querySelector('.loader');
};

const loaderSet = () => {
  elem = loaderElem();
};

const loaderShow = () => {
  elem.removeAttribute('style');
};

const loaderHide = () => {
  elem.style.display = 'none';
};

const loaderShowWithMsg = (msg) => {
  loaderShow();
  const span = elem.querySelector('span');
  span.innerHTML = `<br><br>${msg}`;
};

const loaderMsg = (msg) => {
  const img = elem.querySelector('img');
  const span = elem.querySelector('span');

  img.style.display = 'none';
  span.innerHTML = msg;

  setTimeout(() => {
    loaderHide();
    img.removeAttribute('style');
    span.innerHTML = '';
  }, 3000);
};

const loaderErrMsg = () => {
  loaderMsg('Something went wrong, please try again');
};

function Loader(){
  useEffect(() => {
    loaderSet();
  }, []);

  return (
    <div className="loader">
      <div></div>
      <img src="/loader.gif" alt="loader" />
      <span></span>
    </div>
  );
}

export default memo(Loader);
export {
  loaderSet,
  loaderShow, loaderHide,
  loaderShowWithMsg, loaderMsg,
  loaderErrMsg
};
