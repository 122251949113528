import { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import Context from './context';

import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './pages/Home';
import Zipcode from './pages/Zipcode';
import NotFound from './pages/NotFound';
import Footer from './components/Footer';
import Loader from './components/Loader';
import Token from './components/Token';
import Confirm from './components/Confirm';
import History from './components/History';
import Unload from './components/Unload';
import Idle from './components/Idle';

import './App.css';
import './libs/theme/theme.css';
import './libs/mobile/mobile.css';

/*
  Context API usage:
  const context = {
    key: value
  };
  <Context.Provider value={context}>

  This can be populated on runtime.
  Ex:
    import Context, { useContext } from '../../context';

    // Add context
    // Update context
    Object.assign(
      useContext(Context),
      {
        key: value
      }
    );

    // Use context
    const { key } = useContext(Context);


    // If multiple use
    const Contexts = useContext(Context);

    Object.assign(
      Contexts,
      {
        key: value
      }
    );

    const { key } = Contexts;


    // Using dot notation to add or update
    const Contexts = useContext(Context);
    Contexts.key = value;
    const { key } = Contexts;


    // Using bracket notation to add or update
    const Contexts = useContext(Context);
    Contexts[key] = value;
    const { key } = Contexts;


    // Using spread operator to add
    const Contexts = useContext(Context);
    Contexts = {
      ...Contexts,
      key: value
    };


    // Use context if the target component is at below
    // Refer to the script below if the target component is at above
    for(const svg of svgs) svg.addEventListener('click', slider);
*/

let signin = 0;
let token = '';
let level = '';
let name = '';
let email = '';
let conts = '';
let relate = '';
let comms = '';
let summary = '';
let viewmode = '';
let exptime = '';
let trans = '';
let navs = '';
let accts = '';

const transmit = () => {
  signin = Number(sessionStorage.signin);
  delete sessionStorage.signin;

  token = sessionStorage.token;
  delete sessionStorage.token;

  level = sessionStorage.level;
  delete sessionStorage.level;

  name = sessionStorage.name;
  delete sessionStorage.name;

  email = sessionStorage.email;
  delete sessionStorage.email;

  conts = sessionStorage.conts;
  delete sessionStorage.conts;

  relate = sessionStorage.relate;
  delete sessionStorage.relate;

  comms = sessionStorage.comms;
  delete sessionStorage.comms;

  summary = sessionStorage.summary;
  delete sessionStorage.summary;

  viewmode = sessionStorage.viewmode;
  delete sessionStorage.viewmode;

  exptime = sessionStorage.exptime;
  delete sessionStorage.exptime;

  // Replaced with USD or ILS not 0 then it is active
  /* const stats = (sessionStorage.stats)? sessionStorage.stats : '';
  delete sessionStorage.stats; */
};

function App(){
  const [reload, setReload] = useState(false);
  const reloader = () => setReload(!reload);

  useEffect(() => {
    // This condition determines if the cache is enabled
    if(!trans){
      (async () => {
        if(sessionStorage.signin === '1'){
          transmit();

          // Create cache
          const appCache = await caches.open('appCache');

          const transCache = await appCache.match('/trans.json');
          // trans = await transCache.json();
          trans = await transCache.text();
          appCache.delete('/trans.json');

          const navsCache = await appCache.match('/navs.json');
          // navs = await navsCache.json();
          navs = await navsCache.text();
          appCache.delete('/navs.json');

          const acctsCache = await appCache.match('/accts.json');
          // accts = await acctsCache.json();
          accts = await acctsCache.text();
          appCache.delete('/accts.json');

          // This causes issue on refresh
          // Delete cache
          // caches.delete('appCache');
          reloader();
        } else {
          // Delete cache
          caches.delete('appCache');
        }
      })();
    }
  });

  if(localStorage.trans){
    transmit();

    trans = localStorage.trans;
    delete localStorage.trans;

    navs = localStorage.navs;
    delete localStorage.navs;

    accts = localStorage.accts || sessionStorage.accts;
    delete localStorage.accts;
    delete sessionStorage.accts;
  }

  const context = {
    reloader: reloader,
    signin: signin,
    token: token,
    level: level,
    name: name,
    email: email,
    trans: trans,
    navs: navs,
    conts: conts,
    accts: accts,
    relate: relate,
    comms: comms,
    summary: summary,
    viewmode: viewmode,
    exptime: exptime
  };

  const path = window.location.pathname;

  return (
    (sessionStorage.signin === '1')? '' : (
      <Context.Provider value={context}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/zipcode/:zip" element={<Zipcode />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        {path === '/'? <Footer /> : ''}
        <Loader />

        {/* Do not include
        this component to
        any condition */}
        <Unload />
        {/* Any conditions
        can be used to bypass
        login or manually set
        sessionStorage data
        to access sensitive
        data */}

        {(() => {
          if(signin){
            return (
              <>
                <Token />
                <Confirm />
                <History />
                <Idle />
              </>
            );
          }
        })()}
      </Context.Provider>
    )
  );
}

export default App;