import { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './index.css';

import Slider from '../Slider';
import { NewToken } from '../Token';

import {
  loaderShow, loaderHide,
  loaderErrMsg
} from '../Loader';

let toggleSlider = () => void 0;
let items = [];
let active = 0;

function ModalSlider(){
  const [modalSlider, setModalSlider] = useState(false);
  toggleSlider = async (clicked, predid, images, show) => {
    let stat = true;
    if(!isNaN(clicked)){
      loaderShow();

      try {
        const newtoken = await NewToken();

        const response = await fetch(`${process.env.REACT_APP_API}/access`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': newtoken
          }
        });
        const res = await response.json();
        const access = res.data[0].token;

        const item = (file) => {
          const caption = (show)? file : '';
          return {
            src: `${process.env.REACT_APP_API}/images/${predid}/${file}?token=${access}`,
            altText: caption,
            caption: caption
          };
        };

        const imgs = images.slice();
        const clickid = [];
        items = [];
        imgs.forEach((a, b) => {
          if(a){
            if(b <= 3) clickid.push(b.toString());
            items.push(item(a));
          }
        });

        // If the clicked image is null then display the next available image
        /* if(clickid[clicked])
          active = clicked;
        else {
          const len = clickid.length;
          active = (len)? len - 1 : 0;
        } */

        // Use this if there are no null images
        active = clicked;

        loaderHide();
      } catch(err){
        loaderErrMsg();
        stat = false;
        console.log(err);
      }
    }

    if(stat) setModalSlider(!modalSlider);
  };

  const close = (
    <span className="close"
      onClick={toggleSlider}
    >&times;</span>
  );

  return (
    <Modal isOpen={modalSlider} toggle={toggleSlider} centered={true} size="xl" modalClassName="slider-modal" backdropClassName="slider-backdrop" external={close}>
      <ModalBody>
        <Slider items={items} interval={0} fade={true} active={active} />
      </ModalBody>
    </Modal>
  );
}

export default ModalSlider;
export { toggleSlider };