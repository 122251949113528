import { useEffect } from 'react';
import { Input } from 'reactstrap';
import './index.css';

const calendar = (evnt) => {
  evnt.target
  .previousElementSibling
  .showPicker();
};

const datelabels = {};
const datedisplay = (evnt) => {
  const self = evnt.target;
  const select = self.nextElementSibling;
  if(!datelabels[self.name]) datelabels[self.name] = select.innerText;
  select.options[0].innerText = (!self.value)? datelabels[self.name] : format(self.value);
};

const setDate = (date) => {
  const change = new Event('change', { bubbles: true });
  date.dispatchEvent(change);
};

const format = (date) => new Date(date).toLocaleDateString('es-pa');
const revmat = (date) => new Date(date).toLocaleDateString('en-ca');

function DatePicker({name, label, onChange, value = ''}){
  useEffect(() => {
    const select = document.querySelector('.select-date');
    select.addEventListener('click', calendar);

    const date = select.previousElementSibling;
    date.addEventListener('change', datedisplay);

    // Trigger change event
    if(value) setDate(date);
  }, [value]);

  return (
    <>
      <input type="date" name={name} onChange={onChange} className="input-date" defaultValue={revmat(value)} />
      <Input type="select" className="select-date" bsSize="sm">
        <option value="-">{label}</option>
      </Input>
    </>
  );
}

export default DatePicker;
export { setDate };