import { useState } from 'react';
import Context, { useContext } from '../../context';
import './index.css';

import {
  Dropdown, DropdownToggle, DropdownMenu,
  DropdownItem, Modal, ModalHeader,
  ModalBody, ModalFooter, Button
} from 'reactstrap';

import { RxCaretDown } from 'react-icons/rx';
import { toggleConfirm } from '../Confirm';

function Signout(){
  const Contexts = useContext(Context);
  const { name, email, viewmode } = Contexts;

  const signout = (callback) => {
    Contexts.signin = 0;
    callback();
    window.location.reload();
  };

  Object.assign(
    Contexts,
    {
      signout: signout
    }
  );

  const names = name.split(' ');
  /* Commented out for the theme */
  /* let initials = names[0].substr(0, 1);
  initials += (names[1])? names[1].substr(0, 1) : ''; */

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const toggleLogout = () => {
    toggleConfirm({
      action: 'logout',
      label: 'Logout',
      confirm: () => signout(toggleConfirm)
    });
  };

  const [modalProfile, setModalProfile] = useState(false);
  const toggleProfile = () => setModalProfile(!modalProfile);

  return (
    <>
      <Dropdown isOpen={isOpen} toggle={() => setTimeout(toggle, 100)} className="signout">
        {/* Commented out for the theme */}
        {/* <DropdownToggle size="sm" className={`name ${viewmode}`}>{initials.toUpperCase()}</DropdownToggle> */}
        <DropdownToggle size="sm" className={`name ${viewmode}`}>{names[0]} <RxCaretDown size="20px" /></DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={toggleProfile}>Profile</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={toggleLogout}>Logout</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Modal isOpen={modalProfile} toggle={toggleProfile} centered={true}>
        <ModalHeader toggle={toggleProfile}>Profile</ModalHeader>
        <ModalBody>
          <h6>Name: {name}</h6>
          <h6>Email: {email}</h6>
          <h6>Type: {viewmode}</h6>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="primary" onClick={toggleProfile}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Signout;
