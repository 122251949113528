import { useState, useEffect } from 'react';
import Context, { useContext } from '../../context';
import { Container, Row } from 'reactstrap';
import Chart, { chartopts, chartshaper, chartclick } from '../Chart';
import Totals from '../Totals';
import './index.css';

import { encodeFilters } from '../../utils/filters';
import { NewToken } from '../Token';

let filters = [];
let loaded = 0;
let stats = () => void 0;
const names = {};

const initstats = () => {
  if(!loaded){
    filters = document.querySelectorAll('.filters .filter select');
    for(const filter of filters){
      filter.addEventListener('change', stats);
      names[filter.name] = filter;
    }

    stats();
    loaded = 1;
  }
};

const slice = (evnt, filter) => {
  const elem = names[filter];
  const curval = elem.value;

  const change = new Event('change', { bubbles: true });
  let name = evnt.dataPoint.name;

  if(name === 'Floating')
    name = 'Non-Fixed';

  if(curval !== name){
    elem.value = name;
    const newval = elem.value;

    if(newval) elem.dispatchEvent(change);
    else elem.value = curval;
  } else {
    elem.selectedIndex = 0;
    elem.dispatchEvent(change);
  }

  chartclick();
};

function Statistics(){
  const [options, setOptions] = useState(
    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
  );

  const Contexts = useContext(Context);
  const level = Contexts.level;
  const viewmode = Contexts.viewmode;

  useEffect(() => {
    const levelElem = document
    .querySelector('.statistics .level:last-child');
    if(levelElem){
      // Remove class upon leaving the page
      levelElem.classList.remove(level);

      // Add class when landed in the page
      if(document.body.classList.contains('statpage')){
        (function retry(){
          const chartElem = document.querySelector
          ('.statistics .canvasjs-chart-credit');
          if(chartElem){
            levelElem.classList.add(level);
          } else setTimeout(retry, 400);
        })();
      }
    }
  });

  stats = () => {
    NewToken().then(newtoken => {
      const enfil = encodeFilters(filters);
      fetch(`${process.env.REACT_APP_API}/loandetails?type=stats&filters=${enfil}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': newtoken
        }
      })
        .then(res => res.json())
        .then(res => {
          const data = res.data;

          const stropts = JSON.stringify(chartopts);

          let optPartner = {};
          let optAcct = {};
          let optSize = {};
          let optBench = {};
          let optPred = {};
          let optStat = {};
          let optSecu = {};

          if(
            viewmode === 'admin' ||
            viewmode === 'lender'
          ){
            // Commented out to have only 1 access level
            /* optPartner = JSON.parse(stropts);
            optPartner.data[0].type = 'doughnut';
            optPartner.data[0].dataPoints = chartshaper(data[0]);
            optPartner.data[0].click = (evnt) => slice(evnt, 0);

            stateFilter = 4;
            assetFilter = 3;
            loanFilter = 2; */

            optAcct = JSON.parse(stropts);
            optAcct.data[0].type = 'doughnut';
            optAcct.data[0].dataPoints = chartshaper(data[6]);
            optAcct.data[0].click = (evnt) => slice(evnt, 'account_name');

            optPred = JSON.parse(stropts);
            optPred.data[0].type = 'doughnut';
            optPred.data[0].dataPoints = chartshaper(data[7]);

            optStat = JSON.parse(stropts);
            optStat.data[0].type = 'doughnut';
            optStat.data[0].dataPoints = chartshaper(data[8]);
            optStat.data[0].click = (evnt) => slice(evnt, 'loan_performing_status');
            optStat.data[0].name = 'stat';

            optSecu = JSON.parse(stropts);
            optSecu.data[0].type = 'doughnut';
            optSecu.data[0].dataPoints = chartshaper(data[9]);
            optSecu.data[0].click = (evnt) => slice(evnt, 'security_type');
          }

          const optState = JSON.parse(stropts);
          optState.data[0].type = 'doughnut';
          optState.data[0].dataPoints = chartshaper(data[1], 2);
          optState.data[0].click = (evnt) => slice(evnt, 'state');

          const optAsset = JSON.parse(stropts);
          optAsset.data[0].type = 'doughnut';
          optAsset.data[0].dataPoints = chartshaper(data[2]);
          optAsset.data[0].click = (evnt) => slice(evnt, 'main_purpose');

          const optLoan = JSON.parse(stropts);
          optLoan.data[0].type = 'doughnut';
          optLoan.data[0].dataPoints = chartshaper(data[3]);
          optLoan.data[0].click = (evnt) => slice(evnt, 'loan_type');

          optSize = JSON.parse(stropts);
          optSize.data[0].type = 'doughnut';
          optSize.data[0].dataPoints = chartshaper(data[4]);
          optSize.data[0].click = (evnt) => slice(evnt, 'loansizes');

          optBench = JSON.parse(stropts);
          optBench.data[0].type = 'doughnut';
          optBench.data[0].dataPoints = chartshaper(data[5]);
          optBench.data[0].click = (evnt) => slice(evnt, 'loan_benchmark');

          setOptions([
            optPartner, optState,
            optAsset, optLoan, optAcct,
            optSize, optBench, optPred,
            optStat, optSecu
          ]);
        })
        .catch(err => console.log(err));
    });
  };

  return (
    <Container fluid className="stats">
      {/* Added h4 for the theme */}
      <h4>Statistics</h4>
      <Totals />
      <div className="statistics">
        <Row className={`level ${level}`}>
          {/* Commented out to have only 1 access level */}
          {/* {level !== 'admin'? '' : <Chart title="Breakdown by Partner" options={options[0]} />} */}

          <Chart title="Asset Type" options={options[2]} />
          <Chart title="Loan Type" options={options[3]} />
          <Chart title="Share by State" options={options[1]} />
        </Row>
        <Row className="level">
          <Chart title="Loan Size" options={options[5]} />
          <Chart title="Loan Benchmark" options={options[6]} />
          {(() => {
            if(
              viewmode === 'admin' ||
              viewmode === 'lender'
            ){
              return (
                <>
                  <Chart title="Account Name" options={options[4]} />
                  <Chart title="Outstanding Amount / Pred Leverage Funded" options={options[7]} />
                  <Chart title="Loan Performing Status" options={options[8]} />
                  <Chart title="Security Type" options={options[9]} />
                </>
              );
            }
          })()}
        </Row>
      </div>
    </Container>
  );
}

export default Statistics;
export { initstats };
