const center = {
  lat: 37.0902,
  lng: -95.7129
};

const zoom = 4;

const styles = [
  {
    featureType: "all",
    stylers: [
      { saturation: -90 },
      { lightness: 50 }
    ]
  },
];

export { center, zoom, styles };