import {
  loaderShow, loaderMsg,
  loaderErrMsg
} from '../Loader';

import { Input } from 'reactstrap';
import validatefile from '../../utils/validatefile';
import { NewToken } from '../Token';

let validFiles = [];
let predid = '';
let fileElem = null;
let callback = () => void 0;

// File validation
const validateFile = async () => {
  const selectFiles = fileElem.files;
  const sellen = selectFiles.length;
  if(sellen){
    loaderShow();

    // This try catch is for submitFile()
    try {
      validFiles = [];
      for(const file of selectFiles){
        if(await validatefile('image', file))
          validFiles.push(file);
      }

      let msg = 'Invalid files selected';

      const vallen = validFiles.length;
      if(vallen){
        const res = await submitFile();
        msg = res.message;
        if(res.status === 'OK'){
          fileElem.value = '';
          callback(res.data);

          if(vallen !== sellen)
            msg = msg+' except for invalidated';
        }
      } else {
        if(sellen === 1)
          msg = msg.replace('s ', ' ');
      }

      loaderMsg(msg);
    } catch(err){
      loaderErrMsg();
      fileElem.value = '';
      console.log(err);
    }
  }
};

const submitFile = async () => {
  let url = `${process.env.REACT_APP_API}/images/${predid}`;
  let method = 'POST';

  const data = {};
  validFiles.forEach((a, b) => data[b] = a);

  const formData = new FormData();
  for(const key in data)
    formData.append(key, data[key]);

  // console.log(formData.get('name'));

  const newtoken = await NewToken();

  const response = await fetch(url, {
    method,
    headers: {
      'Authorization': newtoken
    },
    body: formData
  });
  return await response.json();
};

const openSelector = (id, call) => {
  predid = id;
  fileElem = document.querySelector('[name="file"].d-none');
  fileElem.click();
  // The passed data will be used in history component
  fileElem.dispatchEvent(new CustomEvent('click', { detail: predid }));
  callback = call;
};

function Upload(){
  return <Input type="file" name="file" className="d-none" bsSize="sm" onChange={validateFile} accept="image/*" multiple />;
}

export default Upload;
export { openSelector };