import { obj } from '../Map/map';
import { Col, Input } from 'reactstrap';

let filters = [];

const feature = (evnt) => {
  const elem = evnt.currentTarget;
  const val = elem.value;
  filters = document.querySelectorAll('.filters .filter select');
  if(val === 'Show Loans Only'){
    filterDisability(false);
    A2OneVisibility(false);
  } else if(val === 'Show A2One Only'){
    filterDisability(true);
    A2OneVisibility(true);
  } else if(val === 'A2One - Multifamily Data'){
    A2OneVisibility(false);
    obj.marketVisibility();
  } else {
    if(elem.name === 'features'){
      filterDisability(false);
      A2OneVisibility(true);
    } else {
      A2OneVisibility(true);
      obj.visibility(obj.polySubmarket, false);
      obj.visibility(obj.polyMarket, false);
    }
  }
};

const filterDisability = (state) => {
  if(state === false){
    for(const filter of filters){
      filter.removeAttribute('disabled');
    }
  } else {
    for(const filter of filters){
      filter.disabled = 'true';
    }
  }
};

const A2OneVisibility = (state) => {
  if(obj.level){
    let key = 'poly';
    key += obj.level[0].toUpperCase();
    key += obj.level.slice(1);
    obj.visibility(obj[key], state);
  }
};

function Feature({data, name, label}){
  return (
    <Col md={2}>
      <Input name={name} type="select" onChange={feature}>
        <option value="-">{label}</option>
        {
          data?.map((v, i) => {
            return <option key={i} value={v}>{v}</option>;
          })
        }
      </Input>
    </Col>
  );
}

export default Feature;
