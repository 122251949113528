import { loaderShow, loaderHide } from '../Loader';
import { NewToken } from '../Token';
import { tier } from '../../utils/borrower';
import { openFullInfo, closeFullInfo } from '../Mobile/utils';

const elem = 'span';
const closeid = 'close';
const times = '&times;';
const fullid = 'full';
const fullwindowid = 'fullwindow';

let level = '';
let toggleSlider = () => void 0;
let predid = '';
let images = [];

const infoContent = async (infowindow, details, data, closeInfo) => {
  infowindow.setContent(await infoCategories(details, data));
  infowindow.Open();
  loaderHide();

  infowindow.addListener('domready', () => {
    const close = document.createElement(elem);
    close.className = closeid;
    close.title = 'Close';
    close.addEventListener('click', closeInfo);
    close.innerHTML = times;

    const full = document.createElement(elem);
    full.className = fullid;
    full.title = 'Full screen';
    full.addEventListener('click', fullInfo);
    full.innerHTML = '[&nbsp;&nbsp;&nbsp;]';

    const bottom = document.createElement(elem);
    bottom.className = 'bottom';
    bottom.addEventListener('click', closeInfo);
    bottom.innerHTML = 'Close';

    const dialog = document.querySelectorAll('.gm-style-iw-d');
    dialog[dialog.length - 1].append(close, full, bottom);

    openFullInfo(full, close);
  });
};

const infoTable = (data) => {
  const zrn = data.zip_rating_num;
  data.zip_rating = (zrn <= 1)? '1.00' : zrn;

  // Zip rating num for admin and lender only
  /* if(
    level !== 'admin' &&
    level !== 'lender'
  ) data.zip_rating_num = '0'; */

  // Zip rating num remove
  data.zip_rating_num = '0';

  let details = '<table>';
  for(let key in data){
    // const value = data[key];
    // Placed here to exclude from zero rule because zero means Excellent
    const value = (key === 'borrower_tier')? tier[data[key]] : data[key];
    if(
        value && value !== '0' &&
        value !== '$0' && value !== '0%' &&
        value !== '$0.00' && value !== '0.00%' &&
        value !== '0.00' && value !== 'NULL'
    ){
      key = key.toUpperCase();
      key = key.replace(/_/g, ' ');
      key = key.replace(/SLASH/g, '/');
      key = key.replace(/PLUS/g, '+');
      key = key.replace(/ DASH /g, '&minus;');
      if(key === 'PNW') key = key.replace('PNW', 'SPONSOR NET WORTH');
      if(key === 'LIQUIDITY') key = key.replace('LIQUIDITY', 'SPONSOR LIQUIDITY');
      key = key.replace('PNW / LOAN AMOUNT', 'SPONSOR NET WORTH RATIO');
      key = key.replace('LIQUIDITY / LOAN AMOUNT', 'SPONSOR LIQUIDITY RATIO');

      if(
        level !== 'admin' &&
        level !== 'lender'
      ){
        key = key.replace('ORIGINAL ZIP RATING', 'ZIP RATING');
        key = key.replace('ORIGINAL LOAN RATING', 'LOAN RATING');
      }

      key = key.replace('CURRENT UNEMPLOY', 'UNEMPLOYMENT');
      key = key.replace('UNEMPLOY 3Y', 'UNEMPLOYMENT TREND 3Y');
      key = key.replace('MAX UNEMPLOY', 'MAX UNEMPLOYMENT');

      if(key === 'ZIP RATING') key = key.replace('ZIP RATING', 'ZIP RATING (1-10)');
      details += `
        <tr>
          <td><b>${key}</b></td>
          <td>${value}</td>
        </tr>
      `;
    }
  }
  details += '</table>';
  return details;
};

const toggleCategories = function(){
  this.classList.toggle('active');
  const panel = this.nextElementSibling;
  if(panel.style.height){
    panel.style.height = null;
  } else {
    panel.style.height = panel.scrollHeight+'px';
  }
};

const infoCategories = async (details, data) => {
  const create = (title, content, active = '') => {
    const accordion = document.createElement('button');
    accordion.className = 'accordion';
    accordion.addEventListener('click', toggleCategories);
    accordion.innerHTML = title;
    accordion.tabIndex = -1;

    const panel = document.createElement('div');
    panel.className = 'panel';
    panel.innerHTML = content;

    if(active){
      accordion.classList.add(active);
      panel.style.height = 'auto';
    }

    return [accordion, panel];
  };

  // Object
  // const pick = (...keys) => keys.reduce((a, e) => (a[e] = data[e], a), {});
  // let picked = pick('loan_name', 'account_name', 'loan_status');

  // Array
  // const pick = (arr) => arr.reduce((a, e) => (a[e] = data[e], a), {});

  /* const pick = (arr) => arr.reduce((a, e) => {
    a[e] = data[e];
    return a;
  }, {}); */

  const pick = (arr) => {
    return (
      arr.reduce((a, e) => {
        a[e] = data[e];
        return a;
      }, {})
    );
  };

  const container = document.createElement('div');
  container.className = 'categories';

  predid = data.pred_dash_id;
  images = data.images;
  if(predid && images.length){
    const newtoken = await NewToken();

    // No try catch because there is a catch on the call that uses infoContent()
    // Components: Pin, State, County, Zip, Market and Submarket
    const response = await fetch(`${process.env.REACT_APP_API}/access`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': newtoken
      }
    });
    const res = await response.json();
    const access = res.data[0].token;

    const temp = (file) => {
      const url = (file)? `${process.env.REACT_APP_API}/images/${predid}/${escape(file)}?token=${access}` : '';
      let html = '<div class="col-md-3">';
      html += `<div style="background-image: url(${url})"></div>`;
      html += '</div>';
      return html;
    };

    let img = '<div class="row images">';
    for(let n = 0; n <= 3; n++){
      if(images[n])
        img += temp(images[n]);
    }
    img += '</div>';
    container.innerHTML = img;

    const imgs = container.querySelectorAll('.images [style*="url"]');
    imgs.forEach((a, b) => a.addEventListener('click', () => toggleSlider(b, predid, images)));
  }

  details.forEach((a, b) => {
    let picked = pick(a.fields);
    let elem = create(a.title, infoTable(picked), (b === 0)? 'active' : '');
    container.append(elem[0], elem[1]);
  });

  return container;
};

const fullInfo = (evnt) => {
  const parent = evnt.target.parentNode.firstChild;
  const mapDiv = document.querySelector('.map');

  const fullwindow = document.createElement('div');
  fullwindow.className = fullwindowid;
  fullwindow.innerHTML = parent.outerHTML;

  // Fixed the height issue when fullscreen
  // Height is set to 381px from toggle
  // Make it auto to when fullscreen
  const panel = fullwindow.querySelector('.panel');
  if(panel.style.height) panel.style.height = 'auto';

  const close = document.createElement(elem);
  close.className = closeid;
  close.title = 'Close';
  close.addEventListener('click', () => fullwindow.remove());
  close.innerHTML = times;

  mapDiv.prepend(fullwindow);
  fullwindow.append(close, closeFullInfo(close));

  const accs = document.querySelectorAll('.fullwindow .accordion');
  for(const acc of accs){
    acc.addEventListener('click', toggleCategories);
  }

  const imgs = document.querySelectorAll('.images [style*="url"]');
  imgs.forEach((a, b) => a.addEventListener('click', () => toggleSlider(b, predid, images)));
};

function InfoWindow(map, maps, coords, _level = '', _toggleSlider = ''){
  level = _level;
  toggleSlider = _toggleSlider;

  loaderShow();

  const infowindow = new maps.InfoWindow({
    pixelOffset: new maps.Size(0, -20)
  });
  infowindow.setPosition(coords);
  infowindow.Open = () => infowindow.open(map);
  return infowindow;
}

export default InfoWindow;
export { infoContent };