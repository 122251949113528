import Context, { useContext } from '../../context';
import './index.css';

function Footer(){
  const { toggles } = useContext(Context);
  const {
    toggleAbout, toggleTerms, toggleDisclaimer,
    toggleContact, togglePrivacy
  } = toggles;

  return (
    <div className="footer">
      <span className="copyright">
        <span>{`© Phoenix Real Tech 2018-${new Date().getFullYear()}`}</span>
      </span>
      <span className="links">
        <span onClick={toggleAbout}>About the Fund</span>
        &nbsp;|&nbsp;
        <span onClick={toggleContact}>Contact Us</span>
        &nbsp;|&nbsp;
        <span onClick={toggleTerms}>Terms of Use</span>
        &nbsp;|&nbsp;
        <span onClick={toggleDisclaimer}>Disclaimer</span>
        &nbsp;|&nbsp;
        <span onClick={togglePrivacy}>Privacy Policy</span>
      </span>
      {/* Commented out for the theme */}
      {/* <img src="/phoenix.jpg" alt="phoenix" /> */}
    </div>
  );
}

export default Footer;
